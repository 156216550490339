"use strict";
import { theme } from "../../../ThemeContext/ThemeObject";
export const classes = {
  applicationsContainer: {
    paddingTop: "6px"
  },
  text: {
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "14px"
  },
  tableWrapper: {
    tableLayout: "auto !important",
    minWidth: "750px",
    border: "none",
    "td, th, tr, thead": {
      fontFamily: "Source Sans Pro, sans-serif",
      border: "none",
      backgroundColor: "#FFFFFF",
      textTransform: "none"
    },
    th: {
      color: theme.palette.primary.main,
      fontWeight: "bold",
      paddingBottom: "16px",
      "& .Mui-active": {
        color: theme.palette.primary.main
      }
    },
    tr: {
      fontSize: "16px",
      borderBottom: "1px solid #DDDDDD"
    }
  },
  checkbox: {
    color: "#aaa !important",
    "& .MuiSvgIcon-root": {
      fontSize: "20px"
    },
    "&.Mui-checked": {
      color: "#15C7C0 !important"
    }
  },
  sortable: {
    "&:hover": {
      color: "#0C74A4"
    },
    "& .MuiTableSortLabel-icon": {
      color: "darkgrey !important"
    }
  },
  candidateCell: {
    display: "flex",
    marginLeft: 1,
    alignItems: "center",
    "& a": {
      color: "#666666",
      fontWeight: "bold",
      "&:hover": {
        textDecoration: "none"
      }
    }
  },
  sticky: {
    position: "sticky",
    left: "0px",
    background: "white",
    zIndex: 1
  },
  stickyRight: {
    position: "sticky",
    right: "0px",
    background: "white"
  },
  paginationParent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px"
  },
  rowSelectContainer: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    marginTop: "5px",
    padding: "12px",
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#666666",
    "& span": {
      marginRight: "12px",
      paddingBottom: "6px"
    }
  },
  paginationContainer: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    marginTop: "5px",
    padding: "12px",
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#666666"
  },
  pagination: {
    marginRight: "24px",
    "& button": {
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "bold",
      color: "#CCCCCC",
      margin: "0px -2px"
    },
    "& .Mui-selected": {
      color: "#666666",
      backgroundColor: "#ffffff00 !important",
      "&:hover": {
        backgroundColor: "#ffffff00 !important"
      }
    },
    "& .MuiPaginationItem-previousNext": {
      color: "#666666"
    }
  },
  rowSelect: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    color: "#666666",
    fontWeight: "bold",
    borderRadius: "6px !important",
    "& legend": {
      display: "none"
    },
    "& svg": {
      display: "none"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#DBDEDF"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#CACDCE"
    },
    "& .MuiOutlinedInput-input": {
      padding: "4px 12px 8px 12px !important"
    }
  },
  paginationMenuItems: {
    "& li": {
      fontFamily: "Source Sans Pro"
    }
  },
  noApplications: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    fontFamily: "Source Sans Pro, sans-serif",
    color: "#DDDDDD",
    fontSize: "18px",
    fontWeight: "bold",
    margin: "32px 0px"
  },
  skeletonContainer: {
    borderBottom: "1px solid #DDDDDD",
    padding: "10px 0px"
  },
  selectedTooltip: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    visibility: "hidden",
    padding: "20px 30px",
    minWidth: "665px",
    bottom: "40px",
    left: "calc(50% - 350px)",
    position: "fixed",
    zIndex: 99,
    backgroundColor: "rgba(242,242,242)",
    borderRadius: "8px",
    transform: "translateX(20px)"
  },
  fadeIn: {
    visibility: "visible",
    opacity: "1",
    transform: "translateX(0px)",
    transition: "visibility 0s linear 0s, opacity 300ms, transform 300ms"
  },
  fadeOut: {
    visibility: "hidden",
    opacity: "0",
    transform: "translateX(20px)",
    transition: "visibility 0s linear 300ms, opacity 300ms, transform 300ms"
  },
  actionsMenu: {
    "& .MuiPaper-root": {
      display: "flex",
      flexDirection: "column",
      rowGap: "10px",
      width: "135px",
      padding: "14px 0px 14px 20px",
      marginTop: "6px",
      borderRadius: "6px"
    },
    "& span": {
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "bold",
      color: "#666666",
      cursor: "pointer",
      "&:hover": {
        color: theme.palette.primary.main
      }
    }
  },
  selectedTooltipText: {
    fontFamily: "Source Sans Pro",
    fontSize: "20px",
    fontWeight: "600",
    color: "#666666"
  },
  selectedTooltipButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontWeight: "bold",
    textTransform: "none",
    color: "#D6827D",
    backgroundColor: "rgba(214, 130, 125, 0.22)",
    boxShadow: "none",
    width: "100px",
    height: "40px",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: "rgba(214, 130, 125, 0.33)",
      boxShadow: "none"
    }
  },
  selectedTooltipButtonsContainer: {
    display: "flex",
    columnGap: "18px",
    marginLeft: "8px"
  },
  selectedAllTooltipText: {
    color: "#084D6D",
    fontWeight: "700",
    textDecoration: "underline",
    borderRadius: "10px",
    padding: "4px 12px",
    "&:hover": {
      background: "rgba(171, 205, 239, 0.3)"
    }
  },
  actionsContianer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "22px",
    marginBottom: "50px"
  },
  actionButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "600",
    color: "#666666",
    backgroundColor: "#9393931F",
    padding: "10px 24px",
    borderRadius: "6px",
    border: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#93939335",
      border: "none"
    }
  },
  actionButtonAI: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "600",
    color: "#2D388D",
    backgroundColor: "#AFBCFF30",
    padding: "10px 24px",
    borderRadius: "6px",
    border: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#AFBCFF55",
      border: "none"
    }
  },
  deleteApplicant: {
    color: theme.palette.error.main,
    backgroundColor: "#D6827D33",
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: theme.palette.error.main,
      border: "none"
    }
  },
  filtersRow: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    marginBottom: "30px",
    columnGap: "17px"
  },
  iconButton: {
    border: "1px solid #EAEEF0",
    backgroundColor: "#FFFFFF",
    borderRadius: "6px",
    width: "69px",
    height: "60px",
    "& svg": {
      fontSize: "30px",
      color: "#084D6D"
    }
  },
  iconButtonOpen: {
    backgroundColor: "rgba(8,77,109,0.121)"
  },
  popover: {
    marginTop: "15px"
  },
  popoverContent: {
    width: "272px",
    padding: "22px 0px 22px 24px",
    boxShadow: "0px 2px 14px rgba(48,90,111,0.16)"
  },
  sortableField: {
    display: "flex",
    alignItems: "center",
    width: "204px"
  },
  sortableSwitchLabels: {
    justifyContent: "space-between",
    width: "100%",
    marginLeft: "4px",
    "& .MuiFormControlLabel-label": {
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "bold",
      color: "#084D6D"
    },
    "& .MuiSwitch-track": {
      backgroundColor: "rgba(0,0,0,0.38)",
      opacity: "1"
    },
    "& .Mui-checked+.MuiSwitch-track": {
      backgroundColor: "rgb(91, 196, 191, 0.17) !important",
      opacity: "1 !important"
    },
    "& .Mui-checked": {
      color: "rgb(91, 196, 191, 0.5) !important"
    }
  },
  switchLabels: {
    justifyContent: "space-between",
    width: "200px",
    "& .MuiFormControlLabel-label": {
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "bold",
      color: "#084D6D"
    },
    "& .MuiSwitch-track": {
      backgroundColor: "rgba(0,0,0,0.38)",
      opacity: "1"
    },
    "& .Mui-checked+.MuiSwitch-track": {
      backgroundColor: "rgb(91, 196, 191, 0.17) !important",
      opacity: "1 !important"
    },
    "& .Mui-checked": {
      color: "rgb(91, 196, 191, 0.5) !important"
    }
  },
  switch: {
    "& .MuiSwitch-thumb": {
      color: "#FFFFFF"
    },
    "& :hover": {
      backgroundColor: "rgba(91, 196, 191, 0.17)"
    }
  },
  switchActive: {
    "& .MuiSwitch-thumb": {
      color: "#5BC4BF"
    }
  },
  filterSwitch: {
    transform: "translateX(10px)",
    "& .MuiSwitch-thumb": {
      color: "#FFFFFF"
    },
    "& :hover": {
      backgroundColor: "rgba(91, 196, 191, 0.17)"
    }
  },
  filterSwitchActive: {
    transform: "translateX(10px)",
    "& .MuiSwitch-thumb": {
      color: "#5BC4BF"
    }
  },
  filterSwitchLabels: {
    justifyContent: "space-between",
    width: "100%",
    marginLeft: "0px",
    "& .MuiFormControlLabel-label": {
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "bold",
      color: "#084D6D"
    },
    "& .MuiSwitch-track": {
      backgroundColor: "rgba(0,0,0,0.38)",
      opacity: "1"
    },
    "& .Mui-checked+.MuiSwitch-track": {
      backgroundColor: "rgb(91, 196, 191, 0.17) !important",
      opacity: "1 !important"
    },
    "& .Mui-checked": {
      color: "rgb(91, 196, 191, 0.5) !important"
    }
  },
  labelActive: {
    "& .MuiFormControlLabel-label": {
      color: "#CCCCCC",
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "bold"
    }
  },
  searchInput: {
    fontFamily: "Source Sans Pro",
    fontSize: "20px",
    fontWeight: "regular",
    borderRadius: "6px",
    minHeight: "54px",
    "& legend": {
      display: "none"
    },
    "& svg": {
      fontSize: "31px",
      color: "#084D6D"
    },
    "& input": {
      padding: "10.5px 11px 15.5px 11px",
      color: "#000000"
    },
    "& fieldset": {
      borderColor: "#EAEEF0"
    },
    "&:hover": {
      "& fieldset": {
        borderColor: "#DADEE0 !important"
      }
    }
  },
  newApplicationButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontWeight: "bold",
    lineHeight: "1.3",
    color: theme.palette.action.main,
    backgroundColor: "#5BC4BF1F",
    height: "60px",
    width: "260px",
    borderRadius: "6px",
    border: "none",
    textTransform: "none",
    "@media  screen and (max-width: 1130px)": {
      fontSize: "14px"
    },
    "&:hover": {
      backgroundColor: "#5BC4BF35",
      border: "none"
    }
  },
  attachmentsHeader: {
    minWidth: "86px"
  },
  attachmentsCell: {
    minWidth: "86px",
    width: "50px",
    "& svg": {
      color: "#666666",
      fontSize: "18px",
      cursor: "pointer"
    }
  },
  actionItems: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  attachmentItem: {
    display: "flex",
    alignItems: "center",
    columnGap: "10px",
    padding: "14px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F5F5F5"
    }
  },
  fileIcon: {
    color: theme.palette.primary.main
  },
  fileName: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    maxWidth: "240px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  filterPopoverContent: {
    width: "272px",
    maxHeight: "500px",
    overflowY: "auto",
    boxShadow: "0px 2px 14px rgba(48,90,111,0.16)",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    position: "relative",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  scrollableFilters: {
    height: "fit-content",
    padding: "26px",
    paddingTop: "0px"
  },
  filterPopoverHeader: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "14px",
    backgroundColor: "white",
    position: "sticky",
    top: "0px",
    zIndex: 99,
    paddingTop: "26px",
    paddingBottom: "2px",
    boxShadow: "0px 6px 10px 7px #ffffff"
  },
  clearButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "10px",
    fontWeight: "bold",
    textTransform: "none",
    color: "#939393",
    backgroundColor: "rgba(147, 147, 147, 0.12)",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "5px 10px",
    "&:hover": {
      backgroundColor: "rgba(147, 147, 147, 0.24)",
      boxShadow: "none"
    },
    "& .MuiButton-endIcon svg": {
      fontSize: "10px"
    }
  },
  applyButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "10px",
    fontWeight: "bold",
    textTransform: "none",
    color: "#FFFFFF",
    backgroundColor: "#5BC4BF",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "5px 23px",
    "&:hover": {
      backgroundColor: "#4da8a4",
      boxShadow: "none"
    }
  },
  filterOptionContainer: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#084D6D",
    marginBottom: "12px"
  },
  checkboxContainer: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#084D6D",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "24px"
  },
  csvExportModal: {
    display: "flex",
    flexDirection: "column",
    padding: "60px",
    width: "520px",
    borderRadius: "6px",
    dropShadow: "0px 2px 14px #305A6F29",
    position: "absolute",
    background: "#FFFFFF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontFamily: "Source Sans Pro",
    color: "#333333"
  },
  csvExportHeader: {
    fontSize: "20px",
    fontWeight: "600"
  },
  csvExportSubheader: {
    color: "#333333",
    fontSize: "14px"
  },
  csvExportCheckboxContainer: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px",
    fontFamily: "Source Sans Pro",
    fontWeight: "bold",
    color: "#084d6d"
  },
  closeIcon: {
    position: "absolute",
    top: "20px",
    right: "20px",
    cursor: "pointer",
    color: "#DDDDDD"
  },
  chipPacket: {
    display: "flex",
    flexWrap: "wrap",
    rowGap: "20px",
    columnGap: "26px",
    marginTop: "36px"
  },
  chipSelected: {
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontWeight: "600",
    padding: "20px 12px",
    borderRadius: "25px",
    color: theme.palette.primary.main,
    backgroundColor: "#FFFFFF",
    border: "2px solid " + theme.palette.action.main,
    transition: "all 0.4s ease-in-out",
    "&:hover": {
      backgroundColor: "#FFFFFF"
    }
  },
  chip: {
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontWeight: "600",
    padding: "20px 12px",
    borderRadius: "25px",
    backgroundColor: "#FFFFFF",
    border: "2px solid #CCCCCC",
    color: "#CCCCCC",
    transition: "all 0.4s ease-in-out",
    "&:hover": {
      backgroundColor: "#FFFFFF"
    }
  },
  csvExportButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontWeight: "bold",
    textTransform: "none",
    color: "#FFFFFF",
    backgroundColor: theme.palette.action.main,
    borderRadius: "8px",
    height: "58px",
    marginTop: "42px",
    "&:hover": {
      backgroundColor: "#4da8a4"
    }
  },
  // Application modal
  actionsModal: {
    display: "flex",
    flexDirection: "column",
    padding: "60px",
    maxWidth: "900px",
    width: "100%",
    height: "84vh",
    borderRadius: "6px",
    dropShadow: "0px 2px 14px #305A6F29",
    position: "absolute",
    background: "#FFFFFF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  modalHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "28px",
    fontWeight: "bold",
    color: theme.palette.primary.main
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    margin: "46px 0px"
  },
  modalFooter: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-end",
    columnGap: "20px"
  },
  modalFormLine: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    columnGap: "50px",
    rowGap: "26px",
    alignItems: "center",
    marginBottom: "26px",
    width: "100%"
  },
  modalConfirmationButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    textTransform: "none",
    color: "#FFFFFF",
    backgroundColor: "#5BC4BF",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "8px 14px",
    minWidth: "176px",
    minHeight: "40.5px",
    "&:hover": {
      backgroundColor: "#4da8a4",
      boxShadow: "none"
    }
  },
  modalCancelButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    width: "100px",
    textTransform: "none",
    textDecoration: "underline",
    color: theme.palette.error.main,
    backgroundColor: "#FFFFFF",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "5px 0px",
    "&:hover": {
      backgroundColor: "#FFFFFF",
      textDecoration: "none",
      boxShadow: "none"
    },
    "&:focus": {
      outline: "none"
    }
  },
  fullWidthFieldContainer: {
    maxWidth: "630px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginBottom: "26px",
    rowGap: "26px"
  },
  fullWidthField: {
    width: "100%"
  },
  uploadSection: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    "& h3": {
      color: "#939393",
      fontSize: "19px",
      fontWeight: "Bold",
      marginBottom: "14px"
    }
  },
  uploadSectionContent: {
    display: "flex",
    flexWrap: "wrap",
    columnGap: "62px",
    rowGap: "34px",
    marginBlock: "18px"
  },
  uploadContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "2px",
    rowGap: "8px",
    fontSize: "17px",
    fontWeight: "600",
    color: "#AAAAAA",
    position: "relative"
  },
  uploadLabel: {
    "& span": {
      color: theme.palette.error.main,
      marginLeft: "4px"
    }
  },
  uploadError: {
    color: theme.palette.error.main,
    fontSize: "13px",
    position: "absolute",
    bottom: "-26px"
  },
  candidateIcon: {
    fontSize: "21px",
    margin: "0px 9px 1.5px 0px"
  },
  state: {
    fontSize: "13px",
    fontWeight: "bold",
    borderRadius: "6px",
    textTransform: "uppercase",
    padding: "8px 24px",
    width: "auto",
    height: "35px"
  },
  approved: {
    fontSize: "13px",
    fontWeight: "bold",
    borderRadius: "6px",
    textTransform: "uppercase",
    padding: "8px 24px",
    width: "auto",
    height: "35px",
    color: "#8CCF95",
    backgroundColor: "RGBA(140, 207, 149, 0.12)"
  },
  pending: {
    fontSize: "13px",
    fontWeight: "bold",
    borderRadius: "6px",
    textTransform: "uppercase",
    padding: "8px 24px",
    width: "auto",
    height: "35px",
    color: "#E6A052",
    backgroundColor: "RGBA(230, 160, 82, 0.12)"
  },
  draft: {
    fontSize: "13px",
    fontWeight: "bold",
    borderRadius: "6px",
    textTransform: "uppercase",
    padding: "8px 24px",
    width: "auto",
    height: "35px",
    color: "#FFFFFF",
    backgroundColor: "#CCCCCC"
  },
  declined: {
    fontSize: "13px",
    fontWeight: "bold",
    borderRadius: "6px",
    textTransform: "uppercase",
    padding: "8px 24px",
    width: "auto",
    height: "35px",
    color: "#D6827D",
    backgroundColor: "RGBA(214, 130, 125, 0.12)"
  },
  statusClamp: {
    display: "-webkit-box",
    position: "relative",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    width: "140px",
    minHeight: "35px",
    maxHeight: "53px",
    padding: "6px 20px 5px 24px",
    textAlign: "left",
    "&::after": {
      content: '"\u25BE"',
      fontSize: "13px",
      color: "#CCCCCC",
      position: "absolute",
      right: "6px",
      lineHeight: "0",
      top: "50%"
    },
    "& .status-dropdown-full": {
      visibility: "hidden",
      width: "140px",
      minHeight: "35px",
      padding: "6px 20px 5px 24px",
      textAlign: "left",
      position: "absolute",
      top: "-1px",
      left: "-1px",
      zIndex: "2",
      background: "#ffffff",
      borderRadius: "6px",
      border: "1px solid #CCCCCC",
      "&::after": {
        content: '"\u25BE"',
        fontSize: "13px",
        color: "#CCCCCC",
        position: "absolute",
        right: "6px",
        lineHeight: "0"
      }
    },
    "&:hover": {
      overflow: "visible",
      WebkitLineClamp: "unset",
      "& .status-dropdown-full": {
        visibility: "visible"
      }
    }
  },
  statusColour: {
    position: "absolute",
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    top: "calc(50% - 3px)",
    left: "10px"
  },
  noDropdown: {
    border: "1px solid #FFFFFF",
    "& .status-dropdown-full": {
      border: "1px solid #FFFFFF"
    },
    "&::after": {
      display: "none"
    }
  },
  noStatuses: {
    width: "100%",
    paddingLeft: "18px",
    display: "flex"
  },
  statusDropdown: {
    border: "1px solid #CCCCCC",
    borderRadius: "6px",
    cursor: "pointer",
    "&::after": {
      display: "block"
    }
  },
  statusDropdownItem: {
    fontFamily: "Source Sans Pro",
    fontSize: "13px",
    padding: "6px 12px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F1F1F1"
    }
  },
  statusDropdownItems: {
    "& .MuiPaper-root": {
      width: "200px",
      maxHeight: "250px",
      overflowY: "auto",
      borderRadius: "6px",
      marginTop: "6px"
    }
  },
  statusLoader: {
    color: theme.palette.primary.main,
    position: "absolute",
    zIndex: "4",
    top: "calc(50% - 9px)",
    left: "calc(50% - 9px)"
  },
  applicationStatus: {
    "& span": {
      cursor: "pointer"
    }
  },
  formCheckbox: {
    color: "#ddd !important",
    "& .MuiSvgIcon-root": {
      fontSize: "20px"
    },
    "&.Mui-checked": {
      color: "#15C7C0 !important"
    }
  },
  checkboxLabel: {
    "& .MuiTypography-root": {
      fontFamily: "Source Sans Pro",
      fontSize: "16px",
      color: "#939393"
    },
    "& span": {
      color: theme.palette.error.main
    }
  },
  actionModalFormLine: {
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    columnGap: "50px",
    rowGap: "26px",
    alignItems: "center",
    marginBottom: "26px",
    width: "100%",
    marginLeft: "12px"
  },
  actionModalHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "28px",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    marginLeft: "8px"
  },
  actionsModalReducedPadding: {
    display: "flex",
    flexDirection: "column",
    padding: "60px",
    paddingLeft: "48px",
    maxWidth: "900px",
    width: "100%",
    height: "84vh",
    borderRadius: "6px",
    dropShadow: "0px 2px 14px #305A6F29",
    position: "absolute",
    background: "#FFFFFF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    "& h3": {
      color: "#939393",
      fontSize: "19px",
      fontWeight: "Bold",
      marginBottom: "30px"
    }
  },
  modalCandidatesHeader: {
    color: "#939393",
    fontSize: "19px",
    fontWeight: "Bold",
    marginBottom: 3
  },
  modalSendButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    textTransform: "none",
    color: "#FFFFFF",
    backgroundColor: "#5BC4BF",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "8px 19px",
    "&:hover": {
      backgroundColor: "#4da8a4",
      boxShadow: "none"
    },
    "&:focus": {
      outline: "none"
    }
  },
  selectedApplicantsTable: {
    tableLayout: "auto !important",
    minWidth: "700px",
    width: "unset",
    border: "none",
    "td, th, tr, thead": {
      fontFamily: "Source Sans Pro, sans-serif",
      border: "none",
      backgroundColor: "#FFFFFF",
      textTransform: "none",
      padding: "5px 8px 10px 0px"
    },
    th: {
      color: theme.palette.primary.main,
      fontWeight: "bold",
      "& .Mui-active": {
        color: theme.palette.primary.main
      }
    },
    tr: {
      fontSize: "16px",
      border: "none"
    }
  },
  selectedApplicantsTableHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontWeight: "600",
    color: theme.palette.primary.main,
    marginTop: "24px",
    marginBottom: "20px"
  },
  statusClampSelected: {
    display: "-webkit-box",
    position: "relative",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    width: "140px",
    minHeight: "35px",
    maxHeight: "53px",
    padding: "6px 20px 5px 24px",
    textAlign: "left",
    "& .status-dropdown-full": {
      visibility: "hidden",
      width: "140px",
      minHeight: "35px",
      padding: "6px 20px 5px 24px",
      textAlign: "left",
      position: "absolute",
      top: "-1px",
      left: "-1px",
      zIndex: "2",
      background: "#ffffff",
      borderRadius: "6px",
      border: "1px solid #CCCCCC"
    },
    "&:hover": {
      overflow: "visible",
      WebkitLineClamp: "unset",
      "& .status-dropdown-full": {
        visibility: "visible"
      }
    }
  },
  statusDropdownSelected: {
    border: "1px solid #CCCCCC",
    borderRadius: "6px",
    "&::after": {
      display: "block"
    }
  },
  candidateSearch: {
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontWeight: "regular",
    color: "#939393",
    borderRadius: "6px",
    width: "320px",
    "& .MuiOutlinedInput-root": {
      paddingLeft: "3px"
    },
    "& legend": {
      display: "none"
    },
    "& svg": {
      fontSize: "22px",
      color: "#939393",
      transform: "translate(-6px, -1px)"
    },
    "& input": {
      padding: "8px 9px 12px 0px",
      color: "#000000"
    },
    "& fieldset": {
      borderColor: "#939393",
      backgroundColor: "#AAAAAA09"
    },
    "&:hover": {
      "& fieldset": {
        borderColor: "#6DB5B1 !important"
      }
    }
  },
  sourceContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "30px",
    "& h3": {
      color: "#939393",
      fontSize: "19px",
      fontWeight: "Bold",
      marginBlock: "30px",
      marginLeft: "2px"
    }
  },
  saveSMSTemplateContainer: {
    position: "relative",
    display: "flex",
    alignItems: "baseline",
    columnGap: "40px",
    marginLeft: "15px",
    marginBottom: "20px"
  },
  saveSMSTemplateButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    textTransform: "none",
    color: "#FFFFFF",
    backgroundColor: "#5BC4BF",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "0px 19px",
    height: "37.5px",
    width: "132px",
    "&:hover": {
      backgroundColor: "#4da8a4",
      boxShadow: "none"
    }
  },
  saveSMSTemplateError: {
    color: theme.palette.error.main,
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "13px",
    position: "absolute",
    bottom: "-24px",
    left: "4px"
  },
  modalSection: {
    marginLeft: "15px",
    marginTop: "15px"
  },
  formLabel: {
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    color: "#838383",
    marginBottom: "5px"
  },
  placeholderText: {
    fontFamily: "Source Sans Pro",
    color: "#838383",
    fontSize: "11px",
    margin: "6px 0px 20px 1px"
  },
  fileIconContainer: {
    display: "flex",
    alignItems: "center",
    width: "90%"
  },
  formError: {
    color: theme.palette.error.main,
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "13px",
    position: "absolute",
    bottom: "-24px",
    left: "4px"
  },
  formGroup: {
    position: "relative",
    marginTop: "36px",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "column",
    marginLeft: "15px"
  },
  formSwitchLabels: {
    justifyContent: "space-between",
    width: "200px",
    marginLeft: "3px",
    "& .MuiFormControlLabel-label": {
      fontFamily: "Source Sans Pro",
      fontSize: "16px",
      color: "#939393"
    },
    "& .MuiSwitch-track": {
      backgroundColor: "rgba(0,0,0,0.38)",
      opacity: "1"
    },
    "& .Mui-checked+.MuiSwitch-track": {
      backgroundColor: "rgb(91, 196, 191, 0.17) !important",
      opacity: "1 !important"
    }
  },
  timeslotsContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between"
  },
  calendar: {
    ".MuiPickersCalendarHeader-root": {
      display: "flex",
      alignItems: "center",
      justifyItems: "center",
      position: "relative",
      paddingInline: "initial"
    },
    "& .Mui-selected": {
      backgroundColor: `${theme.palette.action.main} !important`
    },
    "& .MuiTypography-root": {
      fontFamily: "Source Sans Pro",
      color: "#939393",
      fontSize: "13px",
      marginInline: "5px"
    },
    ".MuiPickersCalendarHeader-root:first-child": {
      order: 0
    },
    ".MuiPickersArrowSwitcher-root": {
      display: "inline-flex",
      "& .MuiIconButton-root": {
        color: "#bfbfc0",
        "& svg": {
          width: "1.15em",
          height: "1.15em"
        }
      }
    },
    ".MuiPickersCalendarHeader-label": {
      textAlign: "center"
    },
    ".MuiPickersArrowSwitcher-spacer": {
      width: "220px"
    },
    "& .MuiPickersArrowSwitcher-root": {
      width: "100%",
      "& .MuiPickersArrowSwitcher-spacer": {
        width: "100%"
      },
      "& .MuiIconButton-root": {
        color: "#bfbfc0",
        "& svg": {
          width: "1.15em",
          height: "1.15em"
        }
      }
    },
    "& .MuiPickersDay-root": {
      fontFamily: "Source Sans Pro",
      fontSize: "14.5px",
      marginInline: "5px"
    },
    ".MuiPickersArrowSwitcher-button": {
      paddingRight: "7px"
    }
  },
  dailyTimeslots: {
    display: "flex",
    flexDirection: "column",
    width: "410px"
  },
  dailyTimeslotsHeader: {
    fontSize: "18px",
    color: "#939393",
    marginBottom: "26px",
    marginTop: "17px"
  },
  dailyTimeslotsContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px"
  },
  addToEventTimeslotsContainer: {
    width: "100%",
    rowGap: "12px",
    height: "275px",
    overflow: "auto",
    position: "relative",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  addToEventTimeslot: {
    borderRadius: "6px",
    padding: "8px 16px",
    fontWeight: "bold",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  addToEventSecondStepContainer: {
    flexGrow: 1,
    padding: "0px 8px",
    overflow: "auto"
  },
  addToEventEmailPlaceholder: {
    fontSize: "11px",
    color: "#838383",
    lineBreak: "anywhere"
  },
  addToEventListboxProps: {
    fontFamily: "Source Sans Pro, sans-serif",
    color: "#333333",
    maxHeight: "200px"
  },
  addToEventCharacterCount: {
    paddingRight: 1,
    flexDirection: "row-reverse",
    color: "#838383",
    minWidth: "55px"
  },
  timeslot: {
    position: "relative",
    border: "1px solid #eaeef0",
    borderRadius: "4px",
    width: "115px",
    height: "46px",
    padding: "7px 11px"
  },
  expandedTimeslot: {
    position: "absolute",
    border: "1px solid #eaeef0",
    borderRadius: "4px",
    backgroundColor: "#fff",
    width: "115px",
    height: "fit-content",
    padding: "7px 11px",
    zIndex: "2",
    top: "-1px",
    left: "-1px"
  },
  timeslotTime: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    color: "#666666",
    fontWeight: "bold",
    lineHeight: "1.2"
  },
  timeslotType: {
    fontFamily: "Source Sans Pro",
    fontSize: "11px",
    color: "#666666",
    fontWeight: "bold",
    lineHeight: "1.2"
  },
  ellipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  timeslotNote: {
    fontFamily: "Source Sans Pro",
    fontSize: "9px",
    color: "#939393",
    paddingTop: "4px",
    lineHeight: "1.2"
  },
  noTimeslots: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    color: "#939393",
    fontWeight: "bold",
    width: "100%",
    height: "210px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  characterCount: {
    fontFamily: "Source Sans Pro",
    fontSize: "13px",
    color: "#999999",
    fontWeight: "600",
    position: "absolute",
    bottom: "-22px",
    right: "58px"
  },
  centerColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  exportErrors: {
    rowGap: "18px",
    "& h4": {
      color: theme.palette.primary.main,
      marginTop: "0px",
      fontSize: "24px"
    },
    "& p": {
      marginTop: "0px"
    }
  },
  linkContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    columnGap: "20px",
    width: "100%",
    "& a:hover": {
      textDecoration: "none"
    }
  },
  link: {
    fontSize: "16px",
    color: "#939393",
    cursor: "pointer",
    transition: "color 0.2s ease-in-out",
    "&:hover": {
      color: "#737373"
    },
    "@media (max-width: 1450px)": {
      fontSize: "16px"
    },
    "@media (max-width: 1260px)": {
      fontSize: "14px"
    }
  },
  copyButton: {
    fontSize: "12px",
    fontWeight: "bold",
    backgroundColor: "#5BC4BF1F",
    color: theme.palette.action.main,
    borderRadius: "6px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.action.main,
      color: "#FFFFFF"
    }
  },
  noLinkStyling: {
    "& a": {
      color: "rgba(0, 0, 0, 0.87)",
      "&:hover": {
        textDecoration: "none",
        color: "rgba(0, 0, 0, 0.87)"
      }
    }
  },
  filterButton: {
    border: "1px solid #DDDDDD",
    borderRadius: 1,
    width: "fit-content",
    padding: "8px 12px"
  },
  noteContainer: {
    "& .MuiTextField-root": { width: "27ch" },
    width: "fit-content"
  },
  notesContainer: {
    flexDirection: "column",
    rowGap: "4px",
    paddingLeft: "16px",
    maxHeight: "100px",
    overflowY: "auto",
    textOverflow: "ellipsis"
  },
  noteTitle: {
    fontWeight: "bold"
  },
  noteUser: {
    margin: "10px 0"
  },
  notesExpanded: {
    maxHeight: "unset"
  },
  notesExpandContract: {
    position: "absolute",
    bottom: "8px",
    right: "8px",
    color: theme.palette.primary.main,
    cursor: "pointer"
  },
  noteEditButtonContainer: {
    marginTop: theme.spacing(2),
    gap: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    width: "fit-content"
  },
  scrollShadowLeft: {
    "&::after": {
      content: '""',
      background: "#aaaaaa",
      width: "2px",
      height: "100%",
      filter: "blur(2px)",
      position: "absolute",
      top: "0",
      right: "-2px",
      opacity: "0%",
      transition: "opacity 0.17s linear"
    },
    "&::before": {
      content: '""',
      top: "0",
      right: "0",
      width: "6px",
      height: "100%",
      position: "absolute",
      background: "#ffffff",
      zIndex: "2"
    }
  },
  scrollShadowRight: {
    "&::after": {
      content: '""',
      background: "#aaaaaa",
      width: "2px",
      height: "100%",
      filter: "blur(2px)",
      position: "absolute",
      top: "0",
      left: "-2px",
      opacity: "0%",
      transition: "opacity 0.17s linear"
    },
    "&::before": {
      content: '""',
      top: "0",
      left: "0",
      width: "6px",
      height: "100%",
      position: "absolute",
      background: "#ffffff",
      zIndex: "2"
    }
  },
  scrollShadowVisible: {
    "&&::after": {
      opacity: "20%"
    }
  },
  notesTitle: {
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    color: theme.palette.primary.main,
    fontWeight: "bold",
    marginBottom: "12px",
    width: "100%"
  },
  editNotePopover: {
    "& .MuiPaper-root": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-end",
      rowGap: "10px",
      width: "300px",
      minHeight: "150px",
      padding: "17px 17px 14px 17px"
    }
  },
  noteInput: {
    marginBottom: "4px",
    fontFamily: "Source Sans Pro",
    fontSize: "12px",
    "& input": {
      padding: "9.5px 14px"
    }
  },
  notesButtonContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    columnGap: "10px"
  },
  notesEditSaveButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    width: "86px",
    textTransform: "none",
    color: "#FFFFFF",
    backgroundColor: "#5BC4BF",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "5px 0px",
    "&:hover": {
      backgroundColor: "#4da8a4",
      boxShadow: "none"
    },
    "& svg": {
      color: "#FFFFFF",
      fontSize: "16px !important"
    },
    "&:disabled": {
      backgroundColor: "#EEEEEE",
      color: "#BBBBBB",
      transition: "all 250ms ease-in-out"
    }
  },
  notesCancelButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    width: "86px",
    textTransform: "none",
    color: "#939393",
    backgroundColor: "rgba(147, 147, 147, 0.12)",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "5px 0px",
    "&:hover": {
      backgroundColor: "rgba(147, 147, 147, 0.24)",
      boxShadow: "none"
    },
    "& svg": {
      color: theme.palette.error.main,
      fontSize: "16px !important"
    }
  },
  arrowsContainer: {
    height: 0,
    overflow: "visible",
    position: "sticky",
    top: "200px",
    zIndex: "10",
    transform: "translateY(100px)"
  },
  leftArrow: {
    display: "none",
    alignItems: "center",
    justifyContent: "center",
    position: "sticky",
    width: "40px",
    height: "65px",
    background: "black",
    bottom: "370px",
    borderRadius: "0px 10px 10px 0px",
    background: "#eeeeee",
    zIndex: "11",
    opacity: "0.9",
    cursor: "pointer"
  },
  rightArrow: {
    display: "none",
    alignItems: "center",
    justifyContent: "center",
    position: "sticky",
    width: "40px",
    height: "65px",
    background: "black",
    bottom: "370px",
    borderRadius: "10px 0px 0px 10px",
    background: "#eeeeee",
    zIndex: "11",
    opacity: "0.9",
    cursor: "pointer"
  },
  showArrows: {
    display: "flex",
    animation: "$fadeIn linear 0.17s"
  },
  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 0.9 }
  },
  rating: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "3px",
    width: "58px",
    height: "24px"
  },
  tableSettingsHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontWeight: "bold",
    paddingBottom: 1,
    paddingLeft: "4px"
  },
  aiQuestionsButton: {
    marginLeft: "8px",
    padding: "4px",
    background: "linear-gradient(225.64deg, #E548FF -23.43%, #084D6D 140.57%, #084D6D 140.58%, #5BC4C0 140.6%)",
    transition: "all 250ms linear",
    "&:hover": {
      filter: "brightness(1.16)"
    },
    "& svg": {
      fontSize: "11px !important",
      color: "#FFFFFF !important"
    }
  },
  titleLink: {
    "& a": {
      color: "#666666",
      fontWeight: "bold",
      "&:hover": {
        textDecoration: "none"
      }
    },
    paddingLeft: 2
  },
  enabledDropdown: {
    "& .MuiPaper-root": {
      display: "flex",
      flexDirection: "column",
      rowGap: "11px",
      padding: "14px 20px",
      marginTop: "6px",
      borderRadius: "6px",
      backgroundColor: "#FFFFFF"
    },
    cursor: "pointer"
  },
  availabilityColumnCell: {
    padding: 1,
    borderRadius: "6px",
    alignItems: "center",
    fontWeight: "bold",
    width: "70%"
  },
  applicationBetaButton: {
    padding: "4px 12px",
    borderRadius: "30px",
    backgroundColor: "rgba(247, 161, 255, 0.2)",
    color: "#F7A1FF",
    fontWeight: "bold",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    fontSize: "12px"
  },
  notesContainer: {
    width: "300px",
    minHeight: "150px",
    padding: "17px 17px 14px 17px"
  }
};
