"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
export const getAnswerAttributes = (question, index, answers, ratings, formResponse) => {
  switch (question.type) {
    case "RadioButtonField":
    case "SelectField":
      return __spreadValues({
        field_id: question.id,
        field_choice_id: answers[question.id],
        rating_value: ratings[question.id]
      }, formResponse && formResponse[index].id && { id: formResponse[index].id });
    case "CheckBoxField":
      return __spreadValues({
        field_id: question.id,
        field_choice_ids: answers[question.id] && Object.entries(answers[question.id]).filter(([_, value]) => value).map(([id]) => id),
        rating_value: ratings[question.id]
      }, formResponse && formResponse[index].id && { id: formResponse[index].id });
    default:
      return __spreadValues({
        field_id: question.id,
        data: answers[question.id],
        rating_value: ratings[question.id]
      }, formResponse && formResponse[index].id && { id: formResponse[index].id });
  }
};
