import React, { Dispatch, useRef, useState } from 'react';
import StyledModal from '../../../NewUI/Components/GenericModal/StyledModal';
import { emailIsValid } from '../../../NewUI/Components/Utilities/emailIsValid';
import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import MultiTagInput from '../../../NewUI/Components/CustomUIElements/MultiTagInput';
import { sharedClasses } from '../../../NewUI/Components/CustomUIElements/sharedClasses';
import ModalFooterButtons from '../../../NewUI/Components/GenericModal/ModalFooterButtons';
import { ApplicationState, ApplicationAction, IApplication } from '../types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ModalType } from '../config';
import { styles } from '../styles';
import { MultilineFormTextField } from '../../Components/CustomUIElements/FormTextField';
import Api from '../API';

export default function EmailExternal({
  ApplicationState,
  dispatch,
  aiSummariesEnabled
}: {
  ApplicationState: ApplicationState;
  dispatch: Dispatch<ApplicationAction>;
  aiSummariesEnabled: boolean;
}) {
  const [emails, setEmails] = useState<string[]>([]);
  const [submissionError, setSubmissionError] = useState<string>('');
  const [includeAttachments, setIncludeAttachments] = useState<boolean>(false);
  const [includeSmartForms, setIncludeSmartForms] = useState<boolean>(false);
  const [includeSummary, setIncludeSummary] = useState<boolean>(false);
  const [notes, setNotes] = useState<string>('');
  const emailInputRef = useRef<HTMLDivElement>(null);
  const { modalsOpen } = ApplicationState;

  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);

  const handleClose = () => {
    dispatch({ type: 'SET_MODALS_OPEN', payload: null });
  };

  const { mutate: sendEmail, isLoading: sendingEmail } = useMutation({
    mutationFn: async () => {
      if (application) {
        const { res } = await Api.sendExternalEmails(application.job.id, {
          application_ids: [application.id],
          external_view: {
            emails: emails.join(','),
            full_view: includeAttachments,
            smart_forms: includeSmartForms,
            include_summary: includeSummary,
            notes
          }
        });
        return res;
      }
    },
    onSuccess: (res) => {
      dispatch({ type: 'SET_SNACKBAR', payload: { message: res.message, state: 'success' } });
      handleClose();
    },
    onError: () =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message:
            'Error sending emails, please try again or contact support if the issue persists',
          state: 'error'
        }
      })
  });

  return (
    <StyledModal
      isOpen={modalsOpen === ModalType.EMAIL_EXTERNAL}
      handleClose={handleClose}
      label="External Email"
      styleOverrides={{ ...styles.modalStyleOverrides, height: 'unset' }}
    >
      <Stack sx={styles.modalContainer}>
        <Stack sx={styles.modalTitle}>Email external</Stack>
        <Stack sx={{ rowGap: 2 }}>
          <Stack sx={{ fontSize: '18px', fontWeight: 'bold', color: '#666666' }}>
            Recipient details
          </Stack>
          <Stack ref={emailInputRef}>
            <MultiTagInput
              tags={emails}
              setTags={setEmails}
              validation={emailIsValid}
              validationMessage="Must input valid email"
              label="Email address/s"
              width="100%"
              error={submissionError}
              tagType="emails"
              helperText="To copy and paste bulk emails, email addresses must be separated with a comma"
              required
            />
            <Stack sx={{ flexDirection: 'row', columnGap: 2, marginLeft: '3px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={sharedClasses.formCheckbox}
                    onChange={(e) => setIncludeAttachments(e.target.checked)}
                    value={includeAttachments}
                  />
                }
                label="Include Attachments"
                sx={{
                  ...sharedClasses.checkboxLabel,
                  '.MuiFormControlLabel-label': { fontSize: '14px' }
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={sharedClasses.formCheckbox}
                    onChange={(e) => setIncludeSmartForms(e.target.checked)}
                    value={includeSmartForms}
                  />
                }
                label="Include Smart Forms"
                sx={{
                  ...sharedClasses.checkboxLabel,
                  '.MuiFormControlLabel-label': { fontSize: '14px' }
                }}
              />
              {aiSummariesEnabled && (
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={sharedClasses.formCheckbox}
                      onChange={(e) => setIncludeSummary(e.target.checked)}
                      value={includeSummary}
                    />
                  }
                  label="Include Felix AI Summary"
                  sx={{
                    ...sharedClasses.checkboxLabel,
                    '.MuiFormControlLabel-label': { fontSize: '14px' }
                  }}
                />
              )}
            </Stack>
          </Stack>
          <MultilineFormTextField
            label="Notes"
            rows={4}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            styles={{ marginLeft: '3px' }}
          />
        </Stack>
        <ModalFooterButtons
          primaryButtonText="Send"
          primaryButtonCallback={() =>
            emails.length ? sendEmail() : setSubmissionError('Must input at least one valid email')
          }
          secondaryButtonText="Cancel"
          secondaryButtonCallback={handleClose}
          isLoading={sendingEmail}
          primaryButtonMinWidth="75px"
        />
      </Stack>
    </StyledModal>
  );
}
