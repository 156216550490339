"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { defaultSections, InitialTimeStampState } from "./config";
export const InitialApplicationState = {
  apiKey: "",
  snackbar: {
    message: "",
    state: "success"
  },
  modalsOpen: null,
  actions: {
    anchorEl: null
  },
  sortableSections: defaultSections,
  emailPlaceholders: "",
  editedTask: null,
  selectedAttachmentAction: {
    preview: null,
    delete: null
  },
  selectedFormResponseAction: {
    edit: null,
    delete: null
  },
  timeStampState: InitialTimeStampState
};
export const ApplicationReducer = (state, action) => {
  switch (action.type) {
    case "SET_APIKEY":
      return __spreadProps(__spreadValues({}, state), { apiKey: action.payload });
    case "SET_SNACKBAR":
      return __spreadProps(__spreadValues({}, state), { snackbar: action.payload });
    case "SET_MODALS_OPEN":
      return __spreadProps(__spreadValues({}, state), { modalsOpen: action.payload });
    case "SET_ACTIONS":
      return __spreadProps(__spreadValues({}, state), { actions: action.payload });
    case "SET_SORTABLE_SECTIONS":
      return __spreadProps(__spreadValues({}, state), { sortableSections: action.payload });
    case "SET_EMAIL_PLACEHOLDERS":
      return __spreadProps(__spreadValues({}, state), { emailPlaceholders: action.payload });
    case "SET_EDITED_TASK":
      return __spreadProps(__spreadValues({}, state), { editedTask: action.payload });
    case "SET_SELECTED_ATTACHMENT_ACTION":
      return __spreadProps(__spreadValues({}, state), { selectedAttachmentAction: action.payload });
    case "SET_SELECTED_FORM_RESPONSE_ACTION":
      return __spreadProps(__spreadValues({}, state), { selectedFormResponseAction: action.payload });
    case "SET_TIMESTAMP_STATE":
      return __spreadProps(__spreadValues({}, state), {
        timeStampState: __spreadProps(__spreadValues({}, state.timeStampState), { [action.payload.name]: action.payload.value })
      });
    case "RESET_TIMESTAMP_STATE":
      return __spreadProps(__spreadValues({}, state), {
        timeStampState: InitialTimeStampState
      });
    default:
      return state;
  }
};
