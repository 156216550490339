import React, { useState } from 'react';
import { Box, Button, CircularProgress, Modal, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { classes } from '../../Components/Modals/styles';
import { ApplicationAction, ApplicationState, IApplication, IAttachment } from '../types';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import Api from '../API';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import TimestampTable from './Components/TimestampTable';
import ImagePreview from '../AttachmentsTab/ImagePreview';
import VideoPreview from '../AttachmentsTab/VideoPreview';
import AudioPreview from '../AttachmentsTab/AudioPreview';
import TextPreview from '../AttachmentsTab/TextPreview';
import PdfPreview from '../AttachmentsTab/PdfPreview';
import WordPreview from '../AttachmentsTab/WordPreview';
import { getAttachmentType } from '../../utils/attachment-type';

dayjs.extend(duration);

export default function AttachmentPreviewModal({
  previewOpen,
  dispatch,
  selectedAttachment,
  ApplicationState
}: {
  previewOpen: boolean;
  dispatch: React.Dispatch<ApplicationAction>;
  selectedAttachment: IAttachment;
  ApplicationState: ApplicationState;
}) {
  const BASE_URL = window.location.origin;
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const [isLoadingPreview, setIsLoadingPreview] = useState<boolean>(true);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);
  const attachmentType = getAttachmentType(selectedAttachment);

  const handleClose = () => {
    dispatch({
      type: 'SET_SELECTED_ATTACHMENT_ACTION',
      payload: { delete: null, preview: null }
    });
    dispatch({ type: 'RESET_TIMESTAMP_STATE' });
    setIsLoadingPreview(true);
  };

  const { data: previewData } = useQuery({
    queryKey: ['preview_data', currentIndex],
    queryFn: async () => {
      if (selectedAttachment.id && application) {
        const { res } = await Api.previewAttachment(
          application.job.id,
          application.id,
          selectedAttachment.id,
          currentIndex
        );
        return res;
      }
    },
    onError: (error: { message: string }) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting preview data, ${error}`,
          state: 'error'
        }
      }),
    onSettled: () => setIsLoadingPreview(false),
    enabled: !!selectedAttachment && (attachmentType.isVideo || attachmentType.isWord)
  });

  const handleDisplayContent = () => {
    if (!selectedAttachment) return null;
    const { isImage, isVideo, isAudio, isText, isPdf, isWord } =
      getAttachmentType(selectedAttachment);

    if (isImage) {
      return (
        <ImagePreview
          selectedAttachment={selectedAttachment}
          isLoadingPreview={isLoadingPreview}
          setIsLoadingPreview={setIsLoadingPreview}
        />
      );
    } else if (isVideo && previewData?.video) {
      return (
        <VideoPreview
          selectedAttachment={selectedAttachment}
          videoRef={videoRef}
          previewData={previewData}
          isLoadingPreview={isLoadingPreview}
          setIsLoadingPreview={setIsLoadingPreview}
        />
      );
    } else if (isAudio) {
      return (
        <AudioPreview
          selectedAttachment={selectedAttachment}
          setIsLoadingPreview={setIsLoadingPreview}
        />
      );
    } else if (isText) {
      return (
        <TextPreview
          selectedAttachment={selectedAttachment}
          dispatch={dispatch}
          setIsLoadingPreview={setIsLoadingPreview}
        />
      );
    } else if (isPdf) {
      return (
        <PdfPreview
          selectedAttachment={selectedAttachment}
          isLoadingPreview={isLoadingPreview}
          setIsLoadingPreview={setIsLoadingPreview}
        />
      );
    } else if (isWord) {
      return (
        <WordPreview
          selectedAttachment={selectedAttachment}
          previewData={previewData}
          setCurrentIndex={setCurrentIndex}
          setIsLoadingPreview={setIsLoadingPreview}
        />
      );
    } else {
      return (
        !isLoadingPreview && (
          <Box sx={classes.noPreview}>No preview available for this file type.</Box>
        )
      );
    }
  };

  return (
    <Modal open={previewOpen} onClose={handleClose} aria-labelledby="file-preview-modal">
      <Stack
        sx={{
          ...classes.attachmentPreviewModal,
          height: 'auto',
          maxHeight: '80vh',
          minHeight: '40vh'
        }}
        justifyContent="space-between"
      >
        <CloseIcon
          id="close-attachment-preview-button"
          onClick={handleClose}
          sx={classes.closeIcon}
          className="close-button"
        />
        <Box id="file-preview-modal-title" sx={classes.attachmentPreviewTitle}>
          {selectedAttachment.attached_file_name}
        </Box>
        <Box sx={classes.attachmentPreviewContent}>
          {handleDisplayContent()}
          {isLoadingPreview && (
            <Box sx={{ ...classes.previewLoader, ...classes.absoluteLoader }}>
              <CircularProgress sx={{ color: '#084D6D' }} />
              Loading Preview
            </Box>
          )}
        </Box>
        {!attachmentType.isVideo && !isLoadingPreview && (
          <Box sx={classes.attachmentPreviewActions} marginTop={'auto'}>
            <a href={`${BASE_URL}/admin/assets/${selectedAttachment.id}`}>
              <Button id="download-attachment-button" sx={classes.downloadButton}>
                Download
              </Button>
            </a>
          </Box>
        )}
        {attachmentType.isVideo && !isLoadingPreview && (
          <Box width={'100%'} sx={{ paddingY: '20px' }} marginTop={'auto'}>
            <TimestampTable
              selectedAttachment={selectedAttachment}
              dispatch={dispatch}
              videoRef={videoRef}
              ApplicationState={ApplicationState}
            />
          </Box>
        )}
      </Stack>
    </Modal>
  );
}
