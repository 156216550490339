import React, { Dispatch, useContext, useState, useEffect } from 'react';
import { Box, Button, Stack, Modal } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { classes } from './styles';
import { SystemReportsState, SystemReportsAction } from './reducer';
import { useQuery, useMutation } from '@tanstack/react-query';
import { ReportContext, RecruitReportContext } from '../index';
import SystemReportPreviewTable from './SystemReportPreviewTable';
import RecruitApi from '../RecruitApi';
import GenericDialog from '../../NewUI/Components/Modals/GenericDialog';
import { saveAs } from 'file-saver';
import DownloadPreview from '../CustomReports/ModalContents/DownloadPreview';
import { sharedClasses } from '../../NewUI/Components/CustomUIElements/sharedClasses';
import FilterModals from './FilterModals';
import { ISystemReportsRequestParams } from '../types';
import SystemReportPreviewHeader from './SystemReportPreviewHeader';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import toObject from 'dayjs/plugin/toObject';
import { endOfDay, startOfDay } from '../../NewUI/Components/Charts/helper';
import GenericTablePagination from '../../NewUI/Components/GenericTable/GenericTablePagination';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(toObject);

export default function SystemReportPreview({
  state,
  dispatch
}: {
  state: SystemReportsState;
  dispatch: Dispatch<SystemReportsAction>;
}) {
  const {
    openPreview,
    reportPeriod,
    groupBy,
    selectedEntities,
    jobStates,
    notExpired,
    countryState,
    applicationStatus,
    approvalState,
    approvalForm,
    cognology,
    selectedJobs,
    selectedUser,
    statusId,
    selectedUsers,
    questions,
    scoutClient,
    drilldown,
    includeNewApplications,
    currentPage,
    rowsPerPage
  } = state;
  const [isExitDialogOpen, setIsExitDialogOpen] = useState<boolean>(false);
  const [downloadModal, setDownloadModal] = useState<boolean>(false);
  const reportServiceApi = useContext(ReportContext);
  const recruitReportApiService = useContext(RecruitReportContext);
  const isOnboardExpressReport = openPreview === 'Onboard Express Report';
  const isAurionReport = openPreview === 'Aurion Report';
  const isUserEventsReport = openPreview === 'User Events Report';
  const isJobStatesReport = openPreview === 'Job State Report';
  const userTimezone = reportServiceApi.currentUser.iana_timezone;
  const startDate = startOfDay(reportPeriod.startDate, userTimezone);
  const endDate = endOfDay(reportPeriod.endDate, userTimezone);
  const [refetchInterval, setRefetchInterval] = useState<boolean | number>(5000);

  const params: ISystemReportsRequestParams = {
    start_date: startDate,
    end_date: endDate,
    entity_id: selectedEntities.length ? selectedEntities : undefined,
    job_state: jobStates.length ? jobStates : undefined,
    not_expired: notExpired ? notExpired : undefined,
    candidate_state: countryState ? countryState : undefined,
    application_status_name:
      openPreview === 'Daily Applications Report' && applicationStatus.length
        ? applicationStatus
        : undefined,
    approval_state: openPreview === 'Approvals Report' ? approvalState : undefined,
    approval_id: approvalForm ? approvalForm : undefined,
    job_id: selectedJobs.length ? selectedJobs : undefined,
    actor_id: selectedUser ? selectedUser : undefined,
    user_id: selectedUsers.length && !selectedUsers.includes(0) ? selectedUsers : undefined,
    questions: questions.length ? { question_title: questions } : undefined,
    scout_client: scoutClient ? scoutClient : undefined,
    drilldown: drilldown ? drilldown : undefined,
    'status_activities.status_name':
      openPreview === 'Placements Advanced Report' && applicationStatus.length
        ? applicationStatus
        : undefined,
    new_applications: includeNewApplications ? includeNewApplications : undefined,
    page: currentPage,
    limit: rowsPerPage
  };

  const aurionParams = {
    start_date: startDate,
    end_date: endDate,
    status_id: statusId ? statusId : undefined
  };

  const userEventsParams = {
    user_events: {
      start_date: reportPeriod.startDate,
      end_date: reportPeriod.endDate,
      entity_id: selectedEntities.length ? selectedEntities : reportServiceApi.accessibleEntities,
      actor_id: selectedUser ? selectedUser : undefined
    }
  };

  const { data: previewData, isLoading: loadingPreviewData } = useQuery({
    queryKey: [
      openPreview,
      reportPeriod,
      approvalState,
      approvalForm,
      countryState,
      selectedEntities,
      selectedUser,
      jobStates,
      applicationStatus,
      selectedJobs,
      selectedUsers,
      questions,
      groupBy,
      notExpired,
      scoutClient,
      drilldown,
      includeNewApplications
    ],
    queryFn: async () => {
      const { data, headers } = await reportServiceApi.systemReports.data(
        params,
        openPreview,
        `${openPreview === 'Applications Report' ? groupBy : ''}`,
        'preview'
      );
      if (openPreview === 'Tags Report') {
        const updatedData = data?.map((datum: any) => ({
          ...datum,
          attributes: {
            ...datum.attributes,
            candidate_tags: datum?.attributes?.candidate_tags
              ?.map((tag: any) => tag.candidate_tags)
              .join(', ')
          }
        }));
        return { data: updatedData, headers };
      } else {
        return { data, headers };
      }
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting ${openPreview} preview data, ${error}`,
          state: 'error'
        }
      }),
    enabled:
      !isAurionReport &&
      !isOnboardExpressReport &&
      !isUserEventsReport &&
      !cognology &&
      !isJobStatesReport
  });

  const {
    mutate: downloadReport,
    isLoading: loadingDownloadReport,
    isSuccess: downloadSuccess
  } = useMutation({
    mutationFn: async () => {
      if (isAurionReport) {
        return await RecruitApi.getAurionReport(aurionParams, 'download');
      } else if (isUserEventsReport) {
        return await RecruitApi.getUserEventsReport(userEventsParams, 'download');
      } else if (isOnboardExpressReport) {
        return await RecruitApi.getOnboardExpressReport();
      } else if (isJobStatesReport) {
        return await recruitReportApiService.jobStatusReport.downloadCsv(params, state.filters);
      } else {
        return await reportServiceApi.systemReports.data(
          params,
          openPreview,
          `${openPreview === 'Applications Report' ? groupBy : ''}`
        );
      }
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error downloading the report data, ${error}`,
          state: 'error'
        }
      }),
    onSuccess: (blob) => {
      saveAs(blob, `${openPreview}-${new Date().getTime()}.csv`);
    }
  });

  const { mutate: sendReport } = useMutation({
    mutationFn: async () => {
      const startObject = startDate.toObject();
      const endObject = endDate.toObject();
      return await RecruitApi.postCognologyReport({
        id: reportServiceApi.currentUser.id,
        form_id: approvalForm || undefined,
        include_cognology_data: cognology,
        state: approvalState,
        start: { year: startObject.years, month: startObject.months, day: startObject.date },
        end: { year: endObject.years, month: endObject.months, day: endObject.date }
      });
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting the report, ${error}`,
          state: 'error'
        }
      }),
    onSuccess: (res) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `${res.res.message} and will be sent to your email.`,
          state: 'success'
        }
      })
  });

  const { data: userEventData, isLoading: loadingUserEventData } = useQuery({
    queryKey: [reportPeriod, selectedUser, selectedEntities],
    queryFn: async () => {
      const { res } = await RecruitApi.getUserEventsReport(userEventsParams, 'preview');
      return res;
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting ${openPreview} preview data, ${error}`,
          state: 'error'
        }
      }),
    enabled: isUserEventsReport
  });

  const { data: aurionData, isLoading: loadingAurionData } = useQuery({
    queryKey: [reportPeriod, statusId],
    queryFn: async () => {
      const { res } = await RecruitApi.getAurionReport(aurionParams, 'preview');
      return res;
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting ${openPreview} preview data, ${error}`,
          state: 'error'
        }
      }),
    enabled: isAurionReport
  });

  const { data: onboardExpressData, isLoading: loadingOnboardExpressData } = useQuery({
    queryKey: [openPreview],
    queryFn: async () => {
      const { res } = await RecruitApi.getOnboardExpressReport('/preview');
      return res;
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting ${openPreview} preview data, ${error}`,
          state: 'error'
        }
      }),
    enabled: isOnboardExpressReport
  });

  const { data: jobStateData, isLoading: loadingJobStateData } = useQuery({
    queryKey: [openPreview, reportPeriod, jobStates, currentPage, rowsPerPage],
    queryFn: async () => {
      const { data, headers, message } = await recruitReportApiService.jobStatusReport.getJson(
        params,
        state.filters
      );
      return { data, headers, message };
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting ${openPreview} preview data, ${error}`,
          state: 'error'
        }
      }),
    enabled: isJobStatesReport,
    refetchInterval: refetchInterval,
    refetchIntervalInBackground: true
  });

  const getData = () => {
    if (isAurionReport) {
      return aurionData?.data.slice(1).map((datum: any) => ({
        attributes: datum
      }));
    } else if (isUserEventsReport) {
      return userEventData?.data.map((datum: any) => ({
        attributes: datum
      }));
    } else if (isOnboardExpressReport) {
      return onboardExpressData?.data.map((datum: any) => ({
        attributes: datum
      }));
    } else if (openPreview === 'Recruitment Timeline Report') {
      return previewData?.data.map((datum: any) => ({
        attributes: datum
      }));
    } else if (isJobStatesReport && jobStateData?.data?.total > 0) {
      const startIndex = (currentPage - 1) * rowsPerPage;
      const endIndex = startIndex + rowsPerPage;
      const paginatedData = jobStateData?.data?.data.slice(startIndex, endIndex) || [];

      return paginatedData.map((item: any) => ({
        type: 'reports',
        attributes: { ...item }
      }));
    } else {
      return previewData?.data;
    }
  };

  const getTotal = () => {
    if (isJobStatesReport && jobStateData?.data) {
      return jobStateData?.data.total;
    }
    return;
  };

  const getHeaders = () => {
    if (isAurionReport) {
      return aurionData?.data[0];
    } else if (isUserEventsReport) {
      return userEventData?.headers;
    } else if (isOnboardExpressReport) {
      return onboardExpressData?.headers;
    } else if (isJobStatesReport) {
      return jobStateData?.headers;
    } else {
      return previewData?.headers;
    }
  };

  const getLoading = () => {
    if (isAurionReport) {
      return loadingAurionData;
    } else if (isUserEventsReport) {
      return loadingUserEventData;
    } else if (isOnboardExpressReport) {
      return loadingOnboardExpressData;
    } else if (isJobStatesReport) {
      return loadingJobStateData;
    } else {
      return loadingPreviewData;
    }
  };

  const getMessage = () => {
    if (isJobStatesReport) {
      if ((currentPage - 1) * rowsPerPage >= 100) {
        return 'Only first 100 results are previewable, for full results please download the report.';
      } else {
        return jobStateData?.message;
      }
    }
  };

  useEffect(() => {
    if (openPreview === 'Applications Report') {
      dispatch({ type: 'SET_FILTERS', payload: { 'Group by': groupBy } });
    }
    document.querySelector('#application-content')?.scrollTo({ top: 0 });

    if (jobStateData?.data?.total > 0) {
      setRefetchInterval(false);
    } else {
      setRefetchInterval(5000);
    }
  }, [openPreview, jobStateData?.data?.total, reportPeriod]);

  const totalItems = jobStateData?.data?.total || 0;
  const totalPages = Math.ceil(totalItems / rowsPerPage);

  return (
    <Stack data-testid="system-report-preview">
      <SystemReportPreviewHeader state={state} dispatch={dispatch} />
      {cognology ? (
        <Stack sx={{ paddingTop: 4, paddingBottom: 6, ...classes.previewHeaderTitles }}>
          The report will be sent to your email
        </Stack>
      ) : (
        <SystemReportPreviewTable
          data={getData()}
          headers={getHeaders()}
          loadingPreviewData={getLoading()}
          message={getMessage()}
          total={getTotal()}
        />
      )}
      {isJobStatesReport && (
        <GenericTablePagination
          paginationState={{
            page: currentPage,
            limit: rowsPerPage
          }}
          dispatch={dispatch}
          totalPages={totalPages}
          totalItems={totalItems}
          userPreferenceSource={'reporting_job_states'}
        />
      )}
      <Stack flexDirection="row" justifyContent="space-between">
        <Button sx={classes.exitButton} variant="text" onClick={() => setIsExitDialogOpen(true)}>
          Exit
        </Button>
        {!cognology && (
          <Stack flexDirection="row" columnGap="16px">
            <Button
              id="download-reports-button"
              type="submit"
              disabled={getLoading()}
              sx={classes.downloadReportButton}
              onClick={() => {
                setDownloadModal(true);
                downloadReport();
              }}
            >
              Download reports
            </Button>
          </Stack>
        )}
      </Stack>
      <Modal open={downloadModal} onClose={() => setDownloadModal(false)}>
        <Box sx={{ ...sharedClasses.modalBody, maxWidth: '480px', minHeight: '430px' }}>
          <CloseIcon onClick={() => setDownloadModal(false)} sx={classes.closeIcon} />
          <DownloadPreview
            finishedDownload={loadingDownloadReport}
            file={downloadSuccess}
            downloadAgain={() => {
              setDownloadModal(true);
              downloadReport();
            }}
            back={() => dispatch({ type: 'RESET_STATES' })}
          />
        </Box>
      </Modal>
      <FilterModals dispatch={dispatch} state={state} sendReport={sendReport} />
      <GenericDialog
        isDialogOpen={isExitDialogOpen}
        setDialogOpen={setIsExitDialogOpen}
        title={`Are you sure you want to exit?`}
        description="Your changes will not be saved."
        buttonCallback={() => dispatch({ type: 'RESET_STATES' })}
        callbackLoading={false}
        buttonText="Exit"
        url=""
      />
    </Stack>
  );
}
