import React from 'react';
import { Link, Stack } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { IApplication } from '../types';
import dayjs from 'dayjs';
import { styles } from '../styles';

export default function AdditionalInfo() {
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);
  if (!application) return null;
  return (
    <Stack sx={styles.additionalInfoContainer}>
      <Stack sx={{ rowGap: 1 }}>
        <Stack sx={{ color: '#084D6D' }}>Lodged by</Stack>
        <Stack>{application.lodged_by || '-'}</Stack>
      </Stack>
      <Stack sx={{ rowGap: 1 }}>
        <Stack sx={{ color: '#084D6D' }}>Applied</Stack>
        <Stack>{dayjs(application.created_at).format('D MMM YYYY, LT')}</Stack>
      </Stack>
      {application.job.privacy_acknowledgement && (
        <Stack sx={{ rowGap: 1 }}>
          <Stack sx={{ color: '#084D6D' }}>Privacy statement</Stack>
          <Stack>{application.privacy_acknowledgement ? 'Agreed' : '-'}</Stack>
        </Stack>
      )}
      {application.source && (
        <Stack sx={{ rowGap: 1 }}>
          <Stack sx={{ color: '#084D6D' }}>Source</Stack>
          <Stack>{application.source || '-'}</Stack>
        </Stack>
      )}
      {application.original_job && (
        <Stack sx={{ rowGap: 1 }}>
          <Stack sx={{ color: '#084D6D' }}>Original job</Stack>
          <Link
            href={`${window.location.origin}/admin/jobs/${application.original_job.id}`}
            sx={{ color: 'black !important' }}
          >
            {application.original_job?.title || '-'}
          </Link>
        </Stack>
      )}
    </Stack>
  );
}
