import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ScoutCalendar from './ScoutCalendar';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

export default function ScoutCalendarPage({
  apiKey,
  currentUserId,
  token
}: {
  apiKey: string;
  currentUserId: number;
  token: string;
}) {
  return (
    <QueryClientProvider client={queryClient}>
      <ScoutCalendar apiKey={apiKey} currentUserId={currentUserId} token={token} />
    </QueryClientProvider>
  );
}
