import React, { useState, Dispatch, SetStateAction } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  Stack,
  Divider,
  ButtonGroup,
  IconButton,
  Link
} from '@mui/material';
import {
  Close as CloseIcon,
  Delete as DeleteIcon,
  ContactPage as ContactPageIcon
} from '@mui/icons-material';
import { classes } from './styles';
import { ScoutCalendarState, ScoutCalendarAction, IEvent } from '../types';
import { ReviewStep } from '../CreateEvent/EventProgress';
import GenericDialog from '../../NewUI/Components/Modals/GenericDialog';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import API from '../API';
import ActionModal from './ActionModal';
import ActionButtons from './ActionButtons';
import { useDeleteEvent } from '../helper';

interface Props {
  events: IEvent[];
  ScoutCalendarState: ScoutCalendarState;
  dispatch: Dispatch<ScoutCalendarAction>;
  // setSnackbar indicates that the modal is called from dashboard
  setSnackbar?: Dispatch<
    SetStateAction<{ message: string; state: 'success' | 'warning' | 'error' }>
  >;
  currentUserId: number;
}

function ViewEvent({ events, ScoutCalendarState, dispatch, setSnackbar, currentUserId }: Props) {
  const [tab, setTab] = useState<number>(1);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
  const [selectedCandidates, setSelectedCandidates] = useState<number[]>([]);
  const [confirmAction, setConfirmAction] = useState<string>('');
  const [action, setAction] = useState<string>('');
  const { apiKey, createEventObject, showViewEventModal, weekStart, permissions } =
    ScoutCalendarState;
  const { eventId, candidates, recruiters } = createEventObject;
  const queryClient = useQueryClient();
  const currentUserIsARecruiter = recruiters.find((r) => r.id === currentUserId);

  const onClose = () => {
    dispatch({ type: 'RESET_STATE' });
    dispatch({ type: 'SET_SHOW_VIEW_EVENT_MODAL', payload: false });
  };

  const { deleteEvent, deletingEvent } = useDeleteEvent({
    events,
    setSnackbar,
    ScoutCalendarState,
    dispatch,
    onClose
  });

  const { mutate: removeCandidates, isLoading: removingCandidates } = useMutation({
    mutationFn: async () => await API.removeAttendees(apiKey, eventId, selectedCandidates),
    onError: (error) => {
      const snackbarPayload: { message: string; state: 'error' } = {
        message: `There was an error clearing the session, ${error}`,
        state: 'error'
      };
      setSnackbar
        ? setSnackbar(snackbarPayload)
        : dispatch({ type: 'SET_SNACKBAR', payload: snackbarPayload });
    },
    onSuccess: () => {
      const snackbarPayload: { message: string; state: 'success' } = {
        message: `${candidates.find((c) => c.attendee_id === selectedCandidates[0])?.name}${
          selectedCandidates.length > 1 ? ` and ${selectedCandidates.length - 1} others` : ''
        } has been successfully removed and notified`,
        state: 'success'
      };
      setSnackbar
        ? setSnackbar(snackbarPayload)
        : dispatch({ type: 'SET_SNACKBAR', payload: snackbarPayload });
      const updatedCandidates = candidates.filter(
        (c) => !selectedCandidates.includes(c.attendee_id)
      );
      dispatch({
        type: 'SET_CREATE_EVENT_OBJECT',
        payload: { ...createEventObject, candidates: updatedCandidates }
      });
      setOpenConfirmationDialog(false);
      setSelectedCandidates([]);
      setSnackbar
        ? queryClient.invalidateQueries(['getEvents'], { exact: true })
        : queryClient.invalidateQueries(['events', ScoutCalendarState.weekStart], { exact: true });
      //refreshes the events list on the application page events table on the tasks and events tab
      queryClient.invalidateQueries(['application events'], { exact: true });
    }
  });

  const { mutate: updateInvitation, isLoading: updatingInvitation } = useMutation({
    mutationFn: async () =>
      currentUserIsARecruiter?.attendeeId &&
      (await API.updateAttendeeInvitation(
        apiKey,
        eventId,
        currentUserIsARecruiter.attendeeId,
        confirmAction === 'Accept' ? 'accepted' : 'declined'
      )),
    onError: (error: { errors: string }) => {
      const snackbarPayload: { message: string; state: 'error' } = {
        message: `There was an error ${
          confirmAction === 'Accept' ? 'accepting' : 'declining'
        } the event, ${error.errors}`,
        state: 'error'
      };
      setSnackbar
        ? setSnackbar(snackbarPayload)
        : dispatch({ type: 'SET_SNACKBAR', payload: snackbarPayload });
    },
    onSuccess: (res) => {
      // updating the recruiters accepted/declined state inside the modal
      const updatedRecruiters = [...recruiters];
      const attendeeIndex = updatedRecruiters.findIndex(
        (r) => r.attendeeId === currentUserIsARecruiter?.attendeeId
      );
      updatedRecruiters[attendeeIndex].state = confirmAction === 'Accept' ? 'accepted' : 'declined';
      dispatch({
        type: 'SET_CREATE_EVENT_OBJECT',
        payload: { ...createEventObject, recruiters: updatedRecruiters }
      });

      // updating the events list with the new data from accepting/declining the invite
      const updatedEvents = [...events];
      const index = updatedEvents.findIndex((e) => e.id === eventId);
      updatedEvents[index] = { ...res, start: new Date(res.begins_at), end: new Date(res.ends_at) };
      setSnackbar
        ? queryClient.setQueryData(['getEvents'], { pages: [{ data: { events: updatedEvents } }] })
        : queryClient.setQueryData(['events', weekStart], updatedEvents);

      // show success snackbar from dashboard or events page
      const snackbarPayload: { message: string; state: 'success' } = {
        message: `Event successfully ${confirmAction === 'Accept' ? 'accepted' : 'declined'}`,
        state: 'success'
      };
      setSnackbar
        ? setSnackbar(snackbarPayload)
        : dispatch({ type: 'SET_SNACKBAR', payload: snackbarPayload });
    },
    onSettled: () => setOpenConfirmationDialog(false)
  });

  const dialogTitle = () => {
    switch (confirmAction) {
      case 'Delete':
        return `Delete this event?`;
      case 'Remove':
        return `Remove ${selectedCandidates.length < 2 ? 'this' : 'these'} candidate${
          selectedCandidates.length < 2 ? '' : 's'
        }`;
      default:
        return `${confirmAction} this invitation?`;
    }
  };

  const deleteAndRemoveDialogDescription = (
    <Stack sx={{ rowGap: 3 }}>
      <Stack sx={{ fontSize: '18px', fontWeight: '600' }}>
        {`The following applicant${
          (confirmAction === 'Delete' && candidates.length > 1) ||
          (confirmAction === 'Remove' && selectedCandidates.length > 1)
            ? 's'
            : ''
        } will be notified ${
          confirmAction === 'Delete'
            ? 'of the event cancellations.'
            : 'regarding their removal from the event.'
        }`}
      </Stack>
      <Stack sx={{ fontSize: '18px', color: '#333333', fontWeight: '400' }}>
        {confirmAction === 'Delete'
          ? candidates.map((c) => c.name).join(', ')
          : selectedCandidates
              .map((s) => candidates.find((c) => c.attendee_id === s))
              .map((f) => f?.name)
              .join(', ')}
      </Stack>
    </Stack>
  );

  const dialogDescription = () => {
    if (
      confirmAction === 'Delete' &&
      (createEventObject.eventDate < new Date() || !createEventObject.candidates.length) // Message for deleting past event or event that does not have any candidates booked in
    )
      return 'Are you sure you want to delete this event?';
    if (confirmAction === 'Delete' || confirmAction === 'Remove')
      return deleteAndRemoveDialogDescription;
    return (
      <Stack sx={{ rowGap: 0.5 }}>
        <Stack>
          By {confirmAction === 'Accept' ? 'accepting' : 'declining'} this invitation, the event
        </Stack>
        <Stack>creator will be notified regarding your status</Stack>
      </Stack>
    );
  };

  const dialogCallback = () => {
    switch (confirmAction) {
      case 'Delete':
        return deleteEvent();
      case 'Remove':
        return removeCandidates();
      default:
        return updateInvitation();
    }
  };
  const dialogLoading = () => {
    switch (confirmAction) {
      case 'Delete':
        return deletingEvent;
      case 'Remove':
        return removingCandidates;
      default:
        return updatingInvitation;
    }
  };

  const openDialog = (action: string) => {
    setConfirmAction(action);
    setOpenConfirmationDialog(true);
  };

  return (
    <Dialog maxWidth={false} onClose={onClose} open={showViewEventModal} sx={classes.dialog}>
      <Stack
        sx={{
          ...classes.modalContainer,
          width: 'auto',
          height:
            permissions?.['Events']?.['Create / Edit Events'] &&
            currentUserIsARecruiter &&
            tab === 1
              ? '615px'
              : '698px'
        }}
      >
        <Box sx={{ ...classes.modalTitle, ...classes.longEventTitle }}>
          {createEventObject.eventName}
        </Box>
        <Stack sx={classes.modalDeleteAndCloseContainer}>
          <DeleteIcon
            id="delete-individual-event-button"
            sx={{ cursor: 'pointer', color: '#E37D7A' }}
            onClick={() => openDialog('Delete')}
          />
          <Divider orientation="vertical" flexItem />
          <CloseIcon
            id="close-event-modal-button"
            onClick={onClose}
            sx={{ cursor: 'pointer', color: '#DDDDDD' }}
          />
        </Stack>
        <Stack sx={classes.tabsContainer}>
          <Stack
            id="event-details-tab"
            sx={{ ...classes.tab, ...(tab === 1 ? classes.selectedTab : []) }}
            onClick={() => setTab(1)}
          >
            Details
          </Stack>
          <Stack
            id="event-candidates-tab"
            sx={{ ...classes.tab, ...(tab === 2 ? classes.selectedTab : []) }}
            onClick={() => setTab(2)}
          >
            Candidates
          </Stack>
        </Stack>
        {tab === 1 ? (
          <>
            <ReviewStep ScoutCalendarState={ScoutCalendarState} dispatch={dispatch} />
          </>
        ) : (
          <Stack>
            <ActionButtons
              selectedCandidates={selectedCandidates}
              permissions={permissions}
              setAction={setAction}
              openDialog={openDialog}
            />
            <Stack
              sx={{ rowGap: 3, flexGrow: 1, paddingTop: selectedCandidates.length > 0 ? 3 : 0 }}
            >
              <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Stack sx={{ flexDirection: 'row', columnGap: 2 }}>
                  <Checkbox
                    id="select-all-candidates-check-box"
                    sx={{
                      ...classes.checkbox,
                      '&.MuiCheckbox-indeterminate': { color: 'rgb(170, 170, 170)' }
                    }}
                    checked={
                      candidates.length > 0 &&
                      candidates.every((c) => selectedCandidates.includes(c.attendee_id))
                    }
                    indeterminate={
                      candidates.some((c) => selectedCandidates.includes(c.attendee_id)) &&
                      selectedCandidates.length !== candidates.length
                    }
                    onClick={() => {
                      if (candidates.every((c) => selectedCandidates.includes(c.attendee_id))) {
                        setSelectedCandidates([]);
                      } else {
                        setSelectedCandidates(candidates.map((c) => c.attendee_id));
                      }
                    }}
                  />
                  <Stack sx={{ color: '#666666', fontWeight: 'bold' }}>
                    {createEventObject.eventDate.toLocaleDateString('en-GB', {
                      weekday: 'long',
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric'
                    })}
                  </Stack>
                </Stack>
                <Stack>
                  {candidates.length}/{createEventObject.maxCandidates} candidates booked
                </Stack>
              </Stack>
              <Stack
                sx={{
                  ...classes.candidatesListContainer,
                  height: selectedCandidates.length ? '400px' : '425px'
                }}
              >
                {candidates.length ? (
                  <>
                    {candidates.map((c) => (
                      <Stack key={c.attendee_id} sx={{ flexDirection: 'row', columnGap: 2 }}>
                        <Checkbox
                          id="select-specific-candidate-check-box"
                          disableRipple
                          sx={classes.checkbox}
                          checked={selectedCandidates.includes(c.attendee_id)}
                          onClick={() => {
                            if (selectedCandidates.includes(c.attendee_id)) {
                              setSelectedCandidates(
                                selectedCandidates.filter((f) => f != c.attendee_id)
                              );
                            } else {
                              setSelectedCandidates([...selectedCandidates, c.attendee_id]);
                            }
                          }}
                        />
                        <Stack
                          sx={{
                            ...classes.candidateList,
                            ...(selectedCandidates.includes(c.attendee_id) && {
                              borderColor: '#5BC4BF'
                            })
                          }}
                        >
                          <Stack flexDirection="row">
                            <Link
                              id="individual-applications-link"
                              href={c.application_url || '#'}
                              sx={classes.link}
                              target="_blank"
                              underline="hover"
                            >
                              {c.name}
                            </Link>
                          </Stack>
                          <Stack flexDirection="row">
                            <Stack
                              id="individual-job-link"
                              onClick={() =>
                                c.job_id &&
                                window.open(
                                  `${window.location.origin}/admin/jobs/${c.job_id}`,
                                  '_blank'
                                )
                              }
                              sx={{
                                ...classes.listViewInfoTag,
                                padding: '0px 10px',
                                cursor: 'pointer'
                              }}
                            >
                              <Stack sx={{ fontWeight: 'bold' }}>Job:</Stack>
                              <Stack>
                                {c.job_reference} - {c.job_title}
                              </Stack>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Stack>
                    ))}
                  </>
                ) : (
                  <Stack sx={classes.noCandidatesContainer}>
                    <ContactPageIcon sx={{ fontSize: '150px' }} />
                    <Stack sx={{ fontWeight: 'bold', fontSize: '20px' }}>
                      No candidates are booked
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>
      {permissions?.['Events']?.['Create / Edit Events'] &&
        currentUserIsARecruiter &&
        tab === 1 && (
          <Stack sx={classes.acceptingInviteContainer}>
            <Stack sx={{ fontSize: '18px', fontWeight: 'bold', color: '#666666' }}>
              Are you attending?
            </Stack>
            <ButtonGroup sx={classes.acceptingInviteButtonGroup} variant="text">
              <Button
                id="accept-event-option"
                onClick={() => currentUserIsARecruiter.state !== 'accepted' && openDialog('Accept')}
                sx={{
                  background: currentUserIsARecruiter.state === 'accepted' ? '#DEF3F2' : 'inherit'
                }}
              >
                Accept
              </Button>
              <Button
                id="decline-event-option"
                onClick={() =>
                  currentUserIsARecruiter.state !== 'declined' && openDialog('Decline')
                }
                sx={{
                  background: currentUserIsARecruiter.state === 'declined' ? '#DEF3F2' : 'inherit'
                }}
              >
                Decline
              </Button>
            </ButtonGroup>
          </Stack>
        )}
      {tab === 2 && selectedCandidates.length > 0 && (
        <Stack sx={classes.selectedCandidatesIndicatorContainer}>
          <Stack>
            {selectedCandidates.length === candidates.length && 'All'} {selectedCandidates.length}{' '}
            candidate{selectedCandidates.length > 1 ? 's are' : ' is'} selected
          </Stack>
          <IconButton
            id="clear-selected-candidates-button"
            sx={classes.clearSelectionButton}
            onClick={() => setSelectedCandidates([])}
          >
            Clear selection
          </IconButton>
        </Stack>
      )}
      <GenericDialog
        isDialogOpen={openConfirmationDialog}
        setDialogOpen={setOpenConfirmationDialog}
        title={dialogTitle()}
        description={dialogDescription()}
        buttonCallback={dialogCallback}
        callbackLoading={dialogLoading()}
        buttonText={confirmAction}
        url=""
        primaryButtonId={
          confirmAction === 'Delete' || confirmAction === 'Remove'
            ? `confirm-${confirmAction === 'Delete' ? 'delete' : 'remove-candidate-from'}-event-button`
            : undefined
        }
        secondaryButtonId={
          confirmAction === 'Delete' || confirmAction === 'Remove'
            ? `cancel-${confirmAction === 'Delete' ? 'delete' : 'remove-candidate-from'}-event-button`
            : undefined
        }
        alternateColor={confirmAction === 'Accept' ? true : false}
      />
      {action && (
        <ActionModal
          ScoutCalendarState={ScoutCalendarState}
          dispatch={dispatch}
          action={action}
          handleClose={() => setAction('')}
          selectedCandidates={candidates.filter((c) => selectedCandidates.includes(c.attendee_id))}
          setSelectedCandidates={setSelectedCandidates}
        />
      )}
    </Dialog>
  );
}

export default ViewEvent;
