import React, { forwardRef } from 'react';
import { Box } from '@mui/material';
import IosShareIcon from '@mui/icons-material/IosShare';
import { classes } from '../styles';

const ExportButton = forwardRef<HTMLElement, { onClick: () => void }>(
  ({ onClick, ...props }, ref) => {
    return (
      <Box sx={classes.floatingActionButton} onClick={() => onClick()} ref={ref} {...props}>
        <IosShareIcon />
      </Box>
    );
  }
);
ExportButton.displayName = 'ExportButton';
export default ExportButton;
