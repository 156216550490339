import React, { Dispatch, useState } from 'react';
import { Avatar, Badge, Box, Popper, IconButton, Stack } from '@mui/material';
import { Edit as EditIcon, Add as AddIcon } from '@mui/icons-material';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { SystemReportsState, SystemReportsAction } from './reducer';
import { classes } from './styles';
import DateStartToEndRangeField from '../../NewUI/Components/CustomUIElements/DateRangePickerWithDynamicTitle/DateStartToEndRangeField';
import moment from 'moment';
import { dateRangeOptions } from '../DataVizSection/GraphDefaultOptions';

export default function SystemReportPreviewHeader({
  state,
  dispatch
}: {
  state: SystemReportsState;
  dispatch: Dispatch<SystemReportsAction>;
}) {
  const {
    openPreview,
    reportPeriod,
    editTimePeriod,
    filters,
    questions,
    selectedEntities,
    jobs,
    viewFilters
  } = state;
  const [openCustomPeriod, setOpenCustomPeriod] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [period, setPeriod] = useState<string | number>(30);
  const isOnboardExpressReport = openPreview === 'Onboard Express Report';
  const filtersApplied = Object.keys(filters).length;
  const questionsReportFilters =
    openPreview === 'Questions Report' &&
    (questions.length || selectedEntities.length || jobs.length);

  const handleTimePeriodChange = (value: number | string) => {
    if (value === 'custom') {
      setOpenCustomPeriod(true);
    } else {
      let startDate, endDate;
      switch (value) {
        case 7: // Last Week
          startDate = moment().subtract(1, 'weeks').startOf('isoWeek').toDate();
          endDate = moment().subtract(1, 'weeks').endOf('isoWeek').toDate();
          break;
        case 30: // Last Month
          startDate = moment().subtract(1, 'months').startOf('month').toDate();
          endDate = moment().subtract(1, 'months').endOf('month').toDate();
          break;
        case 90: // Last Quarter
          startDate = moment().subtract(1, 'quarters').startOf('quarter').toDate();
          endDate = moment().subtract(1, 'quarters').endOf('quarter').toDate();
          break;
        case 180: // Last Half Year
          if (moment().month() >= 6) {
            // If current month is July or later, target Jan 1 to Jun 30 of the current year
            startDate = moment().startOf('year').toDate(); // Jan 1 of the current year
            endDate = moment().startOf('year').add(5, 'months').endOf('month').toDate(); // Jun 30 of the current year
          } else {
            // If current month is before July, target Jul 1 to Dec 31 of the previous year
            startDate = moment().subtract(1, 'year').startOf('year').add(6, 'months').toDate(); // Jul 1 of the previous year
            endDate = moment().subtract(1, 'year').endOf('year').toDate(); // Dec 31 of the previous year
          }
          break;
        case 365: // Last Year
          startDate = moment().subtract(1, 'years').startOf('year').toDate();
          endDate = moment().subtract(1, 'years').endOf('year').toDate();
          break;
        case 1095: // Last 3 Years
          startDate = moment().subtract(3, 'years').startOf('year').toDate();
          endDate = moment().subtract(1, 'years').endOf('year').toDate();
          break;
        case 1825: // Last 5 Years
          startDate = moment().subtract(5, 'years').startOf('year').toDate();
          endDate = moment().subtract(1, 'years').endOf('year').toDate();
          break;
        default:
          // Handle other cases or set a default
          startDate = moment().toDate();
          endDate = moment().toDate();
      }

      dispatch({ type: 'SET_PAGE', payload: 1 });
      dispatch({
        type: 'REPORT_PERIOD',
        payload: { startDate, endDate }
      });
      setAnchorEl(null);
    }
    setPeriod(value);
    dispatch({ type: 'EDIT_TIME_PERIOD', payload: !editTimePeriod });
  };

  return (
    <Stack flexDirection="row">
      <Stack sx={classes.previewHeaderSection}>
        <Box sx={classes.previewHeaderTitles}>Report</Box>
        <Stack sx={classes.previewReportTitle}>
          <Box>{openPreview}</Box>
          <Avatar sx={classes.avatarStyle} variant="square">
            S
          </Avatar>
        </Stack>
      </Stack>
      {!isOnboardExpressReport && (
        <Stack sx={classes.previewHeaderSection}>
          <Box sx={classes.previewHeaderTitles}>Report period</Box>
          <Stack flexDirection="row" sx={{ paddingTop: 2, columnGap: 1 }}>
            <Box sx={classes.previewReportPeriod}>
              {moment(reportPeriod.startDate).format('DD MMM YYYY')} -{' '}
              {moment(reportPeriod.endDate).format('DD MMM YYYY')}
            </Box>
            <IconButton
              sx={classes.previewEditButton}
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                setAnchorEl(event.currentTarget);
                dispatch({ type: 'EDIT_TIME_PERIOD', payload: !editTimePeriod });
              }}
            >
              <EditIcon sx={classes.editIcon} />
            </IconButton>
            <Popper
              id="report-preview-time-period-popper"
              sx={{ background: 'inherit' }}
              open={editTimePeriod}
              anchorEl={anchorEl}
              placement="bottom-end"
            >
              <ClickAwayListener
                onClickAway={() => {
                  dispatch({ type: 'EDIT_TIME_PERIOD', payload: !editTimePeriod });
                }}
              >
                <Stack sx={classes.reportPreviewPopperContainer}>
                  {dateRangeOptions.map((option, index) => (
                    <Stack
                      key={index}
                      id={`report-time-period-option-${option.label}`}
                      sx={{
                        ...classes.reportPeriodOption,
                        color: `${index === 7 ? '#084D6D' : '#666666'}`,
                        backgroundColor: `${
                          period === option.value ? 'rgba(25,118,210,0.12)' : 'unset'
                        }`
                      }}
                      onClick={() => handleTimePeriodChange(option.value)}
                    >
                      {option.label}
                    </Stack>
                  ))}
                </Stack>
              </ClickAwayListener>
            </Popper>
            {openCustomPeriod && anchorEl && (
              <DateStartToEndRangeField
                isLoading={false}
                reportState={{ ...reportPeriod, dateRange: { value: '', label: '' } }}
                reportDispatch={dispatch}
                fieldName="REPORT_PERIOD"
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                handleClose={() => setOpenCustomPeriod(false)}
              />
            )}
          </Stack>
        </Stack>
      )}
      {!isOnboardExpressReport && (
        <Stack sx={classes.previewHeaderSection}>
          <Box sx={classes.previewHeaderTitles}>Filters</Box>
          <Stack flexDirection="row" sx={{ paddingTop: 2, columnGap: 1 }}>
            <Badge badgeContent={filtersApplied} sx={classes.filtersBadge}>
              <Box
                sx={{
                  ...classes.previewReportPeriod,
                  cursor: `${filtersApplied || questionsReportFilters ? 'pointer' : ''}`
                }}
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  if (filtersApplied) {
                    setAnchorEl(event.currentTarget);
                    dispatch({ type: 'VIEW_FILTERS', payload: !viewFilters });
                  }
                  if (questionsReportFilters) {
                    dispatch({
                      type: 'FILTERS_MODAL',
                      payload: { ...state.filtersModal, [openPreview]: true }
                    });
                  }
                }}
              >
                {openPreview === 'Tags Report' || openPreview === 'Recruitment Timeline Report' ? (
                  <Stack>Not applicable</Stack>
                ) : (
                  <Stack>
                    {filtersApplied || questionsReportFilters ? 'View filters' : 'None applied'}
                  </Stack>
                )}
              </Box>
            </Badge>
            <Popper
              id="report-preview-time-period-popper"
              sx={{ background: 'inherit' }}
              open={viewFilters}
              anchorEl={anchorEl}
              placement="bottom-end"
            >
              <ClickAwayListener
                onClickAway={() => {
                  dispatch({ type: 'VIEW_FILTERS', payload: !viewFilters });
                }}
              >
                <Stack sx={{ ...classes.reportPreviewPopperContainer, padding: '16px 0px' }}>
                  {Object.keys(filters).map((key, index) => (
                    <Stack key={index} sx={classes.reportFiltersList}>
                      <b>{key}:</b> {filters[key]}
                    </Stack>
                  ))}
                </Stack>
              </ClickAwayListener>
            </Popper>
            {!(openPreview === 'Tags Report' || openPreview === 'Recruitment Timeline Report') && (
              <IconButton
                sx={classes.previewEditButton}
                onClick={() => {
                  dispatch({
                    type: 'FILTERS_MODAL',
                    payload: { ...state.filtersModal, [openPreview]: true }
                  });
                }}
              >
                {filtersApplied ? (
                  <EditIcon sx={classes.editIcon} />
                ) : (
                  <AddIcon sx={{ ...classes.editIcon, strokeWidth: 2 }} />
                )}
              </IconButton>
            )}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}
