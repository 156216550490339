/* eslint-disable max-lines */
import { Box, Button, FormControlLabel, Skeleton, Stack, Switch, Tooltip } from '@mui/material';
import { Info as InfoIcon } from '@mui/icons-material';
import ApplicationsTable from './ApplicationsTable';
import ApplicationsActionsPanel from './ApplicationsActionsPanel';
import GenericDialog from '../../Components/Multiposter/GenericDialog';
import FieldDropdown from './Dropdowns/FieldDropdown';
import FilterDropdown from './Dropdowns/FilterDropdown';
import TempCSVExport from './Modals/TempCSVExport';
import ExternalEmail from './Modals/ExternalEmail';
import SMSCandidates from './Modals/SMSCandidates';
import EmailCandidates from './Modals/EmailCandidates';
import MoveApplications from './Modals/MoveApplications';
import AddToEvent from './Modals/AddToEvent';
import InviteToTimeslot from './Modals/InviteToTimeslot';
import ChangeStatus from './Modals/ChangeStatus';
import ExportToOnboard from './Modals/ExportToOnboard';
import ExportPDF from './Modals/ExportPDF';
import ExportFoundu from './Modals/ExportFoundu';
import Search from './Search';
import FilePreviewModal, { IJobAttachment } from '../../Components/Modals/FilePreviewModal';
import NewApplication from './Modals/NewApplication';
import React, { useCallback, useEffect, useState } from 'react';
import InsightsModal from '../../../AIStudio/ApplicationSummaries/InsightsModal/InsightsModal';
import StyledSnackbar from '../../Components/CustomUIElements/StyledSnackbar';
import Api from '../API';
import {
  IApplicationProps,
  IApplicationStatus,
  IDropdownProps,
  IFilterDropdownProps,
  IGetAllApplications,
  IGetApplications,
  IJobApplication,
  IUIPreferences,
  SortableColumnId,
  TSetUserPreferences
} from '../types';
import { defaultFilters, initialModalsOpen, sortableColumnsDefaults } from './config';
import { classes } from './styles';
import { getUpdatedSortableColumns } from '../../utils/sortable-column';

export default function Applications({
  apiKey,
  jobAttachments,
  jobHasPrivacyAcknowledgement,
  jobId,
  jobIsLoading,
  jobOptions,
  jobQuestions,
  jobSources,
  jobTitle,
  setJob,
  userPermissions,
  newNoteOnApplicationEnabled,
  newApplicationEnabled
}: IApplicationProps) {
  const SORTABLE_COLUMN_IDS_TO_REMOVE = newNoteOnApplicationEnabled
    ? [SortableColumnId.NOTES]
    : [SortableColumnId.NOTE_PAD, SortableColumnId.PERMANENT_RECORD];
  const [applications, setApplications] = useState<IJobApplication[]>([]);
  const [applicationStatuses, setApplicationStatuses] = useState<IApplicationStatus[]>([]);
  const [filterableStatuses, setFilterableStatuses] = useState<IApplicationStatus[]>([]);
  const [filterableCountries, setFilterableCountries] = useState<IApplicationCountry[]>([]);
  const [filterableSuburbs, setFilterableSuburbs] = useState<IApplicationCountry[]>([]);
  const [globalSelected, setGlobalSelected] = useState<IJobApplication[]>([]);
  const [selectAllIsChecked, setSelectAllIsChecked] = useState(false);
  const [selectAllIsIndeterminate, setSelectAllIsIndeterminate] = useState(false);
  const [selectAllApplications, setSelectAllApplications] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(0);
  const [snackbar, setSnackbar] = useState<{
    message: string;
    state: 'success' | 'warning' | 'error';
  }>({
    message: '',
    state: 'success'
  });

  const [selected, setSelected] = useState<number[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalApplications, setTotalApplications] = useState(0);
  const [sortBy, setSortBy] = useState<string>('date');
  const [sortOrder, setSortOrder] = useState<string>('desc');
  const [search, setSearch] = useState<string>('');
  const [filters, setFilters] = useState<IFilterDropdownProps['filters']>(defaultFilters);
  const [modalsOpen, setModalsOpen] = useState<Record<string, boolean>>(initialModalsOpen);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState<IJobAttachment | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [sortableColumns, setSortableColumns] = useState(
    sortableColumnsDefaults.filter((column) => !SORTABLE_COLUMN_IDS_TO_REMOVE.includes(column.id))
  );
  const [density, setDensity] = useState<string>('Default');
  const [newApplicationPage, setNewApplicationPage] = useState<boolean>(false);

  const handleSortTranslate = (sort: string) => {
    switch (sort) {
      case 'candidate':
        return 'firstname';
      case 'date':
        return 'created_at';
      default:
        return sort;
    }
  };

  const saveSortableColumns = async ({
    sortableColumns,
    rowCount
  }: {
    sortableColumns?: IDropdownProps['sortableColumns'];
    rowCount?: number;
  }) => {
    try {
      await fetch(`/api/v4/ui_preferences`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-authenticate': apiKey
        },
        body: JSON.stringify({
          ui_preference: {
            ...(sortableColumns && { sortable_columns: sortableColumns }),
            ...(rowCount && { row_count: rowCount }),
            source: `applications`
          }
        })
      });
    } catch (error) {
      setSnackbar({
        message: 'There was an error saving your preferences',
        state: 'error'
      });
    }
  };

  const setUserPreferences: TSetUserPreferences = useCallback(
    async (limit, sort, columns, newFilters, density) => {
      if (density) {
        try {
          await fetch(`/api/v4/ui_preferences`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-api-authenticate': apiKey
            },
            body: JSON.stringify({
              ui_preference: {
                columns: { density: density },
                source: `universal`
              }
            })
          });
        } catch (error) {
          setSnackbar({
            message: `There was an error saving your density preferences, ${error}`,
            state: 'error'
          });
        }
      } else if (limit || columns) {
        saveSortableColumns({
          rowCount: limit || rowsPerPage,
          sortableColumns: columns || sortableColumns
        });
      } else {
        try {
          await fetch(`/api/v4/ui_preferences`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-api-authenticate': apiKey
            },
            body: JSON.stringify({
              ui_preference: {
                sorting: {
                  field: sort?.sortBy || sortBy,
                  direction: (sort?.sortOrder || sortOrder).toUpperCase()
                },
                filters: newFilters || filters,
                source: `job:${jobId}:applications`
              }
            })
          });
        } catch (error) {
          setSnackbar({
            message: 'There was an error saving your preferences',
            state: 'error'
          });
        }
      }
    },
    [apiKey, filters, jobId, rowsPerPage, sortBy, sortOrder, sortableColumns]
  );

  const getFirstApplicationsAndPreferences = useCallback(async () => {
    setIsLoading((prev) => prev + 1);
    try {
      const sortAndFilterData = await fetch(
        `/api/v4/ui_preferences?source=job:${jobId}:applications`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-api-authenticate': apiKey
          },
          cache: 'no-store'
        }
      ).then((res) => res.json());
      const columnsAndLimitData = await fetch(`/api/v4/ui_preferences?source=applications`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-api-authenticate': apiKey
        },
        cache: 'no-store'
      }).then((res) => res.json());
      const densityData = await fetch(`/api/v4/ui_preferences?source=universal`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-api-authenticate': apiKey
        },
        cache: 'no-store'
      }).then((res) => res.json());
      const sortAndFilterpreferences = sortAndFilterData?.ui_preferences[0];
      const columnsAndLimitPreferences: IUIPreferences = columnsAndLimitData?.ui_preferences[0];
      const densityPreference = densityData?.ui_preferences[0];
      sortAndFilterpreferences?.sorting?.field && setSortBy(sortAndFilterpreferences.sorting.field);
      sortAndFilterpreferences?.sorting?.direction &&
        setSortOrder(sortAndFilterpreferences.sorting.direction.toLowerCase());
      sortAndFilterpreferences?.filters && setFilters(sortAndFilterpreferences.filters);
      const selectedStatuses = sortAndFilterpreferences?.filters?.status?.map(
        (status) => status.id
      );
      const selectedCountries = sortAndFilterpreferences?.filters?.country?.map(
        (country) => country.id
      );
      const selectedSuburbs = sortAndFilterpreferences?.filters?.suburb?.map(
        (suburb) => suburb.name
      );
      const sessionPage = sessionStorage.getItem(`${jobId}-applicationTablePage`);
      sessionPage && setCurrentPage(Number(sessionPage));
      columnsAndLimitPreferences?.row_count && setRowsPerPage(columnsAndLimitPreferences.row_count);

      if (columnsAndLimitPreferences?.sortable_columns) {
        const { updatedColumns } = getUpdatedSortableColumns(
          sortableColumns,
          columnsAndLimitPreferences
        );
        setSortableColumns(updatedColumns);
        saveSortableColumns({ sortableColumns: updatedColumns });
      }

      densityPreference?.columns?.density && setDensity(densityPreference.columns?.density);
      const response = await Api.getApplications({ 'X-api-authenticate': apiKey }, jobId, {
        exclude_job_attribute: true,
        page: sessionPage ? Number(sessionPage) : 1,
        limit: columnsAndLimitPreferences?.row_count || rowsPerPage,
        sort_by: handleSortTranslate(
          sortAndFilterpreferences?.sorting?.field || handleSortTranslate('date')
        ),
        sort_direction: (
          sortAndFilterpreferences?.sorting?.direction?.toLowerCase() || 'desc'
        ).toUpperCase(),
        'q[search]': search,
        'filter[status_ids]': selectedStatuses?.filters?.length === 0 ? 0 : selectedStatuses,
        'filter[country_ids]': selectedCountries?.length === 0 ? 0 : selectedCountries,
        'filter[suburb]': selectedSuburbs?.length === 0 ? 0 : selectedSuburbs,
        'filter[rating_from]': sortAndFilterpreferences?.filters?.rating[0] || 0,
        'filter[rating_to]': sortAndFilterpreferences?.filters?.rating[1] || 100,
        'filter[exclude_unrated]':
          sortAndFilterpreferences?.filters?.excludeApplicantsWithoutRating || false
      });
      setApplications(response.data.applications);
      setTotalPages(response.totalPages);
      setTotalApplications(response.totalItems);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading((prev) => prev - 1);
    }
  }, [apiKey, jobId, search]);

  const getApplications: IGetApplications = async (
    page,
    limit,
    searchQuery,
    sort,
    updatedFilters
  ) => {
    setIsLoading((prev) => prev + 1);
    try {
      const selectedStatuses = (updatedFilters?.status || filters.status)?.map(
        (status) => status.id
      );
      const selectedCountries = (updatedFilters?.country || filters.country)?.map(
        (country) => country.id
      );
      const selectedSuburbs = (updatedFilters?.suburb || filters.suburb)?.map(
        (suburb) => suburb.name
      );
      const response = await Api.getApplications({ 'X-api-authenticate': apiKey }, jobId, {
        exclude_job_attribute: true,
        page: page || currentPage,
        limit: limit || rowsPerPage,
        sort_by: handleSortTranslate(sort?.sortBy || sortBy),
        sort_direction: (sort?.sortOrder || sortOrder).toUpperCase(),
        'q[search]': searchQuery !== null ? searchQuery : search,
        'filter[status_ids]': selectedStatuses?.length === 0 ? 0 : selectedStatuses,
        'filter[country_ids]': selectedCountries?.length === 0 ? 0 : selectedCountries,
        'filter[suburb]': selectedSuburbs?.length === 0 ? 0 : selectedSuburbs,
        'filter[rating_from]': updatedFilters?.rating[0] || filters.rating[0],
        'filter[rating_to]': updatedFilters?.rating[1] || filters.rating[1],
        'filter[exclude_unrated]': updatedFilters
          ? updatedFilters.excludeApplicantsWithoutRating
          : filters.excludeApplicantsWithoutRating
      });
      setApplications(response.data.applications);
      setTotalPages(response.totalPages);
      setTotalApplications(response.totalItems);
      setSelectAllIsChecked(
        response.data.applications
          .map((app: IJobApplication) => app.id)
          .every((id: number) => globalSelected.map((app) => app.id).includes(id))
      );
      setSelectAllIsIndeterminate(
        response.data.applications
          .map((app: IJobApplication) => app.id)
          .some((id: number) => globalSelected.map((app) => app.id).includes(id))
      );
    } catch (error) {
      console.log(error);
    } finally {
      if (sort || updatedFilters) {
        setUserPreferences(null, sort, null, updatedFilters, null);
      } else if (limit) {
        setUserPreferences(limit, null, null, null, null);
      }
      setIsLoading((prev) => prev - 1);
    }
  };

  const getAllApplications: IGetAllApplications = async (
    limit,
    searchQuery,
    sort,
    updatedFilters
  ) => {
    setIsLoading((prev) => prev + 1);
    try {
      const selectedStatuses = (updatedFilters?.status || filters.status)?.map(
        (status) => status.id
      );
      const selectedCountries = (updatedFilters?.country || filters.country)?.map(
        (country) => country.id
      );
      const selectedSuburbs = (updatedFilters?.suburb || filters.suburb)?.map(
        (suburb) => suburb.name
      );
      const response = await Api.getAllApplications({ 'X-api-authenticate': apiKey }, jobId, {
        exclude_job_attribute: true,
        override_limit: true,
        limit: totalApplications,
        sort_by: handleSortTranslate(sort?.sortBy || sortBy),
        sort_order: (sort?.sortOrder || sortOrder).toUpperCase(),
        'q[search]': searchQuery !== null ? searchQuery : search,
        'filter[status_ids]': selectedStatuses?.length === 0 ? 0 : selectedStatuses,
        'filter[country_ids]': selectedCountries?.length === 0 ? 0 : selectedCountries,
        'filter[suburb]': selectedSuburbs?.length === 0 ? 0 : selectedSuburbs,
        'filter[rating_from]': updatedFilters?.rating[0] || filters.rating[0],
        'filter[rating_to]': updatedFilters?.rating[1] || filters.rating[1],
        'filter[exclude_unrated]': updatedFilters
          ? updatedFilters.excludeApplicantsWithoutRating
          : filters.excludeApplicantsWithoutRating
      });
      return response.data.applications;
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading((prev) => prev - 1);
    }
  };

  const getFilterableStatuses = useCallback(async () => {
    try {
      const response = await Api.getFilterableStatuses({ 'X-api-authenticate': apiKey }, jobId, {
        limit: 100
      });
      setFilterableStatuses(response.data.filterable_statuses);
    } catch (error) {
      console.log(error);
    }
  }, [apiKey, jobId]);

  const getFilterableCountries = useCallback(async () => {
    try {
      const response = await Api.getFilterableCountries(
        { 'X-api-authenticate': apiKey },
        jobId,
        {}
      );
      setFilterableCountries(response.data.countries);
    } catch (error) {
      console.log(error);
    }
  }, [apiKey, jobId]);

  const getFilterableSuburbs = useCallback(async () => {
    try {
      const response = await Api.getFilterableSuburbs({ 'X-api-authenticate': apiKey }, jobId, {});
      setFilterableSuburbs(response.data.suburbs);
    } catch (error) {
      console.log(error);
    }
  }, [apiKey, jobId]);

  const getApplicationStatuses = useCallback(async () => {
    setIsLoading((prev) => prev + 1);
    try {
      const response = await Api.getApplicationStatuses({ 'X-api-authenticate': apiKey }, jobId, {
        limit: 100
      });
      setApplicationStatuses(response.data.job_application_statuses);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading((prev) => prev - 1);
    }
  }, [apiKey, jobId]);

  useEffect(() => {
    getFirstApplicationsAndPreferences();
    getFilterableStatuses();
    getFilterableCountries();
    getFilterableSuburbs();
    getApplicationStatuses();
  }, [
    getFirstApplicationsAndPreferences,
    getFilterableStatuses,
    getFilterableCountries,
    getFilterableSuburbs,
    getApplicationStatuses
  ]);

  const deselectAll = () => {
    setSelected([]);
    setGlobalSelected([]);
    setSelectAllIsChecked(false);
    setSelectAllIsIndeterminate(false);
  };

  const handleDelete = async (url: string) => {
    setIsDeleting(true);
    try {
      const deleted = await Api.deleteApplications(
        url,
        { 'X-api-authenticate': apiKey },
        { application_ids: globalSelected.map((app) => app.id) }
      );
      setCurrentPage(1);
      getApplications(1, null, null, null, null);
      setJob((prev) => ({
        ...prev,
        applications_count: prev.applications_count - deleted.application_ids.success.length
      }));
      setSnackbar({
        message: `Successfully trashed ${deleted.application_ids.success.length} application(s)`,
        state: 'success'
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsDeleting(false);
      setModalsOpen({ ...modalsOpen, delete: false });
      deselectAll();
    }
  };

  const handleDeselectCandidate = (id: number) => {
    let newGlobalSelected = [...globalSelected];
    newGlobalSelected = newGlobalSelected.filter((app) => app.id !== id);
    const newSelected = newGlobalSelected.map((app) => app.id);
    setSelected(newSelected);
    setGlobalSelected(newGlobalSelected);
    setSelectAllIsChecked(
      applications.map((app) => app.id).every((id) => newSelected.includes(id))
    );
    setSelectAllIsIndeterminate(
      applications.map((app) => app.id).some((id) => newSelected.includes(id))
    );
  };

  const handleOpenAIInsights = () => {
    if (globalSelected.length > 10) {
      setSnackbar({
        message: 'AI insights supports a maximum of 10 candidates at a time.',
        state: 'error'
      });
      return;
    }
    setModalsOpen({ ...modalsOpen, aiInsights: true });
  };

  const clearAllSelection = () => {
    setSelectAllApplications(false);
    setGlobalSelected([]);
    setSelected([]);
    setSelectAllIsChecked(false);
    setSelectAllIsIndeterminate(false);
  };

  const enableSortableColumn = (id: SortableColumnId) => {
    setSortableColumns((prevSortableColumn) => {
      const updatedSortableColumns = prevSortableColumn.map((sortableColumn) => {
        if (sortableColumn.id === id && !sortableColumn.enabled) {
          return { ...sortableColumn, enabled: true };
        }
        return sortableColumn;
      });
      saveSortableColumns({ sortableColumns: updatedSortableColumns });
      return updatedSortableColumns;
    });
  };

  const showGender =
    userPermissions?.['Job Options']?.['Candidate Gender'] && jobOptions?.gender_field === '1';

  return (
    <Box sx={classes.applicationsContainer}>
      <ApplicationsActionsPanel
        selected={selected}
        setModalsOpen={setModalsOpen}
        userPermissions={userPermissions}
        handleOpenAIInsights={handleOpenAIInsights}
      />
      <Box sx={classes.filtersRow}>
        <Search
          setSearch={setSearch}
          getApplications={getApplications}
          setPage={setCurrentPage}
          jobId={jobId}
        />
        <FilterDropdown
          filters={filters}
          setFilters={setFilters}
          getApplications={getApplications}
          statusList={filterableStatuses}
          countryList={filterableCountries}
          suburbList={filterableSuburbs}
          setUserPreferences={setUserPreferences}
          setCurrentPage={setCurrentPage}
          jobId={jobId}
          clearAllSelection={clearAllSelection}
        />
        <FieldDropdown
          sortableColumns={sortableColumns}
          setSortableColumns={setSortableColumns}
          showGender={showGender}
          density={density}
          setDensity={setDensity}
          setUserPreferences={setUserPreferences}
        />
        <TempCSVExport
          apiKey={apiKey}
          jobId={jobId}
          setSnackbarState={setSnackbar}
          filters={filters}
          sortBy={sortBy}
          sortOrder={sortOrder}
          sortableColumns={sortableColumns}
        />
        {userPermissions?.['Applications']?.['Lodge Application'] &&
          (!jobIsLoading ? (
            <Button
              onClick={() => {
                setModalsOpen({ ...modalsOpen, newApplication: true });
              }}
              sx={classes.newApplicationButton}
              id="new-application-button"
            >
              New application
            </Button>
          ) : (
            <Skeleton animation="wave" height="60px" width="168px" />
          ))}
      </Box>
      {newApplicationEnabled && (
        <Stack
          sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'end', columnGap: 1 }}
        >
          <FormControlLabel
            sx={{
              ...classes.switchLabels,
              '& .MuiFormControlLabel-label': {
                color: '#666666',
                fontFamily: 'Source Sans Pro',
                fontSize: '14px',
                fontWeight: 'bold'
              },
              marginRight: 1
            }}
            control={
              <Switch
                sx={newApplicationPage ? classes.switchActive : classes.switch}
                checked={newApplicationPage}
                onChange={() => setNewApplicationPage(!newApplicationPage)}
              />
            }
            label="New application page"
            labelPlacement="end"
          />
          <Stack sx={classes.applicationBetaButton}>BETA</Stack>
          <Tooltip
            title="This toggle will enable the new application page when you click on an application in the table"
            placement="top"
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  width: '250px'
                }
              }
            }}
          >
            <InfoIcon sx={{ color: '#5BC4BF' }} />
          </Tooltip>
        </Stack>
      )}
      <ApplicationsTable
        apiKey={apiKey}
        applications={applications}
        applicationStatuses={applicationStatuses}
        clearAllSelection={clearAllSelection}
        currentPage={currentPage}
        density={density}
        enableSortableColumn={() => enableSortableColumn(SortableColumnId.NOTE_PAD)}
        getAllApplications={getAllApplications}
        getApplications={getApplications}
        getFilterableStatuses={getFilterableStatuses}
        globalSelected={globalSelected}
        isLoading={isLoading > 0}
        jobId={jobId}
        jobTitle={jobTitle}
        rowsPerPage={rowsPerPage}
        selectAllApplications={selectAllApplications}
        selectAllIsChecked={selectAllIsChecked}
        selectAllIsIndeterminate={selectAllIsIndeterminate}
        selected={selected}
        setApplications={setApplications}
        setCurrentPage={setCurrentPage}
        setGlobalSelected={setGlobalSelected}
        setPreviewOpen={setPreviewOpen}
        setRowsPerPage={setRowsPerPage}
        setSelectAllApplications={setSelectAllApplications}
        setSelectAllIsChecked={setSelectAllIsChecked}
        setSelectAllIsIndeterminate={setSelectAllIsIndeterminate}
        setSelected={setSelected}
        setSelectedAttachment={setSelectedAttachment}
        setSnackbarState={setSnackbar}
        setSortableColumns={setSortableColumns}
        setSortBy={setSortBy}
        setSortOrder={setSortOrder}
        showGender={showGender}
        sortableColumns={sortableColumns}
        sortBy={sortBy}
        sortOrder={sortOrder}
        totalApplications={totalApplications}
        totalPages={totalPages}
        userPermissions={userPermissions}
        newNoteOnApplicationEnabled={newNoteOnApplicationEnabled}
        newApplicationPage={newApplicationPage}
      />
      <FilePreviewModal
        previewOpen={previewOpen}
        setPreviewOpen={setPreviewOpen}
        selectedAttachment={selectedAttachment}
        applicationLink={selectedAttachment?.application_link}
        isShareable={false}
      />
      {!jobIsLoading && (
        <NewApplication
          apiKey={apiKey}
          modalsOpen={modalsOpen}
          setModalsOpen={setModalsOpen}
          getApplications={getApplications}
          jobId={jobId}
          jobQuestions={jobQuestions}
          jobAttachments={jobAttachments}
          jobSources={jobSources}
          jobHasPrivacyAcknowledgement={jobHasPrivacyAcknowledgement}
          setSnackbar={setSnackbar}
          setJob={setJob}
        />
      )}
      <SMSCandidates
        apiKey={apiKey}
        jobId={jobId}
        setSnackbar={setSnackbar}
        modalsOpen={modalsOpen}
        setModalsOpen={setModalsOpen}
        globalSelected={globalSelected}
        handleDeselectCandidate={handleDeselectCandidate}
        handleTemplatePermissions={
          !!userPermissions?.['Entities']?.['Create / Edit / Delete SMS Templates']
        }
        filters={filters}
      />
      <EmailCandidates
        apiKey={apiKey}
        jobId={jobId}
        setSnackbar={setSnackbar}
        modalsOpen={modalsOpen}
        setModalsOpen={setModalsOpen}
        globalSelected={globalSelected}
        handleDeselectCandidate={handleDeselectCandidate}
        filters={filters}
      />
      <MoveApplications
        apiKey={apiKey}
        jobId={jobId}
        setSnackbar={setSnackbar}
        modalsOpen={modalsOpen}
        setModalsOpen={setModalsOpen}
        globalSelected={globalSelected}
        handleDeselectCandidate={handleDeselectCandidate}
        getApplications={getApplications}
        deselectAll={deselectAll}
        filters={filters}
      />
      <ChangeStatus
        apiKey={apiKey}
        jobId={jobId}
        setSnackbar={setSnackbar}
        modalsOpen={modalsOpen}
        setModalsOpen={setModalsOpen}
        globalSelected={globalSelected}
        handleDeselectCandidate={handleDeselectCandidate}
        applicationStatuses={applicationStatuses}
        getApplications={getApplications}
        filters={filters}
      />
      {modalsOpen.addToEvent && (
        <AddToEvent
          apiKey={apiKey}
          jobId={jobId}
          setSnackbar={setSnackbar}
          modalsOpen={modalsOpen}
          setModalsOpen={setModalsOpen}
          globalSelected={globalSelected}
          handleDeselectCandidate={handleDeselectCandidate}
          filters={filters}
          smsTemplatePermissions={
            !!userPermissions?.['Entities']?.['Create / Edit / Delete SMS Templates']
          }
        />
      )}
      {modalsOpen.inviteToTimeslot && (
        <InviteToTimeslot
          apiKey={apiKey}
          jobId={jobId}
          setSnackbar={setSnackbar}
          modalsOpen={modalsOpen}
          setModalsOpen={setModalsOpen}
          globalSelected={globalSelected}
          handleDeselectCandidate={handleDeselectCandidate}
          filters={filters}
          smsTemplatePermissions={
            !!userPermissions?.['Entities']?.['Create / Edit / Delete SMS Templates']
          }
        />
      )}
      <ExternalEmail
        apiKey={apiKey}
        jobId={jobId}
        setSnackbar={setSnackbar}
        modalsOpen={modalsOpen}
        setModalsOpen={setModalsOpen}
        globalSelected={globalSelected}
        handleDeselectCandidate={handleDeselectCandidate}
        filters={filters}
      />
      <ExportPDF
        apiKey={apiKey}
        jobId={jobId}
        setSnackbar={setSnackbar}
        modalsOpen={modalsOpen}
        setModalsOpen={setModalsOpen}
        globalSelected={globalSelected}
        handleDeselectCandidate={handleDeselectCandidate}
        filters={filters}
      />
      <ExportToOnboard
        apiKey={apiKey}
        jobId={jobId}
        setSnackbar={setSnackbar}
        modalsOpen={modalsOpen}
        setModalsOpen={setModalsOpen}
        globalSelected={globalSelected}
        handleDeselectCandidate={handleDeselectCandidate}
        applicationStatuses={applicationStatuses}
        getApplications={getApplications}
        filters={filters}
      />
      <ExportFoundu
        apiKey={apiKey}
        jobId={jobId}
        setSnackbar={setSnackbar}
        modalsOpen={modalsOpen}
        setModalsOpen={setModalsOpen}
        globalSelected={globalSelected}
        handleDeselectCandidate={handleDeselectCandidate}
        filters={filters}
      />
      <InsightsModal
        jobId={jobId}
        open={modalsOpen.aiInsights}
        handleClose={() => setModalsOpen({ ...modalsOpen, aiInsights: false })}
        selectedApplicants={globalSelected}
        handleDeselectApplicant={handleDeselectCandidate}
        setSnackbar={setSnackbar}
      />
      <GenericDialog
        isDialogOpen={modalsOpen.delete}
        setDialogOpen={(isOpen: boolean) => setModalsOpen({ ...modalsOpen, delete: isOpen })}
        buttonCallback={handleDelete}
        title={`Trash Application${selected?.length > 1 ? 's' : ''}`}
        description={`Are you sure you want to trash ${
          selected?.length > 1 ? selected.length : 'the selected'
        } application${selected?.length > 1 ? 's' : ''}?`}
        buttonText="Trash"
        callbackLoading={isDeleting}
        url={`/api/v4/jobs/${jobId}/applications`}
      />
      <StyledSnackbar
        message={snackbar.message}
        state={snackbar.state}
        setSnackbarState={setSnackbar}
      />
    </Box>
  );
}
