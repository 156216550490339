import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Application from './Application';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

export default function ApplicationPage({
  applicationId,
  jobId,
  showLinkedInURL,
  aiSummariesEnabled,
  apiKey,
  currentUserId
}: {
  applicationId: number;
  jobId: number;
  showLinkedInURL: boolean;
  aiSummariesEnabled: boolean;
  apiKey: string;
  currentUserId: number;
}) {
  return (
    <QueryClientProvider client={queryClient}>
      <Application
        applicationId={applicationId}
        jobId={jobId}
        showLinkedInURL={showLinkedInURL}
        aiSummariesEnabled={aiSummariesEnabled}
        apiKey={apiKey}
        currentUserId={currentUserId}
      />
    </QueryClientProvider>
  );
}
