import React, { useState, useEffect } from 'react';
import { Box, Button, Stack, CircularProgress } from '@mui/material';
import { FormTextField, MultilineFormTextField } from '../CustomUIElements/FormTextField';
import FormRadioButtonGroup from '../CustomUIElements/FormRadioButtonGroup';
import FormRadioButtonWithTextInputGroup from '../CustomUIElements/FormRadioButtonWithTextInputGroup';
import FormCheckboxGroup from '../CustomUIElements/FormCheckboxGroup';
import FormSelectField from '../CustomUIElements/FormSelectField';
import DebounceInput from './DebounceInput';
import { UncontrolledSingleDatePicker } from '../CustomUIElements/SingleDatePicker';
import { classes } from './styles';
import { sharedClasses } from '../CustomUIElements/sharedClasses';
import moment from 'moment';
import dompurify from 'dompurify';
import FormRankField from '../CustomUIElements/FormRankField';
import AvailabilityPreview from '../../ApprovalForms/Questions/Modals/AvailabilityPreview';

export interface IApplicantQuestion {
  id: number;
  required: boolean;
  enabled: boolean;
  position: number;
  title: string;
  type: string;
  field_choices: IFieldChoice[];
  availability_config: IAvailabilityConfig;
  original_title: string;
  for_email_template: boolean;
  rateable: boolean;
}

export interface IAvailabilityConfig {
  allow_break: boolean;
  created_at: string;
  field_id: number;
  heading_1: string;
  heading_2: string;
  id: number;
  updated_at: string;
  week_duration: string;
  week_start_day: number;
}

export interface IFieldChoice {
  id: number;
  name: string;
  position: number;
}

export default function QuestionFieldGenerator({
  question,
  onChangeCallback,
  questionRef,
  questionError,
  handleLookUpButton,
  answer,
  fetchingCSV,
  disabled = false,
  inputText,
  setApprovalFormQuestions,
  approvalPage
}: {
  question: IApplicantQuestion;
  onChangeCallback: (id: number, value: string | number | Record<string, boolean | string>) => void;
  questionRef?: (el: HTMLDivElement) => void;
  questionError?: string;
  handleLookUpButton?: (questionId: number, positionId: number) => void;
  answer?: string | Record<string, string>;
  fetchingCSV?: boolean;
  disabled?: boolean;
  inputText?: string;
  setApprovalFormQuestions?: (value: Record<string, { data: string; inputText: string }>) => void;
  approvalPage?: boolean;
}) {
  const csvOptions = ['Please choose...', 'Existing Position', 'New Position'];
  const [selectedCSVOption, setSelectedCSVOption] = useState<string>(csvOptions[0]);
  const [positionId, setPositionId] = useState<string>('');

  useEffect(() => {
    if (question.type === 'CsvDataLookupField' && answer.length > 0) {
      setSelectedCSVOption(answer[0]);
      setPositionId(answer[1]);
    }
  }, [answer]);

  switch (question.type) {
    case 'RadioButtonField':
      return (
        <Box>
          <Box
            sx={{
              ...classes.groupLabel,
              ...(question.for_email_template ? { color: '#333333 !important' } : {})
            }}
            dangerouslySetInnerHTML={{
              __html:
                dompurify.sanitize(question.title.replace(/\n/g, '<br>')) +
                (question.required ? '<span>*</span>' : '')
            }}
          />
          {question.for_email_template && approvalPage ? (
            <FormRadioButtonWithTextInputGroup
              options={question.field_choices.map((choice) => ({
                label: choice.name,
                value: choice.id
              }))}
              onChange={(value: string | number) => onChangeCallback(question.id, value)}
              innerRef={questionRef}
              error={questionError}
              defaultValue={answer}
              draggable={disabled}
              question={question}
              inputText={inputText}
              setApprovalFormQuestions={setApprovalFormQuestions}
            />
          ) : (
            <FormRadioButtonGroup
              options={question.field_choices.map((choice) => ({
                label: choice.name,
                value: choice.id
              }))}
              onChange={(value: string | number) => onChangeCallback(question.id, value)}
              innerRef={questionRef}
              error={questionError}
              defaultValue={answer}
              draggable={disabled}
            />
          )}
        </Box>
      );
    case 'CheckBoxField':
      return (
        <Box>
          <Box
            sx={classes.groupLabel}
            dangerouslySetInnerHTML={{
              __html:
                dompurify.sanitize(question.title) + (question.required ? '<span>*</span>' : '')
            }}
          />
          <FormCheckboxGroup
            options={question.field_choices}
            onChange={(value) => onChangeCallback(question.id, value)}
            innerRef={questionRef}
            error={questionError}
            defaultValues={answer}
            disabled={disabled}
          />
        </Box>
      );
    case 'TextField':
      return (
        <Box>
          <Box
            sx={classes.inputLabel}
            dangerouslySetInnerHTML={{
              __html:
                dompurify.sanitize(question.title) + (question.required ? '<span>*</span>' : '')
            }}
          />
          <DebounceInput
            onDebouncedChange={(value: string) => onChangeCallback(question.id, value)}
            defaultValue={answer}
          >
            {({ value, onChange }) => (
              <FormTextField
                value={value}
                onChange={onChange}
                styles={{ width: '290px' }}
                innerRef={questionRef}
                required={question.required}
                error={questionError}
                draggable={disabled}
              />
            )}
          </DebounceInput>
        </Box>
      );
    case 'TextAreaField':
      return (
        <Box>
          <Box
            sx={classes.inputLabel}
            dangerouslySetInnerHTML={{
              __html:
                dompurify.sanitize(question.title) + (question.required ? '<span>*</span>' : '')
            }}
          />
          <DebounceInput
            onDebouncedChange={(value: string) => onChangeCallback(question.id, value)}
            defaultValue={answer}
          >
            {({ value, onChange }) => (
              <MultilineFormTextField
                value={value}
                onChange={onChange}
                styles={{ width: '600px' }}
                innerRef={questionRef}
                error={questionError}
                required={question.required}
                draggable={disabled}
              />
            )}
          </DebounceInput>
        </Box>
      );
    case 'SelectField':
      return (
        <Box>
          <Box
            sx={classes.inputLabel}
            dangerouslySetInnerHTML={{
              __html:
                dompurify.sanitize(question.title) + (question.required ? '<span>*</span>' : '')
            }}
          />
          <FormSelectField
            options={question.field_choices.map((choice) => ({
              label: choice.name,
              value: choice.id
            }))}
            defaultValue={answer}
            onChange={(value: string | number) => onChangeCallback(question.id, value)}
            styles={{ width: '290px' }}
            innerRef={questionRef}
            error={questionError}
            required={question.required}
            isDisabled={disabled}
          />
        </Box>
      );
    case 'RankField':
      return (
        <Box>
          <Box
            sx={classes.groupLabel}
            dangerouslySetInnerHTML={{
              __html:
                dompurify.sanitize(question.title) + (question.required ? '<span>*</span>' : '')
            }}
          />
          <FormRankField
            choices={question.field_choices}
            onChange={(value) => onChangeCallback(question.id, value)}
            innerRef={questionRef}
            error={questionError}
            required={question.required}
            defaultValue={answer}
            disabled={disabled}
          />
        </Box>
      );
    case 'DateField':
      return (
        <Box>
          <Box
            sx={classes.groupLabel}
            dangerouslySetInnerHTML={{
              __html:
                dompurify.sanitize(question.title) + (question.required ? '<span>*</span>' : '')
            }}
          />
          <Box sx={classes.formDateWrapper} ref={questionRef}>
            <UncontrolledSingleDatePicker
              defaultValue={moment(answer)}
              handleDateChange={(date: Date | null) =>
                onChangeCallback(question.id, date ? moment(date).format('YYYY-MM-DD') : '')
              }
              inputStyles={classes.formDatePickerStyles}
              disabled={disabled}
              innerRef={questionRef}
            />
            {questionError && <Box sx={sharedClasses.errorBox}>{questionError}</Box>}
          </Box>
        </Box>
      );
    case 'CsvDataLookupField': {
      return (
        <Box>
          <Box
            sx={classes.groupLabel}
            dangerouslySetInnerHTML={{ __html: dompurify.sanitize(question.title) }}
          />
          <FormSelectField
            options={csvOptions}
            defaultValue={selectedCSVOption}
            value={selectedCSVOption}
            styles={{ width: '290px' }}
            onChange={(value: string) => setSelectedCSVOption(value)}
            isDisabled={disabled}
          />
          {selectedCSVOption !== csvOptions[0] && (
            <Stack sx={{ marginTop: 2 }}>
              {selectedCSVOption === 'Existing Position'
                ? 'Please enter the Existing Position Number'
                : 'Please enter the Reports to Manager’s Position Number'}
              <Stack sx={{ flexDirection: 'row', alignItems: 'center', marginTop: 1 }}>
                <DebounceInput
                  onDebouncedChange={(value: string) => {
                    setPositionId(value);
                    onChangeCallback(question.id, [selectedCSVOption, value]);
                  }}
                  defaultValue={positionId}
                >
                  {({ value, onChange }) => (
                    <FormTextField
                      value={value}
                      onChange={onChange}
                      styles={{ width: '290px' }}
                      innerRef={questionRef}
                      required={question.required}
                      error={questionError}
                      draggable={disabled}
                    />
                  )}
                </DebounceInput>
                <Button
                  sx={classes.lookUpButton}
                  onClick={() => handleLookUpButton(question.id, positionId)}
                >
                  {fetchingCSV ? <CircularProgress size={20} color="inherit" /> : 'Look Up'}
                </Button>
              </Stack>
            </Stack>
          )}
        </Box>
      );
    }
    case 'AvailabilityField':
      return (
        <Box>
          <Box
            sx={classes.groupLabel}
            dangerouslySetInnerHTML={{
              __html:
                dompurify.sanitize(question.title) + (question.required ? '<span>*</span>' : '')
            }}
          />
          <Box sx={classes.formDateWrapper} ref={questionRef}>
            <AvailabilityPreview
              onChange={(value) => onChangeCallback(question.id, value)}
              availabilityConfig={question.availability_config}
              answer={answer}
              error={questionError}
              disabled={disabled}
            />
          </Box>
        </Box>
      );
    case 'JobReferenceLookupField':
      return null;
    default:
      return (
        <Box>
          <Box ref={questionRef}>{question.title}</Box>
          Invalid question type
        </Box>
      );
  }
}
