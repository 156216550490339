"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Api from "./API";
import { secondsToTimeString } from "./config";
export function useGetEmailTemplate({
  apiKey,
  selectedEmailTemplate,
  setBody,
  dispatch,
  setSubject,
  setTemplateAttachments
}) {
  useQuery({
    queryKey: [selectedEmailTemplate],
    queryFn: () => __async(this, null, function* () {
      if (!selectedEmailTemplate) return;
      const { res } = yield Api.getEmailTemplate(
        apiKey,
        Number(selectedEmailTemplate.split(" -")[0])
      );
      return res;
    }),
    onSuccess: (emailTemplate) => {
      setSubject && setSubject(emailTemplate.subject);
      setTemplateAttachments && setTemplateAttachments(emailTemplate.email_attachments);
      setBody(emailTemplate.body);
    },
    onError: (error) => dispatch({
      type: "SET_SNACKBAR",
      payload: { message: error.errors.join(", "), state: "error" }
    }),
    enabled: !!selectedEmailTemplate
  });
}
export function useGetEvents({
  apiKey,
  dispatch,
  eventTypeId,
  enabled = true
}) {
  const { data: events, isLoading: loadingEvents } = useQuery({
    queryKey: ["events", eventTypeId],
    queryFn: () => __async(this, null, function* () {
      const { res } = yield Api.getEvents(apiKey, /* @__PURE__ */ new Date(), eventTypeId);
      return res.events;
    }),
    onError: (error) => dispatch({
      type: "SET_SNACKBAR",
      payload: { message: error.errors.join(", "), state: "error" }
    }),
    enabled
  });
  return { events, loadingEvents };
}
export function useGetAssignableUsers({ dispatch }) {
  const { data: assignableUsers, isLoading: loadingAssignableUsers } = useQuery({
    queryKey: ["assignable users"],
    queryFn: () => __async(this, null, function* () {
      const { res } = yield Api.getAssignableUsers();
      return res.assignable_users;
    }),
    onError: (error) => dispatch({
      type: "SET_SNACKBAR",
      payload: {
        message: `There was an error getting assignable users data, ${error}`,
        state: "error"
      }
    })
  });
  return { assignableUsers, loadingAssignableUsers };
}
export function useFormQuestions({
  application,
  selectedFormId,
  dispatch
}) {
  const { data: formQuestions, isLoading: loadingFormQuestions } = useQuery({
    queryKey: ["formQuestions", selectedFormId],
    queryFn: () => __async(this, null, function* () {
      if (!application || !selectedFormId) return;
      const { res } = yield Api.getFormQuestions({
        form_id: selectedFormId
      });
      return res.fields;
    }),
    onError: (error) => dispatch({
      type: "SET_SNACKBAR",
      payload: {
        message: `There was an error getting form questions, ${error}`,
        state: "error"
      }
    }),
    enabled: !!application && !!selectedFormId
  });
  return { formQuestions, loadingFormQuestions };
}
export function useFormResponse({
  FormResponseId,
  dispatch,
  application
}) {
  return useQuery({
    queryKey: ["formQuestionAndAnswers", FormResponseId],
    queryFn: () => __async(this, null, function* () {
      if (!application) return;
      const { res } = yield Api.showFormResponse(
        application.job.id,
        application.id,
        FormResponseId
      );
      return res.question_and_answers;
    }),
    onError: (error) => dispatch({
      type: "SET_SNACKBAR",
      payload: {
        message: `There was an error getting form response, ${error}`,
        state: "error"
      }
    }),
    enabled: !!application
  });
}
export function useTimestampData({
  selectedAttachment,
  dispatch,
  listRef,
  application,
  newTimestamp,
  setNewTimestamp
}) {
  var _a;
  const queryClient = useQueryClient();
  const [timestampAdded, setTimestampAdded] = useState(false);
  const { data: timestampData, isLoading: loadingTimestampData } = useQuery({
    queryKey: ["timestamp_data", selectedAttachment],
    queryFn: () => __async(this, null, function* () {
      if ((selectedAttachment == null ? void 0 : selectedAttachment.id) && application) {
        const { res } = yield Api.getVideoTimestamps(selectedAttachment.id);
        return res;
      }
    }),
    onSettled: () => {
      var _a2;
      if (timestampAdded) {
        (_a2 = listRef.current) == null ? void 0 : _a2.scrollTo(0, listRef.current.scrollHeight);
        setTimestampAdded(false);
      }
    },
    onError: (error) => dispatch({
      type: "SET_SNACKBAR",
      payload: {
        message: `There was an error getting timestamp data, ${error}`,
        state: "error"
      }
    }),
    enabled: (_a = selectedAttachment == null ? void 0 : selectedAttachment.attached_content_type) == null ? void 0 : _a.includes("video")
  });
  const { mutate: updateTimestamp, isLoading: updatingTimestamp } = useMutation({
    mutationFn: () => __async(this, null, function* () {
      if (!selectedAttachment || !timestampData || !newTimestamp.id) return;
      const { res } = yield Api.updateVideoTimestamp(
        selectedAttachment.id,
        newTimestamp.id,
        newTimestamp.name,
        newTimestamp.value
      );
      return res;
    }),
    onSuccess: () => {
      dispatch({
        type: "SET_SNACKBAR",
        payload: { message: "Timestamp updated successfully", state: "success" }
      });
      setNewTimestamp({ id: null, name: "", value: "" });
      queryClient.invalidateQueries(["timestamp_data", selectedAttachment], { exact: true });
    },
    onError: (error) => {
      dispatch({
        type: "SET_SNACKBAR",
        payload: { message: `Error updating task: ${error}`, state: "error" }
      });
    }
  });
  const { mutate: addTimestamp } = useMutation({
    mutationFn: () => __async(this, null, function* () {
      if (!selectedAttachment || !timestampData) return;
      const { res } = yield Api.createVideoTimestamp(
        selectedAttachment.id,
        newTimestamp.name,
        newTimestamp.value
      );
      return res;
    }),
    onSuccess: () => {
      dispatch({
        type: "SET_SNACKBAR",
        payload: { message: "Timestamp added successfully", state: "success" }
      });
      setNewTimestamp(__spreadProps(__spreadValues({}, newTimestamp), { name: "", value: "" }));
      dispatch({ type: "RESET_TIMESTAMP_STATE" });
      queryClient.invalidateQueries(["timestamp_data", selectedAttachment], { exact: true });
      setTimestampAdded(true);
    },
    onError: (error) => {
      dispatch({
        type: "SET_SNACKBAR",
        payload: { message: `Error adding timestamp: ${error}`, state: "error" }
      });
    }
  });
  const { mutate: deleteTimestamp } = useMutation({
    mutationFn: (id) => __async(this, null, function* () {
      if (!selectedAttachment || !timestampData) return;
      const { res } = yield Api.deleteVideoTimestamp(selectedAttachment.id, id);
      return res;
    }),
    onSuccess: () => {
      dispatch({
        type: "SET_SNACKBAR",
        payload: { message: "Timestamp deleted successfully", state: "success" }
      });
      queryClient.invalidateQueries(["timestamp_data", selectedAttachment], { exact: true });
    },
    onError: (error) => {
      dispatch({
        type: "SET_SNACKBAR",
        payload: { message: `Error deleting timestamp: ${error}`, state: "error" }
      });
    }
  });
  return {
    timestampData,
    loadingTimestampData,
    updateTimestamp,
    updatingTimestamp,
    addTimestamp,
    deleteTimestamp
  };
}
export function useCurrentTime(videoRef, setCurrentTime) {
  useEffect(() => {
    const videoElement = videoRef.current;
    const getCurrentTime = () => {
      if (videoRef.current) {
        setCurrentTime(secondsToTimeString(videoRef.current.currentTime));
      }
    };
    if (videoElement) {
      videoElement.addEventListener("timeupdate", getCurrentTime);
      videoElement.addEventListener("pause", getCurrentTime);
    }
    return () => {
      if (videoElement) {
        videoElement.removeEventListener("timeupdate", getCurrentTime);
        videoElement.removeEventListener("pause", getCurrentTime);
      }
    };
  }, [videoRef]);
}
