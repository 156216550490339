import React from 'react';
import { IFieldChoice } from '../Utilities/QuestionFieldGenerator';
import Box from '@mui/material/Box';
import FormSelectField from './FormSelectField';
import { sharedClasses } from './sharedClasses';

const populateRanks = (choices: IFieldChoice[]) => {
  const ranks: Record<string, string> = {};
  choices.forEach((choice, index) => {
    ranks[choice.id] = (index + 1).toString();
  });
  return ranks;
};

export default function FormRankField({
  choices,
  onChange,
  groupLabel,
  innerRef,
  error,
  required,
  defaultValue,
  disabled = false
}: {
  choices: IFieldChoice[];
  onChange: (value: Record<string | number, string>) => void;
  groupLabel?: string;
  innerRef?: React.Ref<HTMLDivElement>;
  error?: string;
  required?: boolean;
  defaultValue?: Record<string, number>;
  disabled?: boolean;
}) {
  const [ranks, setRanks] = React.useState(defaultValue || populateRanks(choices));
  const options = choices.map((_, index: number) => (index + 1).toString());

  const handleChange = (value: string | number, name: string | number) => {
    const newRanks = { ...ranks, [name]: value };
    setRanks(newRanks);
    onChange(newRanks);
  };

  return (
    <Box ref={innerRef}>
      {groupLabel && (
        <Box sx={sharedClasses.groupLabel}>
          {groupLabel}
          {required && <span>*</span>}
        </Box>
      )}
      {choices.map((choice) => (
        <Box key={choice.id} sx={sharedClasses.rankFieldOption}>
          <FormSelectField
            defaultValue={ranks[choice.id]}
            options={options}
            isDisabled={disabled}
            styles={{
              '& .MuiInputBase-input': {
                fontSize: '14px'
              }
            }}
            onChange={(value) => {
              handleChange(value, choice.id);
            }}
          />
          {choice.name}
        </Box>
      ))}
      {error && <Box sx={{ ...sharedClasses.errorBoxCheckbox, marginLeft: '14px' }}>{error}</Box>}
    </Box>
  );
}
