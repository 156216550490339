import React, { forwardRef } from 'react';
import { Box } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import CloseIcon from '@mui/icons-material/Close';
import { classes } from '../styles';

const EmailButton = forwardRef<
  HTMLElement,
  {
    onClick: () => void;
    inputOpen: boolean;
  }
>(({ onClick, inputOpen, ...props }, ref) => {
  return (
    <Box
      sx={{
        ...classes.floatingActionButton,
        ...classes.emailInputButton,
        ...(inputOpen && classes.inputOpen)
      }}
      onClick={() => onClick()}
      ref={ref}
      {...props}
    >
      {inputOpen ? <CloseIcon /> : <EmailIcon />}
    </Box>
  );
});
EmailButton.displayName = 'EmailButton';
export default EmailButton;
