"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
export const getAnswerValue = (question, questionIndex, formResponse) => {
  switch (question.type) {
    case "RadioButtonField":
    case "SelectField":
      return formResponse[questionIndex].field_choice_ids.length > 0 ? formResponse[questionIndex].field_choice_ids[0].toString() : {};
    case "RankField":
      return formResponse[questionIndex].field_choice_ids ? formResponse[questionIndex].field_choice_ids.reduce(
        (acc, curr, i) => __spreadProps(__spreadValues({}, acc), { [curr]: (i + 1).toString() }),
        {}
      ) : {};
    case "CheckBoxField":
      return formResponse[questionIndex].field_choices.reduce(
        (acc, curr) => {
          acc[curr.id] = formResponse[questionIndex].field_choice_ids.includes(curr.id);
          return acc;
        },
        {}
      );
    default:
      return formResponse[questionIndex].answer;
  }
};
