import React, { useState } from 'react';
import { Popover, Stack } from '@mui/material';
import { classes } from './styles';

const formatDate = (date: Date) => {
  return date.toLocaleDateString('en-GB', {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });
};

export default function DateDisplay({ dates }: { dates: Date[] }) {
  const [datesAnchorEl, setDatesAnchorEl] = useState<HTMLDivElement | null>(null);
  return (
    <Stack flexDirection="row">
      {formatDate(dates[0])}
      {dates.length > 1 && (
        <Stack
          sx={classes.multipleDatesIndicator}
          onClick={(e) => setDatesAnchorEl(e.currentTarget)}
        >{`+${dates.length - 1}`}</Stack>
      )}
      <Popover
        open={Boolean(datesAnchorEl)}
        anchorEl={datesAnchorEl}
        onClose={() => setDatesAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        sx={{ '& .MuiPaper-root': { marginTop: 1 } }}
      >
        <Stack sx={{ padding: '8px 16px', rowGap: '4px' }}>
          {dates.slice(1).map((d, i) => (
            <Stack key={i} sx={{ fontSize: '14px' }}>
              {formatDate(d)}
            </Stack>
          ))}
        </Stack>
      </Popover>
    </Stack>
  );
}
