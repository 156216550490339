import { Box, Skeleton, Stack } from '@mui/material';
import { AccountBox, InsertDriveFile, Star, WatchLater } from '@mui/icons-material';
import dayjs from 'dayjs';
import React from 'react';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advanced from 'dayjs/plugin/advancedFormat';
import { getApplicationRatingColor } from '../../config';
import { styles } from '../../styles';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);

const WidgetBlock = ({
  icon,
  heading,
  body,
  isLoading
}: {
  icon: React.ReactNode;
  heading: string;
  body: React.ReactNode;
  isLoading: boolean;
}) => (
  <Box sx={styles.widgetBlock}>
    <Box sx={styles.widgetBlockIcon}>{icon}</Box>
    <Stack sx={styles.widgetBlockText}>
      <Box sx={styles.widgetBlockHeading}>{heading}</Box>
      <Box sx={styles.widgetBlockBody}>
        {isLoading ? <Skeleton animation="wave" width={80} height={30} /> : body}
      </Box>
    </Stack>
  </Box>
);

export const RenderFormResponseBlocks = ({
  ApplicationId,
  name,
  created_at,
  submitted_by,
  rating,
  isLoading,
  expandedRows,
  linkToUser
}: {
  ApplicationId: number;
  name: string;
  created_at: string;
  submitted_by: string;
  rating: string;
  isLoading: boolean;
  expandedRows: number[];
  linkToUser?: string;
}) => {
  const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const BASE_URL = window.location.origin;
  return (
    <Box sx={styles.subWidget}>
      <Box sx={styles.widgetBlockContainer}>
        <WidgetBlock
          icon={<InsertDriveFile fontSize={'small'} />}
          heading="Application"
          body={<Box>{`${ApplicationId} - ${name}`}</Box>}
          isLoading={isLoading && !expandedRows.includes(ApplicationId)}
        />
        <WidgetBlock
          icon={<WatchLater fontSize={'small'} />}
          heading="Submitted at"
          body={<Box>{dayjs.tz(created_at, timeZoneString).format('Do MMMM YYYY[,] h:mma')}</Box>}
          isLoading={isLoading}
        />
        <WidgetBlock
          icon={<AccountBox fontSize={'small'} />}
          heading="Submitted by"
          body={
            <Box>
              {linkToUser ? <a href={BASE_URL + linkToUser}>{submitted_by}</a> : submitted_by}
            </Box>
          }
          isLoading={isLoading}
        />
        <WidgetBlock
          icon={<Star fontSize={'small'} />}
          heading="Rating"
          body={
            <Box
              sx={{
                color: getApplicationRatingColor(rating === 'N/A' ? 101 : Number(rating)),
                backgroundColor: 'transparent'
              }}
            >
              {!isNaN(parseFloat(rating)) ? Math.ceil(parseFloat(rating)) + '%' : '-'}
            </Box>
          }
          isLoading={isLoading}
        />
      </Box>
    </Box>
  );
};
