import React from 'react';
import { Button, Stack } from '@mui/material';
import { IntegrationsSVG } from '../OverviewTab/Icons';
import { sharedClasses } from '../../Components/CustomUIElements/sharedClasses';
import { theme } from '../../../ThemeContext/ThemeObject';

export default function IntegrationsTab() {
  return (
    <Stack sx={{ alignItems: 'center', rowGap: 3, paddingTop: 5 }}>
      <IntegrationsSVG />
      <Stack sx={{ alignItems: 'center', rowGap: 0.75, color: theme.palette.common.grey }}>
        <Stack sx={{ fontSize: '19px', fontWeight: 'bold' }}>
          All your favorite integrations coming soon.
        </Stack>
        <Stack>Click the button below to be taken to the integrations section of the old</Stack>
        <Stack>{`version of this candidate's application`}</Stack>
      </Stack>
      <Button
        sx={sharedClasses.genericButtonSecondary}
        onClick={() =>
          window.open(
            `${window.location.href.replace('new_applications', 'applications')}#application-activities`,
            '_blank'
          )
        }
      >
        Go to the old version
      </Button>
    </Stack>
  );
}
