import { ApplicationAction, IFormResponseListItem } from '../types';
import { Box, IconButton, Stack, TableCell } from '@mui/material';
import { styles } from '../styles';
import { theme } from '../../../ThemeContext/ThemeObject';
import dayjs from 'dayjs';
import { getApplicationRatingColor } from '../config';
import { Delete, Edit, ExpandLess, ExpandMore } from '@mui/icons-material';
import React, { Dispatch } from 'react';

export default function RenderFormResponseActions({
  formResponse,
  dispatch,
  expandedRows,
  setExpandedRows
}: {
  formResponse: IFormResponseListItem;
  dispatch: Dispatch<ApplicationAction>;
  expandedRows: number[];
  setExpandedRows: React.Dispatch<React.SetStateAction<number[]>>;
}) {
  const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const handleExpandClick = (id: number) => {
    setExpandedRows(() =>
      expandedRows.includes(id)
        ? expandedRows.filter((rowId) => rowId !== id)
        : [...expandedRows, id]
    );
  };

  const handleDeleteFormResponse = () => {
    dispatch({
      type: 'SET_SELECTED_FORM_RESPONSE_ACTION',
      payload: { delete: formResponse, edit: null }
    });
  };

  const handleEditFormResponse = () => {
    dispatch({
      type: 'SET_SELECTED_FORM_RESPONSE_ACTION',
      payload: { delete: null, edit: formResponse }
    });
  };

  return (
    <>
      <TableCell>
        <Box>
          <Box>
            <Stack direction={'row'} alignItems={'center'} sx={styles.fileAttachedBy}>
              <Box>{formResponse.id}</Box>
            </Stack>
          </Box>
        </Box>
      </TableCell>
      <TableCell
        sx={{
          color: theme.palette.common.grey,
          fontSize: '15px',
          fontWeight: 'bold'
        }}
      >
        {formResponse.form_name}
      </TableCell>

      <TableCell sx={styles.fileAttachedBy}>
        {dayjs.tz(formResponse.updated_at, timeZoneString).format('Do MMMM YYYY[,] h:mma')}
      </TableCell>
      <TableCell
        sx={{
          color: '#000000',
          fontWeight: '600'
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box
            sx={{
              ...styles.ratingCell,
              backgroundColor: getApplicationRatingColor(
                formResponse.rating === 'N/A' ? 101 : Number(formResponse.rating)
              )
            }}
          >
            {!isNaN(parseFloat(formResponse.rating))
              ? Math.ceil(parseFloat(formResponse.rating)) + '%'
              : '-'}
          </Box>
        </Box>
      </TableCell>
      <TableCell
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end'
        }}
      >
        <IconButton onClick={() => handleEditFormResponse()} sx={styles.tableActionIcon}>
          <Edit sx={{ fontSize: 16 }} />
        </IconButton>
        <IconButton onClick={() => handleDeleteFormResponse()} sx={styles.tableActionIcon}>
          <Delete sx={{ color: theme.palette.error.main, fontSize: 16 }} />
        </IconButton>
        <Box
          sx={{ width: '30%', maxWidth: '80px', cursor: 'pointer' }}
          onClick={() => handleExpandClick(formResponse.id)}
        >
          {expandedRows.includes(formResponse.id) ? 'Show less' : 'Show more'}
        </Box>

        <IconButton sx={styles.tableActionIcon} onClick={() => handleExpandClick(formResponse.id)}>
          {expandedRows.includes(formResponse.id) ? (
            <ExpandLess sx={{ fontSize: 16 }} />
          ) : (
            <ExpandMore sx={{ fontSize: 16 }} />
          )}
        </IconButton>
      </TableCell>
    </>
  );
}
