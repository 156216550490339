import React, { Dispatch, useState } from 'react';
import { Skeleton, Stack } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { ApplicationAction, IApplication } from '../types';
import { styles } from '../styles';
import ModalFooterButtons from '../../Components/GenericModal/ModalFooterButtons';
import { UseQueryResult, useMutation } from '@tanstack/react-query';
import QuestionFieldGenerator from '../../Components/Utilities/QuestionFieldGenerator';
import Api from '../API';

export default function Questionnaire({
  hrQuestion,
  dispatch
}: {
  hrQuestion: UseQueryResult<any, unknown>;
  dispatch: Dispatch<ApplicationAction>;
}) {
  const [hrAnswer, setHrAnswer] = useState<string | number | Record<string, boolean | string>>('');
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);

  const formatSavedAnswer = () => {
    if (hrQuestion.data.type === 'CheckBoxField') {
      const answer: { [key: number]: boolean } = {};
      hrQuestion.data.field_choices.map((c: { id: number }) => {
        if (hrQuestion.data.answer.map((a: { id: number }) => a.id).includes(c.id)) {
          answer[c.id] = true;
        } else {
          answer[c.id] = false;
        }
      });
      return answer;
    } else if (
      hrQuestion.data.type === 'SelectField' ||
      hrQuestion.data.type === 'RadioButtonField'
    ) {
      return hrQuestion.data.answer[0]?.id || '';
    } else {
      return hrQuestion.data.answer || '';
    }
  };

  const formatHrAnswer = () => {
    if (hrQuestion.data.type === 'CheckBoxField') {
      if (typeof hrAnswer === 'object') {
        return {
          field_choice_ids: Object.keys(hrAnswer).map((key) => (hrAnswer[key] ? key : ''))
        };
      }
    } else if (
      hrQuestion.data.type === 'SelectField' ||
      hrQuestion.data.type === 'RadioButtonField'
    ) {
      return { field_choice_id: hrAnswer };
    } else {
      return { data: hrAnswer };
    }
  };

  const { mutate: saveHrQuestion, isLoading: savingHrQuestion } = useMutation({
    mutationFn: async () => {
      if (application) {
        const { res } = await Api.saveHrQuestion(
          application.job.id,
          application.id,
          hrQuestion.data.id,
          {
            answers_attributes: {
              0: {
                field_id: hrQuestion.data.question_id,
                ...formatHrAnswer()
              }
            }
          }
        );
        return res;
      }
    },
    onSuccess: (res) => {
      queryClient.setQueryData(['hr questions'], res.hr_answer[0]);
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `Application questionnaire has been saved successfully`,
          state: 'success'
        }
      });
    },
    onError: (error: { error: string }) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error in saving application questionnaire, ${error.error}`,
          state: 'error'
        }
      });
    }
  });

  if (!hrQuestion.data) return null;

  return (
    <Stack sx={styles.questionnaireContainer}>
      <Stack sx={{ fontSize: '16px', fontWeight: 600 }}>Application Questionnaire</Stack>
      {hrQuestion.isLoading ? (
        [...Array(3)].map((_, index) => <Skeleton key={index} height={30} animation="wave" />)
      ) : (
        <QuestionFieldGenerator
          question={hrQuestion.data}
          answer={formatSavedAnswer()}
          onChangeCallback={(id, value) => setHrAnswer(value)}
        />
      )}
      <ModalFooterButtons
        primaryButtonText="Save"
        primaryButtonCallback={saveHrQuestion}
        isLoading={savingHrQuestion}
        primaryButtonMinWidth="75px"
      />
    </Stack>
  );
}
