import React, { Dispatch } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import Api from '../API';
import { ApplicationAction, ApplicationState, IApplication, IAttachment } from '../types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advanced from 'dayjs/plugin/advancedFormat';
import {
  Box,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { Delete, Download, OpenInNew, Visibility } from '@mui/icons-material';
import { styles } from '../styles';
import NoAttachmentsSVG, { CopyLinkSVG } from '../OverviewTab/Icons';
import { DeleteAttachment } from '../Modals/DeleteAttachment';
import DocTypeIcon from '../../Components/Utilities/DocTypeIcon';
import AttachmentPreviewModal from '../Modals/AttachmentPreviewModal';
import { theme } from '../../../ThemeContext/ThemeObject';
import AttachmentSelector from '../../Components/Utilities/AttachmentSelector';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import TableSkeleton from '../../Components/Multiposter/TableSkeleton';
import { IUserPermissions } from '../../Components/sharedTypes';

const BASE_URL = window.location.origin;

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);

function AttachmentsTab({
  apikey,
  ApplicationState,
  dispatch
}: {
  apikey: string;
  ApplicationState: ApplicationState;
  dispatch: Dispatch<ApplicationAction>;
}) {
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);
  const permissions = queryClient.getQueryData<IUserPermissions>(['permissions']);
  const { data: attachments, isLoading: loadingAttachments } = useQuery({
    queryKey: ['attachments'],
    queryFn: async () => {
      if (application?.job) {
        const { res } = await Api.getApplicationAttachments(application.job.id, application.id);
        const responseAttachments = res.attachments;
        const categorizedAttachments = responseAttachments.reduce(
          (acc: { videos: IAttachment[]; files: IAttachment[] }, attachment: IAttachment) => {
            if (attachment.attached_content_type.startsWith('video')) {
              acc.videos.push(attachment);
            } else {
              acc.files.push(attachment);
            }
            return acc;
          },
          { files: [], videos: [] }
        );

        return categorizedAttachments;
      }
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting Application Attachments, ${error}`,
          state: 'error'
        }
      }),
    enabled: !!application
  });

  function AttachmentTable({
    attachmentsList,
    attachmentType
  }: {
    attachmentsList: IAttachment[];
    attachmentType: 'files' | 'video';
    //   TODO: can pass in permissions here
  }) {
    const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const handleCopy = () => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: 'Link copied to clipboard',
          state: 'success'
        }
      });
    };
    const handleOpenAttachment = (attachment: IAttachment) => {
      dispatch({
        type: 'SET_SELECTED_ATTACHMENT_ACTION',
        payload: { ...ApplicationState.selectedAttachmentAction, preview: attachment }
      });
    };

    return (
      <>
        {attachmentsList.map((attachment: IAttachment) => (
          <TableRow key={attachment.id}>
            <TableCell>
              <Box sx={styles.fileIconContainer}>
                <Box sx={styles.fileIcon} onClick={() => handleOpenAttachment(attachment)}>
                  {DocTypeIcon(attachment.attached_file_name.split('.').pop())}
                </Box>
                <Box>
                  <Stack direction={'row'} alignItems={'center'}>
                    <Box sx={styles.fileName} onClick={() => handleOpenAttachment(attachment)}>
                      {attachment.attached_file_name}
                    </Box>
                  </Stack>
                  <Box sx={styles.fileDate}>
                    {dayjs
                      .tz(attachment.created_at, timeZoneString)
                      .format('Do MMMM YYYY[,] h:mma')}
                  </Box>
                </Box>
              </Box>
            </TableCell>
            <TableCell sx={styles.fileAttachedBy}>{attachment.attached_by}</TableCell>
            <TableCell align={'right'}>
              <IconButton onClick={() => handleOpenAttachment(attachment)}>
                <Visibility sx={styles.preview} />
              </IconButton>
              <IconButton
                sx={styles.tableActionIcon}
                href={
                  attachmentType === 'video'
                    ? attachment.attached_url
                    : `${BASE_URL}/admin/assets/${attachment.id}`
                }
              >
                <Download sx={{ fontSize: 16, color: theme.palette.common.grey }} />
              </IconButton>
              {attachmentType === 'video' && (
                <>
                  <CopyToClipboard text={BASE_URL + attachment.public_url} onCopy={handleCopy}>
                    <IconButton sx={styles.tableActionIcon}>
                      <CopyLinkSVG />
                    </IconButton>
                  </CopyToClipboard>

                  <IconButton href={attachment.public_url} target="_blank" rel="noreferrer">
                    <OpenInNew sx={{ fontSize: 16, color: theme.palette.common.grey }} />
                  </IconButton>
                </>
              )}
              {permissions?.Applications?.['Download Attachments'] &&
                attachmentType === 'files' && (
                  <IconButton
                    sx={styles.tableActionIcon}
                    href={`${BASE_URL}/admin/assets/${attachment.id}`}
                  >
                    <Download sx={{ fontSize: 16, color: theme.palette.common.grey }} />
                  </IconButton>
                )}
              {permissions?.Applications['Delete Application Attachments'] && (
                <IconButton
                  sx={styles.tableActionIcon}
                  onClick={() => {
                    dispatch({
                      type: 'SET_SELECTED_ATTACHMENT_ACTION',
                      payload: { ...ApplicationState.selectedAttachmentAction, delete: attachment }
                    });
                  }}
                >
                  <Delete sx={{ color: theme.palette.error.main, fontSize: 16 }} />
                </IconButton>
              )}
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  }

  return (
    <>
      <TableContainer sx={{ boxShadow: 'none', paddingBottom: '20px' }} component={Paper}>
        <Table sx={styles.tableWrapper} aria-label="application attachments">
          {attachments && attachments.videos.length > 0 && (
            <>
              <TableHead>
                <TableRow>
                  <TableCell>Video</TableCell>
                  <TableCell>Attached by</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <AttachmentTable attachmentsList={attachments.videos} attachmentType="video" />
              </TableBody>
            </>
          )}
          {attachments && attachments.files.length > 0 && (
            <>
              <TableHead>
                <TableRow>
                  <TableCell>File</TableCell>
                  {attachments.videos.length === 0 && <TableCell>Attached by</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                <AttachmentTable attachmentsList={attachments.files} attachmentType={'files'} />
              </TableBody>
            </>
          )}
        </Table>
        {!loadingAttachments &&
          attachments?.files.length === 0 &&
          attachments?.videos.length === 0 && (
            <Box sx={{ ...styles.emptyStateContainer, margin: '24px 0px' }}>
              <NoAttachmentsSVG />
              <Box sx={styles.emptyStateText}>No attachments added</Box>
            </Box>
          )}
        {loadingAttachments && (
          <TableSkeleton size={4} borderType={'none'} height={'60px'} padding={'5px 0px'} />
        )}
      </TableContainer>
      {permissions?.Applications?.['Upload File'] && application && (
        <AttachmentSelector
          apiKey={apikey}
          performUpload={true}
          maxSize={50000000}
          uploadUrl={`${BASE_URL}/api/v4/jobs/${application.job.id}/applications/${application.id}/attachments`}
          onUploadComplete={() => {
            dispatch({
              type: 'SET_SNACKBAR',
              payload: {
                message: 'File successfully uploaded',
                state: 'success'
              }
            });
            queryClient.removeQueries(['attachments']);
          }}
          attachment={true}
          maxVideoSize={500000000}
        />
      )}
      {ApplicationState.selectedAttachmentAction.delete && (
        <DeleteAttachment
          dispatch={dispatch}
          isDialogOpen={!!ApplicationState.selectedAttachmentAction.delete}
          selectedAttachment={ApplicationState.selectedAttachmentAction.delete}
        />
      )}
      {ApplicationState.selectedAttachmentAction.preview && (
        <AttachmentPreviewModal
          previewOpen={!!ApplicationState.selectedAttachmentAction.preview}
          dispatch={dispatch}
          selectedAttachment={ApplicationState.selectedAttachmentAction.preview}
          ApplicationState={ApplicationState}
        />
      )}
    </>
  );
}

export default AttachmentsTab;
