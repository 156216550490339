"use strict";
import { theme } from "../ThemeContext/ThemeObject";
export const classes = {
  itemContainer: {
    padding: "30px",
    borderBottom: "1px solid #EAEEF0",
    "& h3": {
      fontSize: 20,
      lineHeight: "20px",
      color: theme.palette.primary.dark,
      fontWeight: 600,
      textTransform: "capitalize",
      marginBottom: "10px"
    },
    "& > span": {
      color: "#777777",
      fontSize: 15,
      marginBottom: "10px",
      display: "block",
      textTransform: "capitalize"
    },
    "&:hover": {
      background: "#F3F6F7",
      cursor: "pointer"
    }
  },
  itemDetails: {
    padding: "4px 0",
    color: "#777777",
    display: "flex",
    alignItems: "center",
    "& svg": {
      marginRight: "6px"
    }
  },
  closeIcon: {
    position: "absolute",
    top: "20px",
    right: "20px",
    color: "#DDDDDD",
    "&:hover": {
      cursor: "pointer"
    }
  },
  addNewEventsPopover: {
    padding: 2,
    rowGap: 1,
    "& div": {
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "bold",
      color: "#666666",
      cursor: "pointer",
      "&:hover": {
        color: theme.palette.primary.main
      }
    }
  },
  calendarArrows: {
    color: "#BFBFBF",
    fontSize: "27px",
    opacity: "0.7",
    "&:hover": {
      cursor: "pointer",
      opacity: "0.4"
    }
  },
  calendarHeader: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#084D6D",
    cursor: "pointer"
  },
  viewAndSettingsButton: {
    minWidth: "unset",
    border: "1px solid #EAEEF0",
    padding: "6px 12px"
  },
  viewAndSettingsContainer: {
    width: "150px",
    flexDirection: "row",
    justifyContent: "flex-end",
    columnGap: 1
  },
  weekPeriodContainer: {
    flexDirection: "row",
    justifyContent: "center",
    columnGap: 2,
    paddingBottom: 3,
    alignItems: "center"
  },
  eventCreatedModalContainer: {
    padding: 3,
    width: "428px",
    alignItems: "center",
    rowGap: 2,
    "& .MuiTypography-root": { fontFamily: "Source Sans Pro" }
  },
  eventCreatedModalTitle: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#666666"
  },
  modalTitle: {
    fontSize: "25px",
    color: "#084D6D",
    fontWeight: "bold",
    paddingBottom: 6,
    paddingTop: 1,
    lineHeight: "normal"
  },
  listViewCircleIcon: {
    padding: "5px",
    color: "#084D6D",
    background: "#D6DEE2",
    borderRadius: "50%",
    fontSize: "1.3rem"
  },
  listViewSectionTitle: {
    color: "#939393",
    fontSize: "16px",
    paddingBottom: 0.5
  },
  listViewInfoTag: {
    background: "#F3F3F3",
    padding: "4px 12px",
    borderRadius: "1rem",
    flexDirection: "row",
    color: "#666666",
    columnGap: 0.5,
    fontSize: "12px"
  },
  listViewNoCandidatesAssigned: {
    height: "100%",
    justifyContent: "center",
    color: "#939393",
    fontSize: "14px"
  },
  eventSessionsContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    columnGap: 2
  },
  listViewEventTime: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#666666",
    cursor: "pointer"
  },
  listViewEventTitle: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#333333",
    cursor: "pointer"
  },
  dateCalendar: {
    ".Mui-selected": {
      backgroundColor: "#5BC4C0 !important"
    },
    height: "300px"
  }
};
