import React, { Dispatch, useState, useRef } from 'react';
import { Autocomplete, Stack, TextField } from '@mui/material';
import StyledModal from '../../Components/GenericModal/StyledModal';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../API';
import { ApplicationAction, ApplicationState, IApplication } from '../types';
import { ModalType } from '../config';
import ModalFooterButtons from '../../Components/GenericModal/ModalFooterButtons';
import { styles } from '../styles';
import EmailsDropdown from './Components/EmailsDropdown';
import EmailBodyComponent from './Components/EmailBodyComponent';
import { useGetEmailTemplate, useGetEvents } from '../helper';
import { FormTextField } from '../../Components/CustomUIElements/FormTextField';
import { IEvent } from '../../Job/types';
import { sharedClasses } from '../../Components/CustomUIElements/sharedClasses';
import { IEventType } from '../../../ScoutCalendar/types';
import AttachmentSelectorAndPreview from '../../Components/CustomUIElements/AttachmentSelectorAndPreview';
import { FileWithPath } from 'react-dropzone';
import { stringToSeconds } from '../../Components/Utilities/stringToSeconds';
import { serialize } from 'object-to-formdata';
import { theme } from '../../../ThemeContext/ThemeObject';
import { ITemplateAttachment } from '../types';
import DelaySelect from '../../EditJob/Automation/shared/DelaySelect';
import { scrollToElement } from '../../utils/scroll-to-element';

export default function SendEmail({
  ApplicationState,
  dispatch
}: {
  ApplicationState: ApplicationState;
  dispatch: Dispatch<ApplicationAction>;
}) {
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);
  const [delay, setDelay] = useState('None');
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState<string | null>(null);
  const [selectedEvent, setSelectedEvent] = useState<IEvent | null>(null);
  const [selectedCareAdvantageEvent, setSelectedCareAdvantageEvent] = useState<IEventType | null>(
    null
  );
  const [subject, setSubject] = useState<string>('');
  const [body, setBody] = useState<string>('');
  const [attachments, setAttachments] = useState<FileWithPath[]>([]);
  const [templateAttachments, setTemplateAttachments] = useState<ITemplateAttachment[]>([]);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const { modalsOpen, apiKey, emailPlaceholders } = ApplicationState;

  const fieldRefs = {
    subject: useRef<HTMLInputElement>(null),
    body: useRef<HTMLDivElement>(null)
  };

  useGetEmailTemplate({
    apiKey,
    selectedEmailTemplate,
    setBody,
    dispatch,
    setSubject,
    setTemplateAttachments
  });
  const { events, loadingEvents } = useGetEvents({ apiKey, dispatch });

  const { data: careAdvantageEvents } = useQuery({
    queryKey: ['care advantage events'],
    queryFn: async () => {
      if (application) {
        const { res } = await Api.getCareAdvantageEvents(application?.job.id);
        return res;
      }
    },
    onError: () =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: 'Failed to fetch care advantage events data', state: 'error' }
      })
  });

  const { mutate: sendEmail, isLoading: sendingEmail } = useMutation({
    mutationFn: async () => {
      if (application) {
        const data = {
          applications_ids: [application.id],
          email_template_id: selectedEmailTemplate?.split(' -')[0],
          event_id: selectedEvent ? selectedEvent.id : undefined,
          subject,
          body: body,
          delay: stringToSeconds(delay),
          care_advantage_event_id: selectedCareAdvantageEvent ? selectedCareAdvantageEvent.id : '',
          ...(attachments.length > 0 && { new_email_attachments: attachments })
        };

        const res = await fetch(`/api/v4/jobs/${application.job.id}/applications/bulk_email`, {
          method: 'POST',
          headers: {
            'X-api-authenticate': apiKey
          },
          body: serialize({ email_batch: data })
        });
        return res;
      }
    },
    onSuccess: () => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: 'Email has been sent', state: 'success' }
      });
      handleClose();
    },
    onError: () =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: `Failed to send email`, state: 'error' }
      })
  });

  const handleClose = () => dispatch({ type: 'SET_MODALS_OPEN', payload: null });

  const validateFields = () => {
    const fieldsErrors: Record<string, string> = {};
    !subject.trim() && (fieldsErrors.subject = 'Must add email subject');
    !body.trim() && (fieldsErrors.body = 'Must add email body');
    setErrors(fieldsErrors);
    if (fieldsErrors.subject) {
      scrollToElement(fieldRefs.subject);
    } else if (fieldsErrors.body) {
      scrollToElement(fieldRefs.body);
    }
    return !Object.keys(fieldsErrors).length;
  };

  return (
    <StyledModal
      isOpen={modalsOpen === ModalType.SEND_EMAIL}
      label="Send email modal"
      handleClose={handleClose}
      styleOverrides={{ ...styles.modalStyleOverrides, height: '700px' }}
    >
      <Stack sx={styles.modalContainer}>
        <Stack sx={styles.modalTitle}>Send email</Stack>
        <Stack
          sx={{
            overflow: 'auto',
            rowGap: 2,
            '&::-webkit-scrollbar': {
              display: 'none'
            },
            padding: '0px 2px'
          }}
        >
          <Stack sx={{ paddingTop: 4, rowGap: 3 }}>
            <EmailsDropdown
              apiKey={apiKey}
              selectedEmailTemplate={selectedEmailTemplate}
              setSelectedEmailTemplate={setSelectedEmailTemplate}
              setBody={setBody}
              dispatch={dispatch}
              dropdownLabel="Template"
            />
            <DelaySelect delay={delay} setDelay={setDelay} />
            <Autocomplete
              disablePortal
              options={events || []}
              getOptionLabel={(option) => option.subject}
              value={selectedEvent}
              sx={{ ...sharedClasses.formAutocomplete, width: '100%', marginTop: 3, flexGrow: 1 }}
              loading={loadingEvents}
              loadingText="Loading events list..."
              ListboxProps={{ style: sharedClasses.autoCompleteListStyles }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Event"
                  placeholder="Please select"
                  InputLabelProps={{ shrink: true }}
                  sx={{ '& .MuiInputBase-root': { margin: 'unset', marginTop: 0.5 } }}
                />
              )}
              onChange={(event, value) => setSelectedEvent(value)}
            />
            {careAdvantageEvents?.has_care_advantage_integration && (
              <Autocomplete
                disablePortal
                options={careAdvantageEvents?.care_advantage_events || []}
                getOptionLabel={(option) => option.name}
                value={selectedCareAdvantageEvent}
                sx={{ ...sharedClasses.formAutocomplete, width: '100%', marginTop: 3, flexGrow: 1 }}
                ListboxProps={{ style: sharedClasses.autoCompleteListStyles }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select a care advantage event"
                    placeholder="Please select"
                    InputLabelProps={{ shrink: true }}
                    sx={{ '& .MuiInputBase-root': { margin: 'unset', marginTop: 0.5 } }}
                  />
                )}
                onChange={(event, value) => setSelectedCareAdvantageEvent(value)}
              />
            )}
            <Stack>
              <FormTextField
                label="Subject"
                value={subject}
                required
                onChange={(v) => setSubject(v.target.value)}
                error={errors.subject}
                innerRef={fieldRefs.subject}
              />
              <Stack
                sx={{ paddingLeft: 0.5, color: '#838383', fontSize: '11px', lineBreak: 'anywhere' }}
              >{`Available placeholders are: ${emailPlaceholders}`}</Stack>
            </Stack>
            <EmailBodyComponent
              body={body}
              setBody={setBody}
              placeholders={emailPlaceholders}
              error={errors.body}
              innerRef={fieldRefs.body}
            />
            <Stack sx={{ rowGap: 1, div: { margin: 'unset' } }}>
              <Stack sx={{ color: theme.palette.common.grey }}>Attachments</Stack>
              <AttachmentSelectorAndPreview
                attachments={attachments}
                setAttachments={setAttachments}
                templateAttachments={templateAttachments}
              />
            </Stack>
          </Stack>
        </Stack>
        <ModalFooterButtons
          primaryButtonText="Send"
          primaryButtonCallback={() => validateFields() && sendEmail()}
          secondaryButtonText="Cancel"
          secondaryButtonCallback={handleClose}
          isLoading={sendingEmail}
          primaryButtonMinWidth="75px"
        />
      </Stack>
    </StyledModal>
  );
}
