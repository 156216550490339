import React, { ChangeEvent, CSSProperties, Dispatch, FocusEventHandler, RefObject } from 'react';
import {
  alpha,
  Box,
  FormControl,
  FormHelperText,
  InputBase,
  InputLabel,
  Stack,
  styled
} from '@mui/material';
import { theme } from '../../../ThemeContext/ThemeObject';
import { sharedClasses } from './sharedClasses';
import DebounceInput from '../Utilities/DebounceInput';

type IStyle = {
  [className: string]: CSSProperties | IStyle;
};

interface IFormTextFieldProps {
  label?: string;
  value: string | number;
  onChange: Dispatch<ChangeEvent<HTMLInputElement>>;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  required?: boolean;
  error?: string;
  id?: string;
  innerRef?: RefObject<HTMLInputElement> | ((el: HTMLDivElement) => void);
  styles?: CSSProperties | IStyle;
  draggable?: boolean;
  helperText?: string;
  fullWidth?: boolean;
  dataTestId?: string;
  maxLength?: number;
  placeholder?: string;
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  noWrapError?: boolean;
}

interface IFormNumberFieldProps extends IFormTextFieldProps {
  unit?: string;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
}

interface IMultilineTextFieldProps extends IFormTextFieldProps {
  rows?: number;
  placeholder?: string;
}

interface IDebouncedMultilineTextFieldProps extends IDebouncedFormTextFieldProps {
  rows?: number;
}

interface IDebouncedFormTextFieldProps {
  label?: string;
  onDebouncedChange: (value: string) => void;
  onBlur?: (value: string) => void;
  debounceTime?: number;
  defaultValue?: string;
  required?: boolean;
  error?: string;
  id?: string;
  innerRef?: RefObject<HTMLInputElement> | ((el: HTMLDivElement) => void);
  styles?: CSSProperties | IStyle;
  draggable?: boolean;
  helperText?: string;
  fullWidth?: boolean;
  dataTestId?: string;
  placeholder?: string;
  unit?: string;
}

interface IDebouncedFormNumberField
  extends Omit<IDebouncedFormTextFieldProps, 'onDebouncedChange' | 'defaultValue'> {
  onDebouncedChange: (value: number) => void;
  defaultValue?: number;
}

const TextInput = styled(InputBase)(() => ({
  'label + &': {
    marginTop: '24px'
  },
  '& .MuiInputBase-input': {
    borderRadius: '6px',
    position: 'relative',
    backgroundColor: '#FFFFFF',
    color: '#333333',
    border: '1px solid #DDDDDD',
    fontSize: '16px',
    padding: '6px 10px',
    margin: '3px 0',
    fontFamily: 'Source Sans Pro, sans-serif',
    transition: 'all 0.16s ease-in-out',
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main
    }
  }
}));

export function FormTextField({
  label,
  value,
  onChange,
  onBlur,
  required,
  error,
  id,
  innerRef,
  styles,
  draggable,
  helperText,
  fullWidth = false,
  dataTestId,
  maxLength,
  placeholder,
  onFocus,
  noWrapError
}: IFormTextFieldProps) {
  return (
    <FormControl variant="standard" sx={{ width: fullWidth ? '100%' : undefined }}>
      {label && (
        <InputLabel shrink htmlFor="text-input" sx={sharedClasses.inputLabel}>
          {label}
          {required && <span>*</span>}
        </InputLabel>
      )}
      <TextInput
        inputProps={{
          'data-testid': dataTestId,
          maxLength: maxLength
        }}
        disabled={draggable}
        sx={styles}
        value={value}
        id={id}
        onChange={onChange}
        onBlur={onBlur}
        inputRef={innerRef}
        placeholder={placeholder}
        onFocus={onFocus}
      />
      {error && (
        <Box sx={{ ...sharedClasses.errorBox, textWrap: noWrapError ? 'nowrap' : 'wrap' }}>
          {error}
        </Box>
      )}
      {helperText && <FormHelperText sx={{ marginLeft: '4px' }}>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export function FormNumberField({
  label,
  value,
  onChange,
  required,
  error,
  id,
  innerRef,
  styles,
  unit,
  fullWidth,
  onKeyDown
}: IFormNumberFieldProps) {
  return (
    <FormControl variant="standard" sx={{ width: '100%' }}>
      {label && (
        <InputLabel shrink htmlFor="text-input" sx={sharedClasses.inputLabel}>
          {label}
          {required && <span>*</span>}
        </InputLabel>
      )}
      <Stack
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: 3,
          columnGap: 1,
          color: '#838383'
        }}
      >
        <TextInput
          type="number"
          sx={styles}
          value={value}
          id={id}
          onChange={onChange}
          inputRef={innerRef}
          fullWidth={fullWidth}
          onKeyDown={onKeyDown}
        />
        {unit}
      </Stack>
      {error && <Box sx={sharedClasses.errorBox}>{error}</Box>}
    </FormControl>
  );
}

export function FormTextFieldBlur({
  label,
  value,
  onChange,
  required,
  onBlur,
  error,
  id,
  innerRef,
  styles
}: IFormTextFieldProps) {
  return (
    <FormControl variant="standard">
      {label && (
        <InputLabel shrink htmlFor="text-input" sx={sharedClasses.inputLabel}>
          {label}
          {required && <span>*</span>}
        </InputLabel>
      )}
      <TextInput
        sx={styles}
        value={value}
        onBlur={onBlur}
        id={id}
        onChange={onChange}
        inputRef={innerRef}
      />
      {error && <Box sx={sharedClasses.errorBox}>{error}</Box>}
    </FormControl>
  );
}

export function DebouncedFormTextField({
  label,
  defaultValue,
  onDebouncedChange,
  required,
  error,
  debounceTime,
  id,
  innerRef,
  styles,
  helperText,
  fullWidth = false,
  dataTestId
}: IDebouncedFormTextFieldProps) {
  return (
    <DebounceInput
      defaultValue={defaultValue}
      onDebouncedChange={onDebouncedChange}
      debounceTime={debounceTime}
    >
      {({ value, onChange }) => (
        <FormTextField
          dataTestId={dataTestId}
          label={label}
          value={value}
          onChange={onChange}
          required={required}
          error={error}
          id={id}
          innerRef={innerRef}
          styles={styles}
          helperText={helperText}
          fullWidth={fullWidth}
        />
      )}
    </DebounceInput>
  );
}

export function DebouncedFormNumberField({
  label,
  defaultValue,
  onDebouncedChange,
  required,
  error,
  debounceTime,
  id,
  innerRef,
  styles,
  dataTestId,
  unit,
  fullWidth
}: IDebouncedFormNumberField) {
  return (
    <DebounceInput
      defaultValue={defaultValue}
      onDebouncedChange={onDebouncedChange}
      debounceTime={debounceTime}
    >
      {({ value, onChange }) => (
        <FormNumberField
          dataTestId={dataTestId}
          label={label}
          value={value as number}
          onChange={onChange}
          required={required}
          error={error}
          id={id}
          innerRef={innerRef}
          styles={styles}
          unit={unit}
          fullWidth={fullWidth}
        />
      )}
    </DebounceInput>
  );
}

export function DebouncedFormTextFieldBlur({
  label,
  defaultValue,
  onDebouncedChange,
  onBlur,
  required,
  error,
  debounceTime,
  id,
  innerRef,
  styles,
  dataTestId
}: IDebouncedFormTextFieldProps) {
  return (
    <DebounceInput
      defaultValue={defaultValue}
      onDebouncedChange={onDebouncedChange}
      debounceTime={debounceTime}
    >
      {({ value, onChange }) => (
        <FormTextFieldBlur
          label={label}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          required={required}
          error={error}
          id={id}
          dataTestId={dataTestId}
          innerRef={innerRef}
          styles={styles}
        />
      )}
    </DebounceInput>
  );
}

export function MultilineFormTextField({
  label,
  value,
  onChange,
  required,
  error,
  id,
  innerRef,
  styles,
  rows,
  helperText,
  draggable = false,
  dataTestId,
  fullWidth = false,
  placeholder,
  maxLength
}: IMultilineTextFieldProps) {
  return (
    <FormControl variant="standard" sx={{ width: fullWidth ? '100%' : undefined }}>
      {label && (
        <InputLabel shrink htmlFor="text-input" sx={sharedClasses.inputLabel}>
          {label}
          {required && <span>*</span>}
        </InputLabel>
      )}
      <TextInput
        id={id}
        inputProps={{
          'data-testid': dataTestId,
          maxLength: maxLength
        }}
        multiline
        rows={rows || 6}
        required={required}
        fullWidth
        value={value}
        inputRef={innerRef}
        onChange={onChange}
        sx={styles}
        disabled={draggable}
        placeholder={placeholder}
      />
      {error && <Box sx={sharedClasses.errorBox}>{error}</Box>}
      {helperText && <FormHelperText sx={{ marginLeft: '4px' }}>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export function MultilineDebouncedFormTextField({
  label,
  defaultValue,
  onDebouncedChange,
  debounceTime,
  required,
  error,
  id,
  innerRef,
  styles,
  rows,
  fullWidth,
  placeholder
}: IDebouncedMultilineTextFieldProps) {
  return (
    <DebounceInput
      defaultValue={defaultValue}
      onDebouncedChange={onDebouncedChange}
      debounceTime={debounceTime}
    >
      {({ value, onChange }) => (
        <MultilineFormTextField
          label={label}
          value={value}
          onChange={onChange}
          required={required}
          error={error}
          id={id}
          innerRef={innerRef}
          styles={styles}
          rows={rows}
          fullWidth={fullWidth}
          placeholder={placeholder}
        />
      )}
    </DebounceInput>
  );
}
