import { ApplicationAction, ApplicationState, ITimestamp } from '../../types';
import React, { Dispatch, SetStateAction } from 'react';
import { Box, CircularProgress, Grid, IconButton, Stack } from '@mui/material';
import { FormTextField } from '../../../Components/CustomUIElements/FormTextField';
import { styles } from '../../styles';
import {
  Close as CloseIcon,
  Delete as DeleteIcon,
  Done as DoneIcon,
  Edit as EditIcon
} from '@mui/icons-material';
import { theme } from '../../../../ThemeContext/ThemeObject';
import { timeStringToSeconds } from '../../config';

export const TimestampTableRow = ({
  timestamp,
  updateTimestamp,
  updatingTimestamp,
  deleteTimestamp,
  videoRef,
  isTimestampValid,
  dispatch,
  ApplicationState,
  setNewTimestamp,
  newTimestamp
}: {
  timestamp: ITimestamp;
  updateTimestamp: () => void;
  updatingTimestamp: boolean;
  deleteTimestamp: (id: number) => void;
  videoRef: React.RefObject<HTMLVideoElement>;
  isTimestampValid: (timestamp: ITimestamp) => boolean;
  dispatch: React.Dispatch<ApplicationAction>;
  ApplicationState: ApplicationState;
  setNewTimestamp: Dispatch<SetStateAction<ITimestamp>>;
  newTimestamp: ITimestamp;
}) => {
  const { timestampError } = ApplicationState.timeStampState;
  const isEditingCurrentTimestamp = newTimestamp.id === timestamp.id;
  const hasError = timestampError.id === timestamp.id;
  const clearTimestampError = () => {
    dispatch({
      type: 'SET_TIMESTAMP_STATE',
      payload: {
        name: 'timestampError',
        value: {
          nameMessage: '',
          valueMessage: '',
          id: null
        }
      }
    });
  };
  const handleEditClick = (timestamp: ITimestamp) => {
    setNewTimestamp(timestamp);
    if (!hasError) {
      clearTimestampError();
    }
    dispatch({ type: 'SET_TIMESTAMP_STATE', payload: { name: 'hasTyped', value: false } });
  };
  const seekToTimestamp = (time: string) => {
    if (videoRef.current) {
      videoRef.current.currentTime = timeStringToSeconds(time);
      videoRef.current.play();
    }
  };

  const renderNameField = () => (
    <FormTextField
      value={newTimestamp.name}
      onChange={(e) => setNewTimestamp({ ...newTimestamp, name: e.target.value })}
      styles={styles.timeStampInput}
      placeholder={`${timestamp.name}`}
      fullWidth={true}
      error={hasError ? timestampError.nameMessage : ''}
    />
  );

  const renderValueField = () => (
    <FormTextField
      value={newTimestamp.value}
      onChange={(e) => setNewTimestamp({ ...newTimestamp, value: e.target.value })}
      styles={styles.timeStampInput}
      placeholder={`${timestamp.value}`}
      fullWidth={true}
      error={hasError ? timestampError.valueMessage : ''}
      noWrapError={true}
    />
  );

  const renderActionButtons = () => (
    <Stack direction={'row'}>
      <IconButton
        sx={styles.tableActionIcon}
        onClick={() => {
          if (isTimestampValid(newTimestamp)) {
            clearTimestampError();
            updateTimestamp();
          }
        }}
      >
        <DoneIcon sx={{ color: '#5BC4C0', cursor: 'pointer', fontSize: '15px' }} />
      </IconButton>
      <IconButton
        onClick={() => {
          setNewTimestamp({ id: null, name: '', value: '' });
          if (hasError) clearTimestampError();
        }}
      >
        <CloseIcon focusable={false} sx={{ ...styles.clickableGreyIcon, fontSize: '15px' }} />
      </IconButton>
    </Stack>
  );

  const renderDefaultControls = () => (
    <Stack direction={'row'}>
      <IconButton sx={styles.tableActionIcon} onClick={() => handleEditClick(timestamp)}>
        <EditIcon sx={{ color: theme.palette.common.grey, fontSize: '15px' }} />
      </IconButton>
      <IconButton
        sx={styles.tableActionIcon}
        onClick={() => timestamp.id && deleteTimestamp(timestamp.id)}
      >
        <DeleteIcon sx={{ color: theme.palette.error.main, fontSize: '15px' }} />
      </IconButton>
    </Stack>
  );

  const renderItem = (field: keyof Pick<ITimestamp, 'name' | 'value'>) => {
    const fieldValue = timestamp[field];
    return (
      <Box onClick={() => seekToTimestamp(timestamp.value)} sx={styles.timeStampItem}>
        {fieldValue}
      </Box>
    );
  };

  return (
    <Grid container spacing={2} key={timestamp.id} columns={12}>
      <Grid item xs={5} alignContent={'baseline'}>
        {isEditingCurrentTimestamp ? renderNameField() : renderItem('name')}
      </Grid>
      <Grid item xs={2}>
        {isEditingCurrentTimestamp ? renderValueField() : renderItem('value')}
      </Grid>
      <Grid item xs={5}>
        {isEditingCurrentTimestamp || hasError ? (
          updatingTimestamp ? (
            <CircularProgress size={15} />
          ) : (
            renderActionButtons()
          )
        ) : (
          renderDefaultControls()
        )}
      </Grid>
    </Grid>
  );
};
