import React, { useRef, useState } from 'react';
import {
  ApplicationAction,
  ApplicationState,
  IApplication,
  IAttachment,
  ITimestamp
} from '../../types';
import { useQueryClient } from '@tanstack/react-query';
import { Box, Stack } from '@mui/material';
import TableSkeleton from '../../../Components/Multiposter/TableSkeleton';
import { TIMESTAMP_REGEX } from '../../../constants';
import { useCurrentTime, useTimestampData } from '../../helper';
import { TimestampTableForm } from './TimestampTableForm';
import { timeStringToSeconds } from '../../config';
import { TimestampTableRow } from './TimestampTableRow';

const TimestampTable = ({
  selectedAttachment,
  dispatch,
  videoRef,
  ApplicationState
}: {
  selectedAttachment: IAttachment;
  dispatch: React.Dispatch<ApplicationAction>;
  videoRef: React.RefObject<HTMLVideoElement>;
  ApplicationState: ApplicationState;
}) => {
  const [newTimestamp, setNewTimestamp] = useState<ITimestamp>({
    id: null,
    name: '',
    value: ''
  });
  const [currentTime, setCurrentTime] = useState<string>('00:00:00');
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);
  const listRef = useRef<HTMLDivElement>(null);

  const isTimestampValid = (timestamp: ITimestamp): boolean => {
    let isValid = true;
    const errorMessages = {
      nameMessage: '',
      valueMessage: ''
    };

    if (!timestamp.value.match(TIMESTAMP_REGEX)) {
      errorMessages.valueMessage = 'Invalid timestamp format - must be HH:MM:SS';
      isValid = false;
    } else if (
      videoRef.current &&
      videoRef.current.duration < timeStringToSeconds(timestamp.value)
    ) {
      errorMessages.valueMessage = 'Timestamp is longer than video';
      isValid = false;
    }
    if (timestamp.name.length === 0) {
      errorMessages.nameMessage = 'Timestamp name cannot be empty';
      isValid = false;
    }

    if (errorMessages.nameMessage || errorMessages.valueMessage) {
      dispatch({
        type: 'SET_TIMESTAMP_STATE',
        payload: {
          name: 'timestampError',
          value: {
            ...ApplicationState.timeStampState.timestampError,
            ...errorMessages,
            id: timestamp.id
          }
        }
      });
    }
    return isValid;
  };

  const {
    timestampData,
    loadingTimestampData,
    updateTimestamp,
    updatingTimestamp,
    addTimestamp,
    deleteTimestamp
  } = useTimestampData({
    selectedAttachment,
    dispatch,
    listRef,
    application,
    newTimestamp,
    setNewTimestamp
  });
  useCurrentTime(videoRef, setCurrentTime);

  return (
    <>
      <Stack
        ref={listRef}
        maxHeight={150}
        overflow={'scroll'}
        gap={1}
        sx={{ paddingBottom: '20px' }}
      >
        {loadingTimestampData ? (
          <TableSkeleton size={3} borderType={'none'} padding={'5px 0px'} />
        ) : (
          timestampData?.timestamps.map((timestamp) => (
            <TimestampTableRow
              key={timestamp.id}
              timestamp={timestamp}
              updateTimestamp={updateTimestamp}
              updatingTimestamp={updatingTimestamp}
              deleteTimestamp={deleteTimestamp}
              videoRef={videoRef}
              dispatch={dispatch}
              isTimestampValid={isTimestampValid}
              ApplicationState={ApplicationState}
              newTimestamp={newTimestamp}
              setNewTimestamp={setNewTimestamp}
            />
          ))
        )}
        <Box sx={{ height: '0px' }}>&nbsp;</Box>
      </Stack>

      <TimestampTableForm
        videoRef={videoRef}
        ApplicationState={ApplicationState}
        isTimestampValid={isTimestampValid}
        addTimestamp={addTimestamp}
        dispatch={dispatch}
        newTimestamp={newTimestamp}
        setNewTimestamp={setNewTimestamp}
        currentTime={currentTime}
      />
    </>
  );
};

export default TimestampTable;
