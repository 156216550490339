import React, { useState, useCallback, useEffect } from 'react';
import {
  Box,
  ClickAwayListener,
  Divider,
  Fade,
  Popper,
  CircularProgress,
  Tooltip
} from '@mui/material';
import FeaturedPlayListOutlinedIcon from '@mui/icons-material/FeaturedPlayListOutlined';
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import { classes } from '../../styles';
import { IUserInfo } from '../../Header';
import { handleAPIRequest } from '../../../../Components/Utilities/handleAPIRequest';
import { getPermissions } from '../../../../../shared/permissionHelpers';
import { formatUserPermissions } from '../../../../Components/Utilities/formatUserPermissions';
import Alert from '@mui/material/Alert';

const ProfileDropdownPopper = ({
  menuOpen,
  setMenuOpen,
  setChangingEntity,
  avatarAnchorEl,
  userInfo
}: {
  menuOpen: boolean;
  setMenuOpen: (menuOpen: boolean) => void;
  setChangingEntity: (changingEntity: boolean) => void;
  avatarAnchorEl: HTMLElement | null;
  userInfo: IUserInfo;
}) => {
  const [loadingLogout, setLoadingLogout] = useState(false);
  const [masqueradeIconHovered, setMasqueradeIconHovered] = useState(false);
  const [userPermissions, setUserPermissions] = useState<Record<string, Record<string, boolean>>>(
    {}
    );

  const [alertState, setAlertState] = useState({
    message: '',
    state: 'success' as 'success' | 'error' | 'warning'
  });
  
  const sourcePermission = userPermissions['Accounts - User']?.['Edit Source'] ? true : false;

  const profileMenuItems = [
    sourcePermission && {
      label: 'Sources',
      icon: <FeaturedPlayListOutlinedIcon />,
      path: userInfo.links.sourceURL
    },
    {
      label: 'Support Centre',
      icon: <LiveHelpOutlinedIcon />,
      path: 'https://scouttalent.my.site.com/myresourcecentre/s/'
    },
    {
      label: 'Settings',
      icon: <SettingsOutlinedIcon />,
      path: '/admin/settings'
    },
    {
      label: 'Log out',
      icon: !loadingLogout ? <LogoutOutlinedIcon /> : <CircularProgress size={24} />,
      path: '#'
    }
  ];

  const getUserPermissions = useCallback(async () => {
    try {
      const data = await getPermissions();
      setUserPermissions(formatUserPermissions(data));
    } catch (error) {
      console.log(error);
      setAlertState({
        message: 'There was an error retrieving user permissions',
        state: 'error'
      });
    }
  }, [setUserPermissions]);

  useEffect(() => {
    getUserPermissions();
  }, [getUserPermissions]);

  const logOut = () => {
    handleAPIRequest({
      url: '/api/v4/logout',
      method: 'DELETE',
      setLoaderState: setLoadingLogout,
      onSuccess: (data) => {
        window.location.href = data?.redirect_url;
        localStorage.removeItem('userPermissions');
        localStorage.removeItem('userPermissionsExpiry');
      }
    });
  };

  const unmasquerade = () => {
    window.location.href = userInfo?.links.unmasqueradeURL;
    localStorage.removeItem('userPermissions');
    localStorage.removeItem('userPermissionsExpiry');
  };

  const openChangingEntity = () => {
    setChangingEntity(true);
    setMenuOpen(false);
  };

  const showAvatar = userInfo?.links.unmasqueradeURL ? (
    <Tooltip title="Unmasquerade" placement="top">
      <Box
        sx={{
          ...classes.profileAvatar,
          ...classes.avatarSmall,
          ...classes.masqueradeAvatar
        }}
        onClick={unmasquerade}
        onMouseEnter={() => setMasqueradeIconHovered(true)}
        onMouseLeave={() => setMasqueradeIconHovered(false)}
      >
        {masqueradeIconHovered ? <VisibilityOffIcon /> : <VisibilityIcon />}
      </Box>
    </Tooltip>
  ) : (
    <Box sx={{ ...classes.profileAvatar, ...classes.avatarSmall }}>
      {userInfo?.firstName[0] + userInfo?.lastName[0]}
    </Box>
  );

  return (
    <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
      <Popper
        open={menuOpen}
        anchorEl={avatarAnchorEl}
        placement="bottom-end"
        transition
        sx={{ zIndex: 1400 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box sx={classes.profileDropdownMenuContainer}>
              <Box sx={classes.profileInformation}>
                {showAvatar}
                <Box sx={classes.profileNameAndEntityContainer}>
                  <Box
                    sx={classes.profileName}
                  >{`${userInfo?.firstName} ${userInfo?.lastName}`}</Box>
                  {userInfo?.entity && (
                    <a href={userInfo?.entity.url}>
                      <Box>{userInfo?.entity.name}</Box>
                    </a>
                  )}
                  {userInfo?.entity?.multiEntitiesAccess && (
                    <SwitchAccountIcon
                      onClick={openChangingEntity}
                    />
                  )}
                </Box>
              </Box>
              <Divider variant="fullWidth" />
              <Box sx={classes.profileDropdownMenuItemsContainer}>
              {alertState?.state === 'error' && (
                <Alert severity={alertState.state}>{alertState.message}</Alert>
              )}
                {profileMenuItems.filter(item => item).map((item, index) => (
                  <a href={item.path} key={index} onClick={item.path === '#' ? logOut : undefined}>
                    <Box sx={classes.profileDropdownMenuItem}>
                      {item.icon}
                      {item.label}
                    </Box>
                  </a>
                ))}
              </Box>
            </Box>
          </Fade>
        )}
      </Popper>
    </ClickAwayListener>
  );
};

export default ProfileDropdownPopper;
