import React, { Dispatch, useState } from 'react';
import { Autocomplete, Chip, Stack, TextField } from '@mui/material';
import { sharedClasses } from '../Components/CustomUIElements/sharedClasses';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from './API';
import { TCandidate } from '../Candidates/types';
import { CandidateAction } from './types';
import { ApplicationAction } from '../Application/types';
import { IApplication } from '../Application/types';
import { styles } from './styles';

export default function CandidateTags({
  candidate,
  dispatch
}: {
  candidate: TCandidate;
  dispatch: Dispatch<CandidateAction> | Dispatch<ApplicationAction>;
}) {
  const [tags, setTags] = useState<string[]>(candidate.tag_list);
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);

  const { mutate: updateCandidateTags } = useMutation({
    mutationFn: async (value: string[]) => {
      const { res } = await Api.updateCandidateTags(candidate.id, value);
      return res;
    },
    onSuccess: (res) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: `Candidate tags have been successfully updated`, state: 'success' }
      });
      // updating candidate data when the component is called from the individual candidate page
      queryClient.setQueryData(['candidate'], res);
      queryClient.invalidateQueries(['candidate'], { exact: true });
      // updating application candidate data when the component is called from the individual application page
      queryClient.setQueryData(['application'], { ...application, candidate: res });
      queryClient.invalidateQueries(['application'], { exact: true });
    },
    onError: (error: { error: string }) => {
      setTags(candidate.tag_list);
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error in updating candidate tags, ${error.error}`,
          state: 'error'
        }
      });
    }
  });

  return (
    <Stack sx={{ rowGap: 1 }}>
      <Stack sx={{ ...styles.candidateSectionHeader, paddingLeft: 0.5 }}>
        {application && 'Candidate '}Tags
      </Stack>
      <Autocomplete
        id="candidate-tags-input"
        clearIcon={false}
        options={[]}
        value={tags}
        onChange={(e, value) => {
          setTags(value);
          updateCandidateTags(value);
        }}
        freeSolo
        multiple
        sx={{ ...sharedClasses.formAutocomplete, '.MuiInputBase-root': { margin: 'unset' } }}
        renderTags={(value, props) =>
          value.map((option, index) => <Chip label={option} {...props({ index })} key={index} />)
        }
        renderInput={(params) => (
          <TextField
            placeholder="Type your tag here..."
            {...params}
            sx={{ '& .MuiOutlinedInput-root': { padding: 0.5 } }}
          />
        )}
      />
    </Stack>
  );
}
