import React, { Dispatch, useEffect, useReducer } from 'react';
import { Skeleton, Stack, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { CalendarMonth as CalendarMonthIcon } from '@mui/icons-material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ApplicationAction, IApplication, ApplicationState } from '../types';
import Api from '../API';
import Tasks from './Tasks';
import { classes as tableStyles } from '../../../reports/SystemReports/styles';
import dayjs from 'dayjs';
import { ScoutCalendarReducer, InitialScoutCalendarState } from '../../../ScoutCalendar/reducer';
import CreateEvent from '../../../ScoutCalendar/CreateEvent/CreateEvent';
import ViewEvent from '../../../ScoutCalendar/ViewEvent';
import { IEvent } from 'app/javascript/components/ScoutCalendar/types';
import StyledSnackbar from '../../Components/CustomUIElements/StyledSnackbar';
import { ISnackbarInput } from '../../Components/CustomUIElements/StyledSnackbar';
import { styles } from '../styles';
import { ModalType } from '../config';
import { ActionButton } from '../OverviewTab/Actions';
import { IUserPermissions } from '../../Components/sharedTypes';

export default function TasksAndEventsTab({
  ApplicationState,
  dispatch,
  currentUserId
}: {
  ApplicationState: ApplicationState;
  dispatch: Dispatch<ApplicationAction>;
  currentUserId: number;
}) {
  const { apiKey } = ApplicationState;
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);
  const permissions = queryClient.getQueryData<IUserPermissions>(['permissions']);

  const [ScoutCalendarState, eventDispatch] = useReducer(
    ScoutCalendarReducer,
    InitialScoutCalendarState
  );
  const { createEventObject } = ScoutCalendarState;

  const { data: events, isLoading: loadingEvents } = useQuery({
    queryKey: ['application events'],
    queryFn: async () => {
      if (application) {
        const { res } = await Api.getApplicationEvents(application.job.id, application.id);
        return res.events;
      }
    },
    onError: () =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: `There was an error in loading events`, state: 'error' }
      })
  });

  useEffect(() => {
    eventDispatch({ type: 'SET_APIKEY', payload: apiKey });
    permissions && eventDispatch({ type: 'SET_PERMISSIONS', payload: permissions });
  }, [eventDispatch, apiKey, permissions]);

  const headerStyle = (name: string) => {
    switch (name) {
      case 'Event':
        return {
          minWidth: '150px'
        };
      case 'Type':
        return { minWidth: '150px' };
      case 'Scheduled':
        return { minWidth: '120px' };
      case 'Event Owner':
        return { minWidth: '120px' };
      default:
        return { minWidth: '90px' };
    }
  };

  function handleSelectEvent(event: IEvent) {
    eventDispatch({
      type: 'SET_CREATE_EVENT_OBJECT',
      payload: {
        ...createEventObject,
        eventName: event.subject,
        eventDescription: event.note || '',
        maxCandidates: String(event.max_candidates),
        recruiters: event.attendees
          .filter((a) => a.type === 'User')
          .map((a) => ({
            id: a.id,
            name: a.name,
            checked: false,
            attendeeId: a.attendee_id,
            state: a.state
          })),
        eventType: event.event_type,
        duration: String(event.duration_in_minutes),
        eventDate: new Date(event.begins_at),
        eventId: event.id,
        candidates: event.attendees.filter((a) => a.type === 'Candidate'),
        dates: [new Date(event.begins_at)],
        owner: event.owner
      }
    });
    eventDispatch({ type: 'SET_SHOW_VIEW_EVENT_MODAL', payload: true });
  }

  const actions = [
    {
      label: 'Add to event',
      icon: <></>,
      onClick: () => dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.ADD_TO_EVENT }),
      permission: true
    },
    {
      label: 'Invite to timeslot',
      icon: <></>,
      onClick: () => dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.INVITE_TO_TIMESLOT }),
      permission: permissions?.Applications?.['Invite To Book In']
    }
  ];

  return (
    <Stack sx={{ rowGap: 3 }}>
      <Tasks ApplicationState={ApplicationState} dispatch={dispatch} />
      <Stack
        sx={{
          padding: 3,
          borderRadius: '6px',
          border: '1px solid #E3E3E3',
          rowGap: 2
        }}
      >
        <Stack
          sx={{
            fontSize: '18px',
            fontWeight: 600,
            color: '#333333',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Stack>Events</Stack>
          <Stack sx={{ flexDirection: 'row', columnGap: 2 }}>
            {actions
              .filter((a) => a.permission)
              .map((action, i) => (
                <ActionButton key={i} action={action} boldFont={true} />
              ))}
          </Stack>
        </Stack>
        {loadingEvents ? (
          [...Array(5)].map((_, index) => <Skeleton key={index} height={30} animation="wave" />)
        ) : (
          <>
            {events?.length ? (
              <Table stickyHeader sx={tableStyles.tableStyling}>
                <TableHead>
                  <TableRow>
                    {['Event', 'Type', 'Scheduled', 'Event Owner'].map((title, index) => (
                      <TableCell
                        sx={{ ...tableStyles.systemReportsTableHeader, ...headerStyle(title) }}
                        key={index}
                      >
                        <Stack>{title}</Stack>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {events?.map((e) => (
                    <TableRow
                      key={e.id}
                      onClick={() => handleSelectEvent(e)}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell>{e.subject}</TableCell>
                      <TableCell>{e.event_type.name}</TableCell>
                      <TableCell>
                        <Stack>
                          <Stack>{dayjs(e.begins_at).format('ddd, D MMM YYYY')}</Stack>
                          <Stack>
                            {dayjs(e.begins_at).format('LT')} - {dayjs(e.ends_at).format('LT')}
                          </Stack>
                        </Stack>
                      </TableCell>
                      <TableCell>{e.owner}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <Stack sx={{ padding: 7.5, alignItems: 'center', rowGap: 2 }}>
                <CalendarMonthIcon sx={styles.emptyStateIcon} />
                <Stack sx={styles.emptyStateMessage}>No events added</Stack>
              </Stack>
            )}
          </>
        )}
      </Stack>
      {ScoutCalendarState.showCreateEventModal && events && (
        <CreateEvent
          events={events}
          ScoutCalendarState={ScoutCalendarState}
          dispatch={eventDispatch}
        />
      )}
      {ScoutCalendarState.showViewEventModal && events && (
        <ViewEvent
          events={events}
          ScoutCalendarState={ScoutCalendarState}
          dispatch={eventDispatch}
          currentUserId={currentUserId}
        />
      )}
      <StyledSnackbar
        message={ScoutCalendarState.snackbar.message}
        state={ScoutCalendarState.snackbar.state}
        setSnackbarState={(snackbarInput: ISnackbarInput) =>
          eventDispatch({ type: 'SET_SNACKBAR', payload: snackbarInput })
        }
      />
    </Stack>
  );
}
