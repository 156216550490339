import React, { Dispatch, SetStateAction, MutableRefObject } from 'react';
import { Grid, Stack } from '@mui/material';
import QuestionFieldGenerator, {
  IApplicantQuestion
} from '../../Components/Utilities/QuestionFieldGenerator';
import Rating from './Rating';
import { styles } from '../styles';
import { getAnswerValue } from '../SmartFormsTab/get-answer-value';
import { IAnswer } from '../../ApprovalForms/types';

export default function QuestionsAndRatings({
  questions,
  questionErrors,
  setQuestionErrors,
  ratings,
  setRatings,
  setAnswers,
  questionsRef,
  formResponseAnswers
}: {
  questions?: IApplicantQuestion[];
  questionErrors: Record<number, string>;
  setQuestionErrors: Dispatch<SetStateAction<Record<number, string>>>;
  ratings: Record<number, number>;
  setRatings: Dispatch<SetStateAction<Record<number, number>>>;
  setAnswers: Dispatch<
    SetStateAction<Record<number, string | number | Record<string, string | boolean>>>
  >;
  questionsRef: MutableRefObject<(HTMLDivElement | null)[]>;
  formResponseAnswers?: IAnswer[];
}) {
  return (
    <Stack sx={styles.questionsAndRatingsContainer}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Stack sx={{ color: '#666666', fontWeight: 'bold' }}>Questions/Answers</Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack sx={{ color: '#666666', fontWeight: 'bold' }}>Rating</Stack>
        </Grid>
      </Grid>
      {questions?.map((q, index) => (
        <Grid key={q.id} container spacing={2}>
          <Grid item xs={8}>
            <QuestionFieldGenerator
              question={q}
              questionRef={(el) => (questionsRef.current[index] = el)}
              questionError={questionErrors[q.id]}
              onChangeCallback={(id, value) => {
                setAnswers((prev) => ({ ...prev, [id]: value }));
                setQuestionErrors((prev) => ({
                  ...prev,
                  [id]: value || !q.required ? '' : 'This field is required'
                }));
              }}
              answer={formResponseAnswers && getAnswerValue(q, index, formResponseAnswers)}
            />
          </Grid>
          <Grid item xs={4}>
            {q.rateable && (
              <Rating
                value={ratings[q.id]}
                setValue={(rate) => setRatings({ ...ratings, [q.id]: rate })}
              />
            )}
          </Grid>
        </Grid>
      ))}
    </Stack>
  );
}
