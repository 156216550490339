import React, { useState, Dispatch, SetStateAction } from 'react';
import { Box, Button, CircularProgress, Skeleton, Tooltip } from '@mui/material';
import ExportButton from './Buttons/ExportButton';
import EmailButton from './Buttons/EmailButton';
import { classes } from './styles';
import dompurify from 'dompurify';
import MultiTagInput from '../../NewUI/Components/CustomUIElements/MultiTagInput';
import { emailIsValid } from '../../NewUI/Components/Utilities/emailIsValid';

const DisplayHTMLViewOnly = ({
  content,
  viewerRef,
  onExportClick,
  setEmails,
  emails,
  onSendEmailClick,
  emailInputOpen,
  setEmailInputOpen,
  sendingEmails,
  loadingHTML,
  maxHeight
}: {
  content: string;
  viewerRef?: React.RefObject<HTMLDivElement>;
  onExportClick?: () => void;
  setEmails?: Dispatch<SetStateAction<string[]>>;
  emails?: string[];
  onSendEmailClick?: () => void;
  emailInputOpen?: boolean;
  setEmailInputOpen?: Dispatch<SetStateAction<boolean>>;
  sendingEmails?: boolean;
  loadingHTML?: boolean;
  maxHeight?: string;
}) => {
  const [emailTooltipOpen, setEmailTooltipOpen] = useState(false);
  const [emailTooltipHidden, setEmailTooltipHidden] = useState(false);
  return (
    <Box sx={classes.viewOnlyHTMLParent}>
      {emailInputOpen && setEmails && (
        <Box sx={classes.emailInputContainer}>
          <Box sx={classes.emailInput}>
            <MultiTagInput
              tags={emails || []}
              setTags={setEmails}
              validation={emailIsValid}
              validationMessage="Must input valid email"
              label="Email address/s"
              width="100%"
              tagType="emails"
              helperText="To copy and paste bulk emails, email addresses must be separated with a comma"
              styles={classes.inputStyles}
              labelStylesOverride={classes.labelStyles}
              helperTextStylesOverride={classes.helperTextStyles}
              autoFocus
            />
          </Box>
          <Button
            sx={classes.sendEmailButton}
            onClick={onSendEmailClick}
            disabled={emails?.length === 0}
          >
            {!sendingEmails ? 'Send' : <CircularProgress size={12} color="inherit" />}
          </Button>
        </Box>
      )}
      {loadingHTML ? (
        <Skeleton width="100%" height={maxHeight || '100%'} sx={{ transform: 'scale(1)' }} />
      ) : (
        <Box
          sx={{ ...classes.viewOnlyHTML, ...(maxHeight && { maxHeight }) }}
          ref={viewerRef}
          dangerouslySetInnerHTML={{
            __html: dompurify.sanitize(content)
          }}
        />
      )}
      {setEmails && (
        <Tooltip
          open={emailTooltipOpen}
          onClose={() => setEmailTooltipOpen(false)}
          onOpen={() => setEmailTooltipOpen(emailTooltipHidden ? false : true)}
          title="Email"
          placement="top"
        >
          <EmailButton
            onClick={() => {
              setEmailInputOpen && setEmailInputOpen((prev) => !prev);
              setEmailTooltipOpen(false);
              emailInputOpen
                ? setTimeout(() => setEmailTooltipHidden(false), 300)
                : setEmailTooltipHidden(true);
            }}
            inputOpen={emailInputOpen || false}
          />
        </Tooltip>
      )}
      {onExportClick && !emailInputOpen && (
        <Tooltip title="Download as PDF" placement="top">
          <ExportButton onClick={onExportClick} />
        </Tooltip>
      )}
    </Box>
  );
};

export default DisplayHTMLViewOnly;
