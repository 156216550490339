import React, { Dispatch } from 'react';
import { Stack } from '@mui/material';
import CandidateTags from '../../Candidate/CandidateTags';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { ApplicationAction, IApplication, ApplicationState } from '../types';
import AdditionalInfo from './AdditionalInfo';
import Notepad from '../../Candidate/Notepad';
import AISummary from './AISummary';
import Responses from './Responses';
import Questionnaire from './Questionnaire';
import Ratings from './Ratings';
import Actions from './Actions';
import Api from '../API';
import { defaultSections } from '../config';
import AddRecord from '../../Candidate/AddRecord';
import { ModalType } from '../config';
import CreateApproval from '../Modals/CreateApproval';
import EmailExternal from '../Modals/EmailExternal';
import SendSms from '../Modals/SendSms';
import SendForm from '../Modals/SendForm';
import { IUserPermissions } from '../../Components/sharedTypes';

export default function OverviewTab({
  ApplicationState,
  dispatch,
  aiSummariesEnabled
}: {
  ApplicationState: ApplicationState;
  dispatch: Dispatch<ApplicationAction>;
  aiSummariesEnabled: boolean;
}) {
  const { sortableSections, modalsOpen } = ApplicationState;
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);
  const permissions = queryClient.getQueryData<IUserPermissions>(['permissions']);

  const { mutate: updateApplicationNotepad, isLoading: updatingApplicationNotepad } = useMutation({
    mutationFn: async (note: string) => {
      if (application) {
        const { res } = await Api.updateApplicationNotepad(
          application.job.id,
          application.id,
          note
        );
        return res;
      }
    },
    onSuccess: () => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: 'Notepad updated successfully', state: 'success' }
      });
      queryClient.invalidateQueries(['application'], { exact: true });
    },
    onError: (error: { error: string }) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error in updating application notepad, ${error.error}`,
          state: 'error'
        }
      });
    }
  });

  useQuery({
    queryKey: ['ui preferences'],
    queryFn: async () => {
      const { res } = await Api.getUiPreferences();
      return res.ui_preferences;
    },
    onSuccess: (res) =>
      dispatch({
        type: 'SET_SORTABLE_SECTIONS',
        payload: res[0]?.sorting.order || defaultSections
      }),
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting ui preferences data, ${error}`,
          state: 'error'
        }
      })
  });

  const { mutate: addRecord, isLoading: addingRecord } = useMutation({
    mutationFn: async ({ subject, body }: { subject: string; body: string }) => {
      if (application) {
        const { res } = await Api.addRecord(application.job.id, application?.id, {
          subject,
          body
        });
        return res;
      }
    },
    onSuccess: (res) => {
      dispatch({ type: 'SET_SNACKBAR', payload: { message: res.success, state: 'success' } });
      dispatch({ type: 'SET_MODALS_OPEN', payload: null });
      queryClient.invalidateQueries(['application']);
    },
    onError: (error) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error in adding permanent record, ${error}`,
          state: 'error'
        }
      });
    }
  });

  const hrQuestion = useQuery({
    queryKey: ['hr questions'],
    queryFn: async () => {
      if (application) {
        const { res } = await Api.getHrQuestions(application.job.id, application.id);
        return res.hr_answers[0] || null;
      }
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting application questionnaire data, ${error}`,
          state: 'error'
        }
      }),
    enabled: !!application
  });

  if (!application) return null;

  const Section = ({ section }: { section: string }) => {
    switch (section) {
      case 'Candidate Tags':
        return <CandidateTags candidate={application.candidate} dispatch={dispatch} />;
      case 'Felix AI Summary':
        return <AISummary dispatch={dispatch} aiSummariesEnabled={aiSummariesEnabled} />;
      case 'Responses':
        return <Responses dispatch={dispatch} />;
      case 'Notepad':
        return (
          <>
            {permissions?.Applications['Add Suitability Comment'] && (
              <Notepad
                note={application.suitability_comment}
                updateNote={updateApplicationNotepad}
                updatingNote={updatingApplicationNotepad}
              />
            )}
          </>
        );
      case 'Actions':
        return <Actions dispatch={dispatch} />;
      case 'Application questionnaire':
        return <Questionnaire hrQuestion={hrQuestion} dispatch={dispatch} />;
      default:
        return null;
    }
  };

  return (
    <Stack sx={{ rowGap: 3 }}>
      <Ratings ApplicationState={ApplicationState} dispatch={dispatch} hrQuestion={hrQuestion} />
      {sortableSections.map((section, index) => (
        <Section key={index} section={section} />
      ))}
      <AdditionalInfo />
      {modalsOpen === ModalType.ADD_RECORD && (
        <AddRecord
          isOpen={modalsOpen === ModalType.ADD_RECORD}
          handleClose={() => dispatch({ type: 'SET_MODALS_OPEN', payload: null })}
          addRecord={addRecord}
          addingRecord={addingRecord}
        />
      )}
      {modalsOpen === ModalType.CREATE_APPROVAL && (
        <CreateApproval ApplicationState={ApplicationState} dispatch={dispatch} />
      )}
      {modalsOpen === ModalType.EMAIL_EXTERNAL && (
        <EmailExternal
          ApplicationState={ApplicationState}
          dispatch={dispatch}
          aiSummariesEnabled={aiSummariesEnabled}
        />
      )}
      {modalsOpen === ModalType.SEND_SMS && (
        <SendSms
          smsTemplatePermissions={true}
          ApplicationState={ApplicationState}
          dispatch={dispatch}
        />
      )}
      {modalsOpen === ModalType.SEND_FORM && (
        <SendForm
          ApplicationState={ApplicationState}
          dispatch={dispatch}
          smsTemplatePermissions={true}
        />
      )}
    </Stack>
  );
}
