import React from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Box,
  TableContainer,
  Paper,
  Skeleton
} from '@mui/material';
import { RemoveRedEye as RemoveRedEyeIcon } from '@mui/icons-material';
import { TableData } from './types';
import { styles } from './styles';

type Props = {
  data: TableData;
  headers: string[];
  loadingPreviewData: boolean;
  message: string;
  total: number;
};
const SystemReportPreviewTable = ({ data, headers, loadingPreviewData, message, total }: Props) => {
  return (
    <Box sx={{ ...styles.previewTableWrapper }}>
      {data?.length ? (
        <Box sx={styles.previewInfoHeader}>
          <RemoveRedEyeIcon sx={styles.previewIcon} />{' '}
          {`Preview Showing 1 - ${data.length} out of ${total ?? data.length}`}
        </Box>
      ) : null}
      <Box sx={{ padding: '1rem', paddingTop: `${!data || !data?.length ? '1rem' : '0'} ` }}>
        <TableContainer sx={{ boxShadow: 'none', scrollBehavior: 'smooth' }} component={Paper}>
          <Table sx={{ ...styles.tableWrapper, 'th, td, tr, thead': { padding: `22px 10px` } }}>
            {!message && !loadingPreviewData && headers?.length > 0 && (
              <TableHead>
                <TableRow sx={styles.tableHeaderRow}>
                  {headers?.map((option, i: number) => (
                    <TableCell key={i} sx={styles.tableCellData}>
                      {option}
                    </TableCell>
                  ))}
                  <TableCell> </TableCell>
                </TableRow>
              </TableHead>
            )}
            {!message && !loadingPreviewData && headers?.length > 0 && (
              <TableBody>
                {data?.map((datum, index) => {
                  return (
                    <TableRow key={index}>
                      {Object.entries(datum.attributes).map((option) => {
                        const [key, value] = option;
                        return <TableCell key={key}>{value ? value : '-'}</TableCell>;
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
          {message ? (
            <Box sx={styles.noRecords}>{message}</Box>
          ) : (
            !loadingPreviewData &&
            (data?.length === 0 || !data) && <Box sx={styles.noRecords}>No Records Found</Box>
          )}
          {!message && loadingPreviewData && (
            <>
              {[...Array(5)].map((_, index) => (
                <Box key={index} sx={styles.skeletonContainer}>
                  <Skeleton animation="wave" height={60} />
                </Box>
              ))}
            </>
          )}
        </TableContainer>
      </Box>
    </Box>
  );
};

export default SystemReportPreviewTable;
